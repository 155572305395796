import Erly from 'images/about/teams/erly.png';
// import ErlyMobile from 'images/about/teams/erly-m.png';
import Norli from 'images/about/new/norliana.png';
import NorliMobile from 'images/about/new/norliana-m.png';
import Sopfian from 'images/about/teams/sopfian.png';
import Dede from 'images/about/teams/dede.png';
import Assegaf from 'images/about/teams/assegaf.png';
import Saiful from 'images/about/new/saiful.png';
import Wati from 'images/about/teams/wati.png';
import Indra from 'images/about/teams/indra.png';

import Edem from 'images/about/teams/edem.png';
import Ilham from 'images/about/teams/ilham.png';
import Bedi from 'images/about/teams/bedi.png';
import Ahmad from 'images/about/teams/ahmad.png';

export const teams = [
  {
    name: "Erly Witoyo",
    position: "Chief Executive Officer",
    images: {
      mobile: Erly,
      desktop: Erly
    },
    linkedin: "https://www.linkedin.com/in/erlywitoyo",
    newLine: true,
  },
  {
    name: "Bedi Lackmann",
    position: "Director",
    images: {
      mobile: Bedi,
      desktop: Bedi
    },
    linkedin: "https://www.linkedin.com/in/b-gunter-l-48b8b6293",
    newLine: false,
  }, 
  {
    name: "Ahmad Zubaidi",
    position: "Sharia Supervisory Board",
    images: {
      mobile: Ahmad,
      desktop: Ahmad
    },
    linkedin: null,
    newLine: false,
  }, 
  {
    name: "Assegaf Syahrial",
    position: "Tech Manager",
    images: {
      mobile: Assegaf,
      desktop: Assegaf
    },
    linkedin: "https://www.linkedin.com/in/assegaf-syahrial-1534bb172",
    newLine: true,
  }, 
  {
    name: "Dede Iskandar",
    position: "Business Development Manager",
    images: {
      mobile: Dede,
      desktop: Dede
    },
    linkedin: "https://www.linkedin.com/in/dede-iskandar-40a44b16b",
    newLine: false,
  }, 
  {
    name: "Achmad Sopfian",
    position: "Risk Manager",
    images: {
      mobile: Sopfian,
      desktop: Sopfian
    },
    linkedin: "https://www.linkedin.com/in/achmad-sopfian-304342166/",
    newLine: false,
  }, 
  // {
  //   name: "Dedi Sumardi",
  //   position: "Head of Monitoring & Collection",
  //   images: {
  //     mobile: Edem,
  //     desktop: Edem
  //   },
  //   linkedin: null,
  //   newLine: false,
  // }, 
  // {
  //   name: "Muhammad Ilham Ilyas",
  //   position: "Legal Officer",
  //   images: {
  //     mobile: Ilham,
  //     desktop: Ilham
  //   },
  //   linkedin: "https://www.linkedin.com/in/muhammad-ilham-ilyas-6bb2b313a",
  //   newLine: false,
  // },
  // {
  //   name: "Saiful Al Bahri",
  //   position: "Marketing Manager",
  //   images: {
  //     mobile: Saiful,
  //     desktop: Saiful
  //   },
  //   linkedin: "http://linkedin.com/in/saiful-a-ab7851236",
  //   newLine: true,
  // }, 
  // {
  //   name: "Sri Jarwati",
  //   position: "Finance, Accounting & Tax Officer",
  //   images: {
  //     mobile: Wati,
  //     desktop: Wati
  //   },
  //   linkedin: "http://linkedin.com/in/sri-jarwati-8409aa199",
  //   newLine: true,
  // }, 
  // {
  //   name: "Indra Permana",
  //   position: "HR - GA Lead",
  //   images: {
  //     mobile: Indra,
  //     desktop: Indra
  //   },
  //   linkedin: "https://www.linkedin.com/in/indra-permana-a39983206/",
  // }, 
]