import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";

import {
  Container,
  AppBar,
  Tabs,
  Tab,
  Paper,
  Snackbar,
  InputAdornment,
  CircularProgress,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  FormControlLabel,
  Checkbox,
  Toolbar,
  MenuItem,
  FormControl,
  Typography,
  Button,
  InputLabel,
  Hidden,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Grid,
  Select,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Input,
} from "@material-ui/core";
import { AccountCircle, Close } from "@material-ui/icons";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import api from "services/api";
import hp from "services/hp";
import MaterialTable from "material-table";

class Page extends Component {
  constructor() {
    super();
    this.state = {
      openSuccessSnack: false,
      save_loading: false,
      referral: "",
      profile: "",
      str_code: "",
      code: "",
      referral_code: "",
      copySuccess: false,
      matches: window.matchMedia("(min-width: 768px)").matches,
      moreMdWindow: false,
    };
    this.withdrawTable = React.createRef();
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.onStart();
    const handler = (e) => this.setState({ matches: e.matches });
    if (window.matchMedia("(min-width: 768px)").addListener(handler)) {
      this.setState({ moreMdWindow: true });
    }
  }
  onStart = async () => {
    let { referral_code } = this.state;
    const referral_res = await api.get("profile");
    const referral = referral_res.data;
    const profile = referral_res.data;
    const str_code = referral.str_code;
    const code = referral.code;
    console.log(profile);
    // const referral_code = str_code + "-" + code;
    if (str_code == null && code == null) {
      referral_code = "";
    } else {
      referral_code = code;
      // referral_code = str_code + "-" + code;
    }
    // console.log(str_code + "," + code);
    // hp.save_user(referral);
    this.setState({ referral, str_code, code, referral_code, profile });
  };

  onChange = (e, f) => {
    let { referral } = this.state;
    referral[f] = e.target.value;
    // console.log(referral[f]);
    this.setState({ referral });
  };

  onChangeReferralCode = (e, f) => {
    let { state } = this;
    const { str_code, code } = this.state;
    state[f] = e.target.value;
    // console.log(state[f]);
    // console.log("str_code: " + str_code + ", code: " + code);
    // console.log("Substring: " + state[f].substring(3, 7));
    if (str_code == null && code == null) {
      state[f] = e.target.value;
      if (state[f].length == 3) {
        state[f] = e.target.value + "-xxxx";
      }
      if (state[f].length > 8) {
        alert("Refferal code cannot more than 8 characters");
        window.location.reload();
      }
      this.setState({ state });
    } else {
      if (state[f] == "") {
        alert("Tidak boleh kosong");
        return;
      } else if (state[f].length > 8) {
        alert("Referral code hanya 7 karakter, contoh 123-4567");
        window.location.reload();
        // return;
      } else if (
        state[f].substring(state[f].length - 4, state[f].length) != code
      ) {
        alert("4 huruf terakhir gak bisa diapus");
        // window.location.reload();
        return;
      }
      this.setState({ state });
    }
    // this.setState({ state });
  };
  onChangeReferralCodeOld = (e, f) => {
    let { referral } = this.state;
    referral[f] = e.target.value;
    if (referral[f].length > 3) {
      alert("Referral code hanya 3 karakter");
      return;
    }
    // console.log(referral[f]);
    this.setState({ referral });
  };

  copyCodeToClipboard = () => {
    const { referral_code } = this.state;
    navigator.clipboard.writeText(referral_code);
    this.setState({ copySuccess: true, openSuccessSnack: true });
    return;
  };

  onSave = async (e) => {
    e.preventDefault();
    const { referral, str_code, referral_code, code } = this.state;
    // console.log(str_code);
    // console.log(referral);
    if (referral_code == "") {
      try {
        const res = await api.post(`membership/referral-code`, {
          // str_code: referral.str_code,
          refferal_code: referral_code,
        });
        let { data } = res;
        // console.log(data);
        // if (data.error) {
        //   alert("something error");
        //   return;
        // }
        alert(
          "Success update referral code, you can update first three letter."
        );
        this.setState({ referral, str_code });
        hp.save_user(data);
        window.location.reload();
        // window.scrollTo(0, 0);
      } catch (error) {
        // console.log(e);
      }
    } else if (referral_code.substr(3, 1) != "-") {
      alert("Penulisan format salah, contoh rpy-r147");
      return;
    } else {
      try {
        const res = await api.post(`membership/referral-code`, {
          // str_code: referral.str_code,
          refferal_code: referral_code,
        });
        let { data } = res;
        // console.log(data);
        // if (data.error) {
        //   alert("something error");
        //   return;
        // }
        alert(
          "Success update referral code, you can update first three letter."
        );
        this.setState({ referral, str_code });
        hp.save_user(data);
        window.location.reload();
        // window.scrollTo(0, 0);
      } catch (error) {}
    }
  };

  render() {
    const {
      referral,
      str_code,
      code,
      referral_code,
      save_loading,
      copySuccess,
      moreMdWindow,
      profile,
    } = this.state;
    return (
      <div id="kb-wallet">
        <Container maxWidth="xl">
          <Grid container spacing={4}>
            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              style={{ padding: "0 auto" }}
              className=""
            >
              {(() => {
                if (profile.status != "approved") {
                  return (
                    <Typography variant="h6">
                      You do not have a Referral Code . To generate, kindly
                      complete the KYC process by uploading your personal
                      documents such as Identification Document and Proof of
                      Address on the <a href="profile">Profile</a> page.
                    </Typography>
                  );
                } else if (
                  (profile.is_verified =
                    1 && profile.status == "approved" && profile.code == null)
                ) {
                  return (
                    <>
                      <Typography variant="h6">
                        Please relogin to generate the Referral Code
                      </Typography>
                    </>
                  );
                } else {
                  return (
                    <>
                      <Grid item xs={12} md={12} lg={12}>
                        <Card
                          className="spaceBottomX center"
                          style={{ borderRadius: "10px" }} // width: "75%", margin: "auto"
                        >
                          <form
                            onSubmit={this.onSave}
                            style={{ margin: "auto" }}
                          >
                            <Grid
                              container
                              spacing={2}
                              className="padding center"
                            >
                              <Grid item xs={12} md={12}>
                                <div
                                  className="padding"
                                  style={
                                    this.state.matches
                                      ? {
                                          border: "1px solid",
                                          borderRadius: "10px",
                                          // marginTop: "30px",
                                          // marginBottom: "30px",
                                          backgroundColor: "#C4C4C4",
                                          width: "50%",
                                          opacity: "75%",
                                          margin: "auto",
                                        }
                                      : {
                                          border: "1px solid",
                                          borderRadius: "10px",
                                          // marginTop: "30px",
                                          // marginBottom: "30px",
                                          backgroundColor: "#C4C4C4",
                                          // width: "50%",
                                          opacity: "75%",
                                          margin: "auto",
                                        }
                                  }
                                >
                                  {/* //todo try just one input for refferal code */}
                                  <TextField
                                    // margin="normal"
                                    type="text"
                                    // required
                                    // value={referral.str_code + "-" + referral.code}
                                    value={referral_code}
                                    placeholder="Your referral code here"
                                    // InputProps={{ inputProps: { rowsMax: 3 } }}
                                    inputProps={{
                                      min: 3,
                                      style: {
                                        textAlign: "center",
                                        fontSize: "30px",
                                      },
                                    }}
                                  ></TextField>
                                  <Button
                                    type="button"
                                    onClick={() => this.copyCodeToClipboard()}
                                  >
                                    <FileCopyIcon />
                                  </Button>
                                </div>
                              </Grid>
                            </Grid>
                          </form>
                        </Card>
                      </Grid>
                      <Typography>
                        Click the icon to copy referral code.
                      </Typography>
                    </>
                  );
                }
              })()}
            </Grid>
          </Grid>
        </Container>

        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={this.state.openSuccessSnack}
          autoHideDuration={6000}
          onClose={() => this.setState({ openSuccessSnack: false })}
        >
          <div className="success-alert">
            <Typography>Success Copied</Typography>
          </div>
        </Snackbar>
      </div>
    );
  }
}

export default Page;
