import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";

import {
  Container,
  AppBar,
  Tabs,
  Tab,
  Paper,
  InputAdornment,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  Toolbar,
  MenuItem,
  FormControl,
  Typography,
  Button,
  InputLabel,
  Hidden,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Grid,
  Select,
} from "@material-ui/core";
import { AccountCircle, Close } from "@material-ui/icons";
import api from "services/api";
import { format } from "date-fns";

class Page extends Component {
  state = {
    id: null,
    update: null,
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    let { pathname } = this.props.location;
    let id = pathname.split("/")[3];
    this.setState({ id }, () => {
      this.onStart();
    });
  }

  onStart = async () => {
    const { id } = this.state;
    const update_res = await api.get(`campaign-update/${id}`);
    const update = update_res.data;
    this.setState({ update });
  };

  render() {
    const { update } = this.state;
    return (
      <div id="campaign-update">
        <Container maxWidth="xl">
          <Paper elevation={3} className="padding">
            {update ? (
              <Fragment>
                <Typography component="h1" className="bold blue">
                  {update.campaign.acronim}
                </Typography>
                <Typography component="h3" className="bold space">
                  {update.title} - Updated on{" "}
                  {format(
                    new Date(update.created_at.replace(/ /g, "T")),
                    "do LLL yyyy"
                  )}
                </Typography>

                <Typography
                  className="spaceBottom"
                  dangerouslySetInnerHTML={{ __html: update.content }}
                  style={{ whiteSpace: "pre-line" }}
                />

                {update.images &&
                  update.images.length > 0 &&
                  update.images.map((image, i) => (
                    <Typography>
                      <a href={image} target="_blank">
                        View attachment {i + 1}
                      </a>{" "}
                    </Typography>
                  ))}
              </Fragment>
            ) : (
              <div className="loading">
                <CircularProgress />
              </div>
            )}
          </Paper>
        </Container>
      </div>
    );
  }
}

export default Page;
