import {
  Box,
  Button,
  Grid,
  Hidden,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import React from "react";
import { Carousel } from "react-responsive-carousel";

import Testmonial2 from "images/home/Djuan.png";
import Testmonial3 from "images/home/Shaik shahul.png";
import Testmonial5 from "images/home/Muhammad Farid.png";
import Testmonial6 from "images/home/Martin.png";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("xs")]: {
      padding: "27px 0",
      alignItems: "flex-start",
    },
    backgroundColor: "rgba(224, 224, 224, .5)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: 50,
  },
  heading: {
    [theme.breakpoints.down("xs")]: {
      fontSize: 21,
      paddingLeft: 40,
      paddingRight: 40,
      marginBottom: 40,
      width: 198,
    },
    ...theme.typography.heading3,
    color: theme.palette.darkLightBlue.main,
  },
  imageProfile: {
    [theme.breakpoints.down("xs")]: {
      width: "85px !important",
      height: "85px",
    },
    objectFit: "cover",
    width: "181px !important",
    height: "181px",
    borderRadius: "50%",
  },
  carousel: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      padding: 0,
      "& .slide": {
        paddingLeft: "0px !important",
        paddingRight: "0px !important",
      },
    },
    width: 900,
    display: "flex",
    paddingTop: 65,
    paddingBottom: 40,
    "& .slide": {
      paddingLeft: 123,
      paddingRight: 123,
    },
  },
  carouselItem: {
    [theme.breakpoints.down("xs")]: {
      padding: "0 40px !important",
    },
    [theme.breakpoints.down("md")]: {
      padding: "0 60px",
    },
    display: "flex",
    backgroundColor: "transparent",
  },
  comment: {
    [theme.breakpoints.down("xs")]: {
      fontSize: 11,
      width: "250px !important",
    },
    [theme.breakpoints.down("md")]: {
      // width: "350px",
    },
    ...theme.typography.body3,
    color: "#4F4F4F",
    textAlign: "justify",
    marginBottom: 20,
  },
  name: {
    [theme.breakpoints.down("xs")]: {
      fontSize: 12,
    },
    fontSize: 18,
    fontWeight: "bold",
    color: "#4F4F4F",
    textAlign: "left",
  },
  buttonNext: {
    [theme.breakpoints.down("xs")]: {
      right: "-15px !important",
    },
    [theme.breakpoints.down("md")]: {
      right: 70,
    },
    zIndex: 1,
    position: "absolute",
    top: "50%",
    right: 0,
    transform: "translateY(-50%)",
    border: "none",
    backgroundColor: "transparent",
    cursor: "pointer",
  },
  buttonLeft: {
    [theme.breakpoints.down("xs")]: {
      left: "-15px",
    },
    [theme.breakpoints.down("md")]: {
      left: 70,
    },
    zIndex: 1,
    position: "absolute",
    top: "50%",
    left: 0,
    transform: "translateY(-50%)",
    border: "none",
    backgroundColor: "transparent",
    cursor: "pointer",
  },
  wrapName: {
    flex: 2,
  },
}));

export default function Testimonials() {
  const classes = useStyles();

  const testimonials = [
    {
      image: Testmonial2,
      name: "Djuan Onn Abdul Rahman, SME owner",
      testimonial: `Kapital Boost offers entrepreneurs a much needed alternative
      source of funding to banks. It gives businesses a relatively fast
      access to funds which is important for fast-growing companies.
      Moreover, investors are able to invest in exciting businesses and
      be part of their success story.`,
    },
    {
      image: Testmonial6,
      name: "Martin Runshaug, SME owner",
      testimonial: `Unlike traditional bank financing which requires SMEs to operate
      for a minimum of three years, Kapital Boost offers a good
      financing alternative for young companies like ourselves. At the
      end of the day, it is during the early years of operation that a
      company experience rapid growth, and when it is most dependent on
      healthy cash flow to facilitate this growth.`,
    },
    {
      image: Testmonial3,
      name: "Shaik Shahul Hameed, Investor",
      testimonial: `Have always been seeking halal investments for an average joe. No
      better avenue than Kapital Boost. Trusted, friendly and very
      reliable. Have experienced consistent results. Best of all,
      totally Shariah-focused and thus halal growth with a peace of
      mind.`,
    },
    {
      image: Testmonial5,
      name: "Muhammad Farid Then, Investor",
      testimonial: `Kapital boost provides a Syariah compliant platform for investors
      who seek better returns. At the same time, it also conducts its
      own due dillegence to get the best deals which is sustainable &
      ethical. Moreover, it allows members to donate to less fortunate
      communities through its donation crowdfunding.`,
    },
  ];

  return (
    <div className={classes.root}>
      <div className={classes.heading}>Testimonials</div>

      <Carousel
        className={`testimonials ${classes.carousel}`}
        autoPlay={true}
        showThumbs={false}
        showStatus={false}
        showIndicators={false}
        renderArrowPrev={(onClickHandler, hasPrev, label) =>
          hasPrev && (
            <button className={classes.buttonLeft} onClick={onClickHandler}>
              <ChevronLeft
                style={{
                  color: "RGBA(102,102,102,0.29)",
                  fontSize: 100,
                }}
              />
            </button>
          )
        }
        renderArrowNext={(onClickHandler, hasNext, label) =>
          hasNext && (
            <button className={classes.buttonNext} onClick={onClickHandler}>
              <ChevronRight
                style={{
                  color: "RGBA(102,102,102,0.29)",
                  fontSize: 100,
                }}
              />
            </button>
          )
        }
      >
        {testimonials.map((i, index) => (
          <div key={index} className={classes.carouselItem}>
            <Hidden xsDown>
              <img
                src={i.image}
                className={classes.imageProfile}
                alt={i.name}
              />
              <Box width="35px" />
            </Hidden>
            <Box flex={1}>
              <Typography
                classes={{
                  root: classes.comment,
                }}
              >
                {i.testimonial}
              </Typography>
              <Grid container spacing={3} alignItems="center">
                <Hidden smUp>
                  <Grid item>
                    <img
                      src={i.image}
                      className={classes.imageProfile}
                      alt={i.name}
                    />
                  </Grid>
                </Hidden>
                <Grid item className={classes.wrapName}>
                  <Typography
                    classes={{
                      root: classes.name,
                    }}
                  >
                    {i.name}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </div>
        ))}
      </Carousel>
    </div>
  );
}
