import { Button, Dialog, DialogContent, Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useRegisterSuccessDialog } from "contexts/RegisterSuccessDialogContext";
import React from "react";

export default function RegisterSuccessDialog() {
  const { isOpen, onClose } = useRegisterSuccessDialog();

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogContent className="dialog-title">
        <Typography variant="h5">Thank you for registering!</Typography>
        <Button onClick={onClose} variant="outlined" disableElevation>
          <Close />
        </Button>
      </DialogContent>
      <DialogContent dividers>
        <Typography>
          A verification email has been sent to you. Please click on the link to
          activate your account. In case it’s not in your Inbox, please check
          the Spam or Junk folder.{" "}
        </Typography>
      </DialogContent>
    </Dialog>
  );
}
