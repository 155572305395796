import React, {Component} from 'react'
import {Link} from 'react-router-dom'

import {Container, AppBar, Tabs, Tab, InputAdornment, FormControlLabel, Checkbox, Toolbar, MenuItem, FormControl, Typography, Button, InputLabel, Hidden, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Grid, Select} from '@material-ui/core'
import {AccountCircle, Close} from '@material-ui/icons'
import api from 'services/api'

import Media1 from 'images/media/media1.png'
import Media2 from 'images/media/media2.png'
import Media3 from 'images/media/media3.jpg'
import Media4 from 'images/media/media4.png'
import Media5 from 'images/media/media5.png'
import Media6 from 'images/media/media6.jpg'
import Media7 from 'images/media/media7.jpg'
import Media8 from 'images/media/media8.jpg'
import Media9 from 'images/media/media9.png'
import Media10 from 'images/media/media10.png'
import Media11 from 'images/media/media11.png'
import Media12 from 'images/media/media12.svg'

class Page extends Component {

   componentDidMount() {
      window.scrollTo(0,0)
   }

   render() {

      return (
         <div id="media">
            <Container maxWidth="md" >
               <Typography variant="h3" component="h1" className="header-text spaceBig" >Media</Typography>
               <Grid container alignItems="center" >
                  <Grid item xs={3}> <img src={Media1} /> </Grid>
                  <Grid item xs={3}> <img src={Media2} /> </Grid>
                  <Grid item xs={3}> <img src={Media3} /> </Grid>
                  <Grid item xs={3}> <img src={Media4} /> </Grid>
                  <Grid item xs={3}> <img src={Media5} /> </Grid>
                  <Grid item xs={3}> <img src={Media6} /> </Grid>
                  <Grid item xs={3}> <img src={Media7} /> </Grid>
                  <Grid item xs={3}> <img src={Media8} /> </Grid>
                  <Grid item xs={3}> <img src={Media9} /> </Grid>
                  <Grid item xs={3}> <img src={Media10} /> </Grid>
                  <Grid item xs={3}> <img src={Media11} /> </Grid>
                  <Grid item xs={3}> 
                     <a target="_blank" href="https://api.kapitalboost.com/storage/683741_235478.pdf">
                        <img src={Media12} /> 
                     </a>
                  </Grid>
               </Grid>
            </Container>
         </div>
      )
   }
}

export default Page
