import React from "react";
import {
  Button,
  Box,
  makeStyles,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import Campaigns1 from "images/home/campaigns_1.png";
import Campaigns2 from "images/home/campaigns_2.png";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("md")]: {
      padding: "24px 0 42px 0",
      flexDirection: "column",
      alignItems: "center",
    },
    padding: "75px 130px 15px 118px",
    backgroundColor: "#ffffff",
    display: "flex",
    justifyContent: "space-between",
  },
  title: {
    [theme.breakpoints.down("md")]: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      fontSize: 21,
      fontFamily: 'Raleway',
      width: 236,
      height: 60,
    },
    width: 250,
    fontSize: '33px',
    fontFamily: 'Raleway',
    fontWeight: 'bold',
    lineHeight: '40px',
    // ...theme.typography.heading2,
    color: theme.palette.darkLightBlue.main,
  },
  campaignsRoot: {
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
    display: "flex",
  },
  campaignCard1: {
    [theme.breakpoints.down("md")]: {
      height: 454,
      backgroundPosition: "center !important",
      marginBottom: 31,
    },
    backgroundImage: `url(${Campaigns1})`,
    backgroundRepeat: "no-repeat",
    width: 387,
    height: 751,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: 60,
  },
  campaignCard2: {
    [theme.breakpoints.down("xs")]: {
      height: 454,
      backgroundPosition: "center !important",
      paddingTop: 28,
    },
    backgroundImage: `url(${Campaigns2})`,
    backgroundRepeat: "no-repeat",
    width: 387,
    height: 751,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: 60,
  },
  campaignTitle: {
    [theme.breakpoints.down("xs")]: {
      fontFamily: "Roboto",
      fontSize: 18,
      width: "100%",
      height: 54,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginBottom: 18,
    },
    ...theme.typography.heading3,
    color: theme.palette.gray6.main,
    textAlign: "center",
    marginBottom: 53,
    width: 157,
  },
  campaignButton: {
    [theme.breakpoints.down("xs")]: {
      width: 151,
      height: 40,
    },
    width: 240,
    height: 48,
    backgroundColor: theme.palette.sunshine.main,
    borderRadius: 50,
  },
  campaignButtonLabel: {
    [theme.breakpoints.down("xs")]: {
      fontSize: 12,
    },
    fontSize: 16,
    fontWeight: 400,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#ffffff",
    textTransform: "none",
  },
}));

export default function OurCampaigns() {
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div className={classes.root}>
      <div className={classes.title}>See our campaigns</div>
      <Box height={matches ? "32px" : "120px"} />
      <div className={classes.campaignsRoot}>
        <div className={classes.campaignCard1}>
          <div className={classes.campaignTitle}>SME Campaign</div>
          <Button
            onClick={() => history.push("/campaign?i=sme")}
            classes={{
              root: classes.campaignButton,
              label: classes.campaignButtonLabel,
            }}
          >
            View all projects
          </Button>
        </div>
        <Box width="30px" />
        <div className={classes.campaignCard2}>
          <div className={classes.campaignTitle}>Donation Campaign</div>
          <Button
            onClick={() => history.push("/campaign?i=donation")}
            classes={{
              root: classes.campaignButton,
              label: classes.campaignButtonLabel,
            }}
          >
            View all projects
          </Button>
        </div>
      </div>
    </div>
  );
}
