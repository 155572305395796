import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'

import { Container, AppBar, CircularProgress, Paper, ExpansionPanel, LinearProgress, ExpansionPanelSummary, ExpansionPanelDetails, Tabs, Tab, InputAdornment, FormControlLabel, Checkbox, Toolbar, MenuItem, FormControl, Typography, Button, InputLabel, Hidden, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Grid, Select } from '@material-ui/core'
import { AccountCircle, Close } from '@material-ui/icons'
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3'
import api from 'services/api'
import hp from 'services/hp'

class Page extends Component {

   state = {
      is_verified: 'null',
      password: '',
      password_confirm: '',
      password_error: false,
      password_confirm_error: false,
      activate: null,
   }

   componentDidMount() {
      window.scrollTo(0, 0)
      this.onStart()
   }

   onStart = async () => {
      const { match } = this.props
      if (hp.is_login()) {
         this.props.history.push('/')
      }
      setTimeout(async () => {
         const res = await api.post('activate-check', { code: match.params.code })
         const { data } = res
         if (data.error) {
            if (data.code == '001') {
               this.setState({ is_verified: 'no' })
            } else {
               this.setState({ is_verified: 'yes' })
            }
            return
         }
         this.setState({ is_verified: 'pending', activate: data })

      }, 2000)
   }

   onChange = (e, f) => {
      let { state } = this
      state[f] = e.target.value
      this.setState({ state })
   }

   onSubmit = async (e) => {
      e.preventDefault()
      this.setState({ password_error: false, password_confirm_error: false })
      const { password, password_confirm } = this.state
      const { match } = this.props
      if (password.length < 8) {
         this.setState({ password_error: true })
         return
      }
      if (password != password_confirm) {
         this.setState({ password_confirm_error: true })
         return
      }
      if (!password.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/)) {
         this.setState({ password_error: true })
         return
      }
      const recaptcha_token = await this.props.googleReCaptchaProps.executeRecaptcha('activate')
      let activate_res = await api.post('activate', { code: match.params.code, password, recaptcha_token })
      let { data } = activate_res
      if (data.error) {
         alert(data.message)
         return
      }
      this.setState({ is_verified: 'yes' })
   }

   render() {
      const { is_verified, password, password_confirm, password_error, password_confirm_error, activate } = this.state
      return (
         <Container id="verify">

            <Paper id="paper" className="paddingBig" elevation={3} >
               {is_verified == 'null' ?
                  <Fragment>
                     <Typography className="space">Please wait while we activate your account</Typography>
                     <LinearProgress className="space" />
                  </Fragment>
                  : is_verified == 'no' ?
                     <Fragment>
                        <Typography className="red spaceBottom" >Activation code is incorrect</Typography>
                        <a href="mailto:support@kapitalboost.com" ><Typography>Email Us</Typography></a>
                     </Fragment>
                     : is_verified == 'pending' ?
                        <form onSubmit={this.onSubmit}>
                           <Typography className="blue spaceBottom">Please enter a new Password</Typography>

                           {activate &&
                              <Fragment>
                                 <TextField margin="normal" label="Name" disabled value={`${activate.firstname} ${activate.lastname}`} fullWidth  />
                                 <TextField margin="normal" label="Email" disabled value={activate.email} fullWidth  />
                              </Fragment>
                           }

                           <TextField margin="normal" label="Password" type="password" value={password} onChange={(e) => this.onChange(e, 'password')} fullWidth required error={password_error} helperText={'Minimum 8 Characters with at least 1 capital letter, 1 number and 1 symbol'} />
                           <TextField margin="normal" label="Confirm Password" type="password" value={password_confirm} onChange={(e) => this.onChange(e, 'password_confirm')} fullWidth required error={password_confirm_error} helperText={password_confirm_error && 'Confirm Password must be the same as the Password'} />
                           <br/>
                           <Button variant="contained" color="primary" fullWidth type="submit" >Change Password</Button>
                        </form>
                        :
                        <Fragment>
                           <Typography className="blue spaceBottom">Activation Successful</Typography>
                           <Button variant="contained" color="primary" fullWidth onClick={this.props.onLoginDialog} >Please Login</Button>
                        </Fragment>
               }

            </Paper>

         </Container>
      )
   }
}

export default withGoogleReCaptcha(Page)
