import React, { Component, useCallback, useEffect } from "react";
import api from "services/api";

function MekariCallback(props) {
    const queryParameters  = new URLSearchParams(window.location.search)
    const code = queryParameters.get('code')
    const locale = queryParameters.get('locale')

    const fetchCampaign = async (code) => {
          let url = `esign/store-code/${code}`;
    
          await api.post(url).then(res => {
            console.log(res);
            alert("Success to store authorized code");
        }).catch(err => {
              console.log(err);
            alert("please try again");
          });
    };
    
    useEffect(() => {
        fetchCampaign(code);
      }, []);

    return (
        <div align="center" style={{padding: 25}}>
            <h1>Callback From Mekari Esign</h1>
            <p>Code : {code}</p>
            <p>Locale : {locale}</p>
        </div>
    )
}

export default MekariCallback;