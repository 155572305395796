import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'

import { Container, AppBar, Tabs, Tab, Paper, Snackbar, InputAdornment, CircularProgress, Table, TableContainer, TableHead, TableBody, TableRow, TableCell, FormControlLabel, Checkbox, Toolbar, MenuItem, FormControl, Typography, Button, InputLabel, Hidden, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Grid, Select } from '@material-ui/core'
import { AccountCircle, Close } from '@material-ui/icons'
import { format } from 'date-fns'
import api from 'services/api'

class Page extends Component {

   state = {
      projects: null
   }

   componentDidMount() {
      window.scrollTo(0, 0)
      this.onStart()
   }

   onStart = async () => {
      const res = await api.get('project')
      const { data } = res
      // console.log(data)
      this.setState({ projects: data })
   }

   render() {
      const { projects } = this.state
      return (
         <div id="project">

            <Container maxWidth="xl" >
               <Grid container spacing={4} >
                  <Grid item xs={12}>
                     <TableContainer component={Paper} id="dashboard-invest-table" className="spaceBottom" >
                        <Table >
                           <TableHead>
                              <TableRow>
                                 <TableCell colSpan={5} className="bold" >Submitted projects</TableCell>
                                 <TableCell colSpan={2} align="right" ><Link to="/get-funded"><Button variant="contained" color="primary" >Submit New Project</Button></Link></TableCell>
                              </TableRow>
                              <TableRow>
                                 <TableCell component="th" className="bold" align="left">No.</TableCell>
                                 <TableCell component="th" className="bold" align="left">Date</TableCell>
                                 <TableCell component="th" className="bold" align="left">Financing Type</TableCell>
                                 <TableCell component="th" className="bold" align="left">Company</TableCell>
                                 <TableCell component="th" className="bold" align="left">Year Established</TableCell>
                                 <TableCell component="th" className="bold" align="left">Est. Annual Revenue</TableCell>
                                 <TableCell component="th" className="bold" align="left">Funding Period</TableCell>
                              </TableRow>
                           </TableHead>
                           <TableBody>
                              {projects ?
                                 <Fragment>
                                    {projects.length > 0 ?
                                       <Fragment>
                                          {projects.map((project, i) => (
                                             <TableRow key={i}>
                                                <TableCell align="left" >{i + 1}</TableCell>
                                                <TableCell align="left" >{format(new Date(project.created_at), 'do LLL yyyy')}</TableCell>
                                                <TableCell align="left" >{project.financing_solution}</TableCell>
                                                <TableCell align="left" >{project.company_name}</TableCell>
                                                <TableCell align="left" >{project.year_established}</TableCell>
                                                <TableCell align="left" >{project.annual_revenue}</TableCell>
                                                <TableCell align="left" >{project.funding_period}</TableCell>
                                             </TableRow>
                                          ))}
                                       </Fragment>
                                       :
                                       <TableRow className="loading-short">
                                          <TableCell colSpan={7} align="center" > <Typography>No project yet</Typography></TableCell>
                                       </TableRow>
                                    }
                                 </Fragment>
                                 :
                                 <TableRow className="loading-short">
                                    <TableCell colSpan={7} align="center" > <CircularProgress /></TableCell>
                                 </TableRow>
                              }
                           </TableBody>
                        </Table>
                     </TableContainer>
                  </Grid>
               </Grid>
            </Container>

         </div>
      )
   }
}

export default Page
