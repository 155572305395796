import React, { createContext, useContext, useState } from "react";

const ResendEmailDialogContext = createContext(null);

function ResendEmailDialogProvider({ children }) {
  const [isOpen, setIsOpen] = useState(false);
  const [email, setEmail] = useState("");

  return (
    <ResendEmailDialogContext.Provider
      value={{
        isOpen,
        email,
        onOpen: (email) => {
          setEmail(email);
          setIsOpen(true);
        },
        onClose: () => setIsOpen(false),
      }}
    >
      {children}
    </ResendEmailDialogContext.Provider>
  );
}

function useResendEmailDialogContext() {
  return useContext(ResendEmailDialogContext);
}

export {
  ResendEmailDialogContext,
  ResendEmailDialogProvider,
  useResendEmailDialogContext,
};
