import React, {Component} from 'react'
import { Veriff } from '@veriff/js-sdk';
import api from "services/api";
import { Button } from '@material-ui/core';

const loadSDKVeriff = () => {
  const script = document.createElement("script");

  script.src = "https://cdn.veriff.me/incontext/js/v1/veriff.js";
  script.async = true;

  document.body.appendChild(script);
}

class VeriffForm extends Component {
  state = {
    veriff: this.props.data,
    profile: this.props.profile,
    startOffer: this.props.startOffer,
    buttonText: this.props.buttonText
  }

  reLoad = async () => {
    const res = await api.get("veriff/show");
    const veriff = res.data.data

    this.setState({ veriff });
    this.props.resetVeriff(veriff)
  }

  createVeriff = async (params) => {
    const res = await api.post("veriff/create", params);
    return res;
  }

  onFinished = async (session_id) => {
    const res = await api.post(`veriff/update/${session_id}`, { status: 'finished' });
    const {veriff} = res.data
    
    this.setState({ veriff: veriff });
    this.props.resetVeriff(veriff)
  }

  veriffWindow = (verification) => {
    const reLoad = this.reLoad.bind(this)
    const onFinished = this.onFinished.bind(this)
    const s_id = verification.session_id ? verification.session_id : verification.id

    window.veriffSDK.createVeriffFrame({
      url: verification.url,
      onEvent: function(msg) {
        if(msg === 'FINISHED') {
          onFinished(s_id)
        }

        if (msg === "CANCELED") reLoad()
      }
    })
  }

  veriff = () => {
    const createVeriff = this.createVeriff.bind(this)
    const VeriffWindow = this.veriffWindow.bind(this)
    const VERIFF_PUBKEY = process.env.REACT_APP_VERIFF_PUBKEY

    return Veriff({
      host: 'https://stationapi.veriff.com',
      apiKey: VERIFF_PUBKEY,
      parentId: 'veriff-root',
      onSession: function(err, response) {
        // Create data Veriff
        createVeriff(response.verification)
    
        VeriffWindow(response.verification)
      }
    })
  };

  componentWillMount() {
    loadSDKVeriff()
  }

  componentDidMount() {
    if (this.state.veriff === null || this.state.startOffer === true) {

      const veriffAction = this.veriff()

      veriffAction.setParams({
        person: {
          givenName: this.state.profile.firstname,
          lastName: this.state.profile.lastname
        },
        vendorData: this.state.profile.email
      })
      veriffAction.mount({
        submitBtnText: this.state.buttonText ? this.state.buttonText : "Get Verified"
      });
    }
  }

  render() {
    const { veriff, startOffer } = this.state;

    return (
      <div align="center">
        {startOffer === false && (
          <h2>
            {`As the last step`} <br />
            {`Let’s verify your profile to complete the KYC process`}
          </h2>
        )}

        {veriff === null || startOffer === true ? (
          <div id='veriff-root' style={{margin: 'auto'}}></div>
        ) : (
          <>
            <Button variant="contained" color="primary" size="large" style={{width: '400px', backgroundColor: '#004e5f'}} disableElevation onClick={() => this.veriffWindow(veriff)}>
              Get Verified
            </Button>
            <small style={{marginTop: '15px', display: 'block'}}>
              <a href="https://veriff.com" target="_blank">Veriff</a> is an identity verification provider that helps companies connect <br />with customers.
            </small>
          </>
        )}
      </div>
    )
  }
}

export default VeriffForm;