import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { format } from "date-fns";

import {
  Container,
  AppBar,
  Tabs,
  Tab,
  Paper,
  InputAdornment,
  Table,
  TableContainer,
  TablePagination,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  Toolbar,
  MenuItem,
  FormControl,
  Typography,
  Button,
  InputLabel,
  Hidden,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Grid,
  Select,
} from "@material-ui/core";
import { AccountCircle, Close } from "@material-ui/icons";
import MaterialTable from "material-table";
import api from "services/api";
import hp from "services/hp";

class Page extends Component {
  constructor() {
    super();
    this.state = {
      portfolios: null,
      tab_index: 0,
    };
    this.tableRef = React.createRef();
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const { hash } = this.props.location;
    if (hash == "#sme") {
      this.setState({ tab_index: 0 });
    } else if (hash == "#donation") {
      this.setState({ tab_index: 1 });
    } else if (hash == "#payment") {
      this.setState({ tab_index: 2 });
    }
    this.onStart();
  }
  onStart = async () => {
    const portfolios_res = await api.get("portfolio");
    const portfolios = portfolios_res.data;
    this.setState({ portfolios });
  };

  onChangeTab = (e, value) => {
    e.preventDefault();
    this.setState({ tab_index: value });
    if (value == 0) {
      window.location.hash = "sme";
    } else if (value == 1) {
      window.location.hash = "donation";
    } else {
      window.location.hash = "payment";
    }
  };

  render() {
    const { portfolios, tab_index } = this.state;

    return (
      <Container id="portfolios" maxWidth="lg">
        <Tabs
          value={tab_index}
          onChange={this.onChangeTab}
          aria-label="simple tabs example"
        >
          <Tab label="SME" />
          <Tab label="DONATION" />
          <Tab label="PAYMENT SCHEDULE" />
        </Tabs>

        {portfolios ? (
          <div id="portfolio-tabs">
            {tab_index == 0 ? (
              <Fragment>
                {portfolios.invests
                  .filter((invests) => invests.campaign.type == "sme")
                  .map((invest, i) => (
                    <Fragment key={i}>{this.renderPapers(invest)}</Fragment>
                  ))}
              </Fragment>
            ) : tab_index == 1 ? (
              <Fragment>
                {portfolios.invests
                  .filter((invests) => invests.campaign.type == "donation")
                  .map((invest, i) => (
                    <Fragment key={i}>{this.renderPapers(invest)}</Fragment>
                  ))}
              </Fragment>
            ) : (
              <Fragment>{this.renderPayments()}</Fragment>
            )}
          </div>
        ) : (
          <div className="loading">
            <CircularProgress />
          </div>
        )}
      </Container>
    );
  }

  renderPapers(invest) {
    return (
      <Paper className="spaceBig">
        <div className="padding">
          <Grid container alignItems="flex-start" spacing={3}>
            <Grid item xs={4}>
              <img src={invest.campaign.cover_image} width="100%" />
            </Grid>
            <Grid item xs={8}>
              <Typography variant="h5" component="h3" className="bold blue">
                {invest.campaign.acronim}
              </Typography>

              <Typography className="spaceBig">
                {invest.campaign.snippet}
              </Typography>

              <TableContainer className="spaceBottom">
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>Investment Amount</TableCell>
                      <TableCell>: ${hp.format_money(invest.amount)}</TableCell>
                      <TableCell>Principal Payment</TableCell>
                      {/* buat 3 kondisi, paid, unpaid and Pending approval */}
                      {invest.is_paid === 1 ? (
                        <TableCell className="green">: Paid</TableCell>
                      ) : (
                        <>
                          {invest.is_paid === 2 ? (
                            <TableCell className="yellow">: Pending approval</TableCell>
                            ):(
                              <TableCell className="red">: Unpaid</TableCell>
                          )}
                        </>
                      )}
                    </TableRow>
                    <TableRow>
                      <TableCell>Returns</TableCell>
                      <TableCell>: {invest.campaign.return}%</TableCell>
                      <TableCell>Investment Date</TableCell>
                      <TableCell>
                        :{" "}
                        {format(
                          new Date(invest.created_at.replace(/ /g, "T")),
                          "do LLL yyyy"
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Risk Level</TableCell>
                      <TableCell>: {invest.campaign.risk}</TableCell>
                      <TableCell>Tenor</TableCell>
                      <TableCell>: {invest.campaign.tenor}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              {!invest.is_paid && (
                <Link to={`/dashboard/portfolio/${invest.id}`}>
                  <Button variant="contained" color="primary" fullWidth>
                    Pay Now
                  </Button>
                </Link>
              )}
            </Grid>
          </Grid>
        </div>
      </Paper>
    );
  }
  handleChangePage = (e, newPage) => {
    window.scrollTo(0, 0);
    this.setState({ page: newPage });
  };

  renderPayments(payments) {
    return (
      <MaterialTable
        tableRef={this.tableRef}
        columns={[
          { title: "Payout id", field: "id", editable: "never" },
          {
            title: "Campaign Name",
            field: "investment.campaign.acronim",
            sorting: false,
          },
          {
            title: "Investment Date",
            field: "investment.created_at",
            editable: "never",
            type: "datetime",
            sorting: false,
          },
          { title: "Payout Amount (SGD)", field: "amount", type: "currency" },
          { title: "Payout Date", field: "date", type: "date" },
          {
            title: "Payout Statue",
            field: "status",
            lookup: {
              paid: "Paid",
              "on-going": "On Going",
              delayed: "Delayed",
            },
          },
        ]}
        title="Payment Schedule"
        options={{
          pageSize: 10,
          pageSizeOptions: [10, 20, 50, 100, 200],
          actionsColumnIndex: -1,
        }}
        data={(query) =>
          new Promise(async (resolve) => {
            let field = query.orderBy ? query.orderBy.field : "";
            let filters = [];
            if (query.filters.length > 0) {
              query.filters.map((data) => {
                let column = {};
                let value = [];
                column.field = data.column.field;
                value = data.value;
                filters.push({ column: column, value: value });
              });
              filters = JSON.stringify(filters);
            }

            const result = await api.get(
              `payment?limit=${query.pageSize}&page=${
                query.page
              }&search=${query.search}&orderDirection=${
                query.orderDirection
              }&field=${field}`
            );
            const { data } = result;
            resolve({
              data: data.data,
              page: query.page,
              totalCount: data.total,
            });
          })
        }
        actions={[
          {
            icon: "refresh",
            tooltip: "Refresh Data",
            isFreeAction: true,
            onClick: () => {
              this.tableRef.current && this.tableRef.current.onQueryChange();
              this.onStart();
            },
          },
        ]}
      />
    );
  }
}

export default Page;
