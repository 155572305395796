import React, {Component} from 'react'
import {Link} from 'react-router-dom'

import {Container, AppBar, Tabs, Tab, Divider, InputAdornment, Paper, CircularProgress, FormControlLabel, Checkbox, Toolbar, MenuItem, FormControl, Typography, Button, InputLabel, Hidden, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Grid, Select} from '@material-ui/core'
import {AccountCircle, Close} from '@material-ui/icons'
import api from 'services/api'
import ShareGoogle from 'images/dashboard/share-google.jpg'
import ShareFacebook from 'images/dashboard/share-facebook.jpg'
import ShareTwitter from 'images/dashboard/share-twitter.jpg'
import ShareLinkedin from 'images/dashboard/share-linkedin.jpg'

class Page extends Component {

   constructor(props) {
      super(props)
      this.state = {
         id: null,
         invest: null,
      }
   }

   componentDidMount() {
      window.scrollTo(0,0)
      setTimeout( () => {
         window.scrollTo(0,0)
      }, 500)
      let {pathname} = this.props.location
      let id = pathname.split('/')[3]
      this.setState({ id }, () => {
         this.onStart()
      })
   }

   onStart = async() => {
      const {id} = this.state
      const invest_res = await api.get(`invest/${id}`)
      const invest = invest_res.data
      this.setState({ invest })
   }



   render() {
      const {invest, bank_name, account_name, account_number, file, submit_loading} = this.state
      return (
         <div id="portfolio-thankyou" className='padding'>
            {invest ?
               <Container maxWidth="xl">
                  <Paper id="portfolio-content" >
                     <div className="padding" >
                        <Typography variant="h5" component="h1" className="spaceBottomX blue" >Thank you for providing the proof of payment for investment in the <strong>{invest.campaign.name}</strong> campaign.</Typography>
                        <Typography className="spaceBig">Our team will confirm and update your payment status shortly, which will be reflected on the <Link to="/dashboard/portfolio">Portfolio</Link> page of your Dashboard.</Typography>
                        <Typography className="spaceBottomX">If you have any questions, please email us at <a href="mailto:support@kapitalboost.com">support@kapitalboost.com</a> </Typography>



                     </div>
                  </Paper>
               </Container>
               :
               <div className="loading"><CircularProgress /></div>
            }
         </div>
      )
   }
}

export default Page
