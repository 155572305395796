import {
  Button,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  makeStyles,
  OutlinedInput,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { Link } from "react-router-dom";

import Bg from "images/bg-login.png";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { useForgetPasswordDialog } from "contexts/ForgetPasswordDialogContext";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

import api_public from "services/api_public";
import hp from "services/hp";
import { useResendEmailDialogContext } from "contexts/ResendEmailDialogContext";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: `url(${Bg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    paddingTop: 120,
    paddingBottom: 120,
  },
  paper: {
    padding: 60,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 15,
    [theme.breakpoints.up("sm")]: {
      padding: 120,
    },
  },
  title: {
    fontFamily: "Raleway",
    fontWeight: 400,
    fontSize: 32,
    color: "#023e8a",
    textAlign: "center",
    [theme.breakpoints.up("sm")]: {
      fontSize: 48,
    }
  },
  textField: {
    width: "100%",
  },
  button: {
    width: "100%",
    borderRadius: 50,
    color: "#fff",
    backgroundColor: "#F9AB2E",
    "&:hover": {
      backgroundColor: "#F9AB2E",
    },
  },
}));

const Login = ({ location }) => {
  const classes = useStyles();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { onOpen: onOpenForgetPasswordDialog } = useForgetPasswordDialog();
  const { onOpen: onOpenResendEmailDialog } = useResendEmailDialogContext();
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState({
    email: "",
    password: "",
  });

  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    const recaptcha_token = await executeRecaptcha("login");
    const { data } = await api_public.post("login", {
      ...form,
      recaptcha_token,
    });

    setIsLoading(false);

    if (data.error) {
      if (data.code === "003") {
        onOpenResendEmailDialog(form.email);
        return;
      }

      alert(data.message);
      return;
    }

    if (data.login.id) {
      hp.save_user(data.user);
      hp.save_bank(data.banks);
      localStorage.setItem("id", data.login.id);
      localStorage.setItem("token", data.login.token);
      window.location.reload();
      if (data.referral_code) {
        //* jika referral code is true
        window.location.reload();
      }
    } else {
      alert("Error");
      return;
    }
  }

  return (
    <div className={classes.root}>
      <Container maxWidth="md">
        <Paper elevation={0} className={classes.paper}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Typography className={classes.title}><b style={{fontWeight: 700}}>Welcome.</b> Please login...</Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Email Address"
                  variant="outlined"
                  name="email"
                  value={form.email}
                  onChange={(e) =>
                    setForm({
                      ...form,
                      [e.target.name]: e.target.value,
                    })
                  }
                  className={classes.textField}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl variant="outlined" className={classes.textField}>
                  <InputLabel htmlFor="outlined-adornment-password">
                    Password
                  </InputLabel>
                  <OutlinedInput
                    name="password"
                    type={showPassword ? "text" : "password"}
                    value={form.password}
                    onChange={(e) =>
                      setForm({
                        ...form,
                        [e.target.name]: e.target.value,
                      })
                    }
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                    labelWidth={70}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  <a
                    href="javascript:;"
                    onClick={() => onOpenForgetPasswordDialog()}
                  >
                    Forgot your password?
                  </a>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  disableElevation
                  className={classes.button}
                  disabled={isLoading}
                >
                  {!isLoading ? (
                    "Login"
                  ) : (
                    <CircularProgress size={24} color="secondary" />
                  )}
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  style={{
                    textAlign: "center",
                  }}
                >
                  Don't have an account?{" "}
                  <Link to={"/registration"}>Register</Link>
                </Typography>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Container>
    </div>
  );
};

export default Login;
