import { Box, Button, Hidden, makeStyles, Typography } from "@material-ui/core";
import React from "react";

import WhatWeDoImage from "images/home/what-we-do.jpg";
import WhatWeDoImage2 from "images/home/what-we-do2.png";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("xs")]: {
      padding: "0 !important",
      flexDirection: "column",
      alignItems: "flex-start",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0 !important",
      flexDirection: "column",
      alignItems: "flex-start",
    },
    [theme.breakpoints.down("md")]: {
      padding: "50px 0",
    },
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    padding: "75px 0",
  },
  whatWeDoTextWrapper: {
    [theme.breakpoints.down("xs")]: {
      padding: "19px 50px 50px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "19px 50px 50px",
    },
    [theme.breakpoints.down("md")]: {
      paddingLeft: 61.5,
    },
    paddingLeft: 123,
  },
  image: {
    [theme.breakpoints.down("xs")]: {
      transform: "none !important",
      height: 290,
      objectFit: "cover",
      width: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      transform: "none !important",
      height: 235,
      objectFit: "cover",
      width: "100%",
    },
    [theme.breakpoints.down("md")]: {
      transform: "translateX(40px)",
      height: '500px',
    },
    height: '620px',
    transform: "translateX(50px)",
    borderRadius: '10px'
  },
  textHead: {
    [theme.breakpoints.down("xs")]: {
      fontSize: 21,
      width: 123,
      height: 48,
      display: "flex",
      justifyContent: "center",
      aligItems: "center",
    },
    ...theme.typography.heading2,
    color: theme.palette.darkLightBlue.main,
    lineHeight: 2,
    marginBottom: 15,
  },
  textParagraph: {
    [theme.breakpoints.down("xs")]: {
      fontSize: 11,
    },
    ...theme.typography.body3,
    color: theme.palette.gray2.main,
    marginBottom: 20,
    lineHeight: 1.7,
    textAlign: "justify",
  },
  button: {
    [theme.breakpoints.down("xs")]: {
      minWidth: 143,
      height: 38,
      fontSize: 12,
    },
    minWidth: 200,
    height: 48,
    borderRadius: 50,
    backgroundColor: theme.palette.sunshine.main,
    color: "#ffffff",
    fontSize: 16,
    fontWeight: 400,
    textTransform: "capitalize",
  },
  buttonWrapper: {
    [theme.breakpoints.down("xs")]: {
      marginTop: 20,
    },
    marginTop: 40,
  },
}));

export default function WhatWeDo() {
  const classes = useStyles();

  function handleClick() {
    const win = window.open(
      "https://www.youtube.com/watch?v=PcIBqRsKRlc&ab_channel=KapitalBoostIndonesia",
      "_blank"
    );
    win.focus();
  }

  return (
    <div className={classes.root}>
      <Hidden mdUp>
        <img src={WhatWeDoImage} className={classes.image} alt="What we do" />
      </Hidden>

      <div className={classes.whatWeDoTextWrapper}>
        <Typography
          classes={{
            root: classes.textHead,
          }}
        >
          What we do
        </Typography>
        <p className={classes.textParagraph}>
          Kapital Boost is an Islamic peer-to-peer (P2P) platform that connects global investors with promising SMEs in Indonesia and Singapore, offering short-term, ethical investment opportunities.
        </p>
        <p className={classes.textParagraph}>
          Established in 2015, our primary objective is to address the financing constraints faced by SMEs. Kapital Boost helps to level the playing field by offering these enterprises a crowdfunding platform to access temporary liquidity for their working capital needs. By doing so, we empower SMEs to thrive and succeed in today's fiercely competitive market.
        </p>
        <p className={classes.textParagraph}>
          Kapital Boost prioritizes inclusivity by allowing members to invest in promising ventures with a minimum amount of SGD200. Our goal is to democratize impactful investments, ensuring a broad spectrum of investors can actively participate in the growth of SMEs in Southeast Asia.
        </p>
        <div className={classes.buttonWrapper}>
          <Button
            onClick={() => handleClick()}
            classes={{
              root: classes.button,
            }}
          >
            Watch our video
          </Button>
        </div>
      </div>
      <Hidden smDown>
        <Box height="115px" />
        <img src={WhatWeDoImage} className={classes.image} alt="What we do" />
      </Hidden>
    </div>
  );
}
