import React, { Component, useState } from "react";

import './styles/global.scss';

import {
    Paper,
    Container,
    Grid,
    Hidden,
    Button,
    TextField
  } from "@material-ui/core";

  export default function Contact() {
    const [contact, setContact] = useState({});

    const onChange = (event, key) => {
      const currContact = contact
      currContact[key] = event.target.value
      setContact(currContact)
    }

    const onSubmit = async (e) => {
      e.preventDefault();
      console.log(contact)
    }

    return (
        <div data-page="caontact-us">
          <Container>
            <Grid
              container
              direction="row"
              justifyContent="center"
            >
              <Grid md={12}>
                
              </Grid>
              <Grid md={6}>
                <div className="px-2">
                  <h1 className="title">Contact us</h1>

                  <p>
                    {`We’d love to hear from you. Let us know how we can help.`}
                  </p>
                  <p>
                    {`Feedback on our services - positive or negative - is also much welcomed`}
                  </p>

                  <form onSubmit={onSubmit}>
                    <TextField
                      margin="normal"
                      value={contact['name']}
                      onChange={(e) => onChange(e, 'name')}
                      label={'Name'}
                      required={true}
                      variant="outlined"
                      fullWidth
                    />
                    <TextField
                      margin="normal"
                      value={contact['email']}
                      onChange={(e) => onChange(e, 'email')}
                      label={'Email Address'}
                      required={true}
                      variant="outlined"
                      fullWidth
                    />
                    <TextField
                      margin="normal"
                      value={contact['message']}
                      onChange={(e) => onChange(e, 'message')}
                      label={'Message'}
                      multiline
                      rows={4}
                      required={true}
                      variant="outlined"
                      fullWidth
                    />
                    <button className="btn-kb btn-blue btn-sm btn-full my-2" type="submit">
                      Submit
                    </button>
                  </form>
                </div>
              </Grid>
              <Grid md={6}>
                <div className="px-2">
                  {/* <h3>Our Office</h3> */}
                  
                  <Paper elevation={3} className='mt-2'>
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.796413887797!2d103.78525996023855!3d1.296808688193061!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da1a4fd6a29643%3A0x1cbb8a56dd8c9ccf!2s71%20Ayer%20Rajah%20Crescent%2C%20Singapura%20139951!5e0!3m2!1sid!2sid!4v1617076338797!5m2!1sid!2sid"
                      width="100%"
                      height="380"
                      rameBorder="0"
                      style={{ border: 0 }}
                      allowFullScreen=""
                    ></iframe>
                  </Paper>

                  <div className="my-2">
                    {/* <p className="location-title">Singapore location</p> */}
                    <table className="table-location">
                      <tr>
                        <td>Address:</td>
                        <td>71 Ayer Rajah Crescent, #03-25, Singapore 139951</td>
                      </tr>
                      <tr>
                        <td>Whatsapp:</td>
                        <td>8433 1760</td>
                      </tr>
                      <tr>
                        <td>Email:</td>
                        <td>
                          <a href="mailto:hello@kapitalboost.com">
                            hello@kapitalboost.com
                          </a>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Container>
        </div>
    );
}