import React, { Component, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import { truncate } from '../utils/helpers';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

import './styles/global.scss';

import {
    Paper,
    Container,
    Grid,
    Hidden,
    Button,
    Typography,
    InputAdornment,
    FormControl, InputLabel, OutlinedInput, IconButton,
    CircularProgress
  } from "@material-ui/core";

import SearchIcon from '@material-ui/icons/Search';

import Image from 'images/about/new/header.png';

import api from "services/api";

export default function Blogs() {
    const [blogs, setBlogs] = useState([]);
    const [visible, setVisible] = useState(3);
    const [search, setSearch] = useState("");
    const [deviceWidth, setDeviceWidth] = useState(1200)

    useEffect(() => {
        async function fetchData() {
            // You can await here
            const blogs_res = await api.get(`blog?search=${search}`);
            const blogs = blogs_res.data;
            
            setBlogs(blogs);

            window.scrollTo(0, 0)
        }

        fetchData();

        setDeviceWidth(window.innerWidth)
    }, [window.innerWidth]);

    const handleClickSearch = async () => {
        const blogs_res = await api.get(`blog?search=${search}`);
        const blogs = blogs_res.data;
        
        setBlogs(blogs);

        window.scrollTo(0, 0)
    }

    const loadMore = () => {
        const currVisible = visible
        const scrollPosition = document.documentElement.scrollTop

        setTimeout(() => {
            setVisible(currVisible + 3)
            window.scrollTo(0, scrollPosition)
        }, 200)
    }

    return (
        <div data-page="blog">
            <Container>
                <h1 className="title">Blog</h1>

                <Grid
                container
                direction='row'
                justifyContent="center"
                alignItems="flex-start"
                spacing={3}
                >
                    {blogs.length <= 0 && 
                        <div className="d-block width-100 text-center py-2"><CircularProgress /></div>}
                    {blogs.slice(0, visible).map((item, idx) => (
                        <Grid item md={4}>
                            <div className="list-of-blogs">
                                <div className={`item-blog ${idx + 1 > (visible - 3) ? 'fade-in':''}`} key={idx}>
                                    <div className="image-blog">
                                        <img src={item.image} alt={`Title of Blog`} width="100%" />
                                    </div>

                                    <small className="time">
                                        {format(
                                            new Date(item.created_at),
                                            "iiii, d MMMM yyyy"
                                        )}
                                    </small>

                                    <h3 className="blog-title">{truncate(item.title, 50)}</h3>
                                    
                        
                                    <Typography
                                        className="spaceBottom"
                                            dangerouslySetInnerHTML={{
                                                __html: item.content + "..",
                                            }}
                                    />
                                    <Link to={`/blog/${item.slug}`} className="blog-link">
                                        Read more
                                        <ArrowRightAltIcon />
                                    </Link>
                                </div>
                            </div>
                        </Grid>
                    ))}

                    {blogs.length >= visible ? <button className={`btn-link mt-2`} onClick={() => loadMore()}>See more</button>:"" }
                </Grid>
            </Container>
        </div>
    );
}