import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";

import {
  Container,
  AppBar,
  Tabs,
  Tab,
  Paper,
  Snackbar,
  InputAdornment,
  CircularProgress,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  FormControlLabel,
  Checkbox,
  Toolbar,
  MenuItem,
  FormControl,
  Typography,
  Button,
  InputLabel,
  Hidden,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Grid,
  Select,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Input,
} from "@material-ui/core";
import { AccountCircle, Close } from "@material-ui/icons";
import api from "services/api";
import hp from "services/hp";
import MaterialTable from "material-table";
import { Alert } from "@material-ui/lab";

class Page extends Component {
  constructor() {
    super();
    this.state = {
      openSuccessSnack: false,
      accounts: null,
      open_withdraw: false,
      balance: null,
      withdraw_amount: 0,
      withdraw_loading: false,
      withdraw_pending_count: null,
      withdraw_bank: "",
      error: {
        withdrawal: null
      }
    };
    this.withdrawTable = React.createRef();
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.onStart();
  }
  onStart = async () => {
    const balance_res = await api.get("kb-wallet/balance");
    const balance = balance_res.data;
    this.setState({ balance });

    const account_res = await api.get("bank");
    const accounts = account_res.data.data;
    // console.log(accounts);
    this.setState({ accounts });

    const withdraw_pending_count_res = await api.get(
      "kb-wallet/withdraw-pending"
    );
    const withdraw_pending_count = withdraw_pending_count_res.data;
    this.setState({ withdraw_pending_count });
  };

  onChange = (e, f, g) => {
    let { state } = this;
    state[f][g] = e.target.value;
    this.setState({ state });
  };
  onChange1 = (e, f) => {
    let { state } = this;
    state[f] = e.target.value;
    this.setState({ state });
  };

  onWithdrawOpen = (e) => {
    e.preventDefault();
    this.setState({ open_withdraw: true });
  };
  onWithdrawClose = () => {
    this.setState({ open_withdraw: false });
  };
  onChangeSelect = (e) => {
    this.setState({ withdraw_bank: e.target.value });
  };

  onWithdraw = async (e) => {
    e.preventDefault();
    const { withdraw_amount, balance, withdraw_bank } = this.state;

    if (withdraw_amount == 0) {
      alert("Amount cannot be 0");
      return;
    }
    if (withdraw_amount < 500) {
      alert("Minimum amount for withdrawal is $500");
      return;
    }
    if (withdraw_amount > balance) {
      alert("Your balance is not sufficient");
      return;
    }
    if (withdraw_bank == "") {
      alert("Please select a bank account");
      return;
    }

    this.setState({ withdraw_loading: true });
    
    const res = await api.post(`kb-wallet/withdraw`, {
      amount: withdraw_amount,
      bank: withdraw_bank,
    });
    
    this.setState({
      withdraw_loading: false,
      withdraw_amount: 0,
      open_withdraw: false,
      openSuccessSnack: true,
    });
    this.onStart();
    this.withdrawTable.current && this.withdrawTable.current.onQueryChange();
  };

  render() {
    const {
      accounts,
      withdraw_bank,
      balance,
      withdraw_amount,
      withdraw_loading,
      withdraw_pending_count,
    } = this.state;
    // console.log(accounts);
    return (
      <div id="kb-wallet">
        <Container maxWidth="xl">
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Card className="spaceBottomX">
                <CardHeader title="KB-Wallet balance" />
                <Divider />
                <CardContent>
                  {balance != null ? (
                    <Typography variant="h4">
                      ${hp.format_money(balance)}
                    </Typography>
                  ) : (
                    <CircularProgress />
                  )}
                  {withdraw_pending_count != null ? (
                    <>
                      {withdraw_pending_count == 0 ? (
                        <Typography>There is no pending withdrawal</Typography>
                      ) : (
                        <Typography>
                          {withdraw_pending_count} pending withdrawal request
                        </Typography>
                      )}
                    </>
                  ) : ""}
                </CardContent>
                <Divider />
                <Grid container spacing={2} className="padding">
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      onClick={(e) => this.onWithdrawOpen(e)}
                    >
                      Withdraw fund
                    </Button>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Card className="spaceBottomX">
                <MaterialTable
                  tableRef={this.transactionTable}
                  title="KB-Wallet transaction activity"
                  columns={[
                    {
                      title: "No",
                      render: (rowData) =>
                        rowData ? rowData.tableData.id + 1 : "",
                    },
                    { title: "Date", field: "created_at", type: "date" },
                    {
                      title: "Type",
                      field: "title",
                      lookup: {
                        "top-up": "Top Up",
                        withdrawal: "Withdrawal",
                        sme: "Invesment",
                        private: "Investment",
                        investment: "Investment",
                        payout: "Payout",
                        donation: "Donation",
                        cashback: 'Cashback'
                      },
                    },
                    { title: "Description", field: "description" },
                    {
                      title: "Amount(SGD)",
                      field: "amount",
                      render: (rowData) =>
                        rowData.amount > 0
                          ? "+ " + hp.format_money(rowData.amount)
                          : "- " + hp.format_money(rowData.amount * -1),
                    },
                  ]}
                  options={{
                    pageSize: 5,
                    pageSizeOptions: [5, 10, 20, 50, 100, 200],
                  }}
                  data={(query) =>
                    new Promise(async (resolve) => {
                      const result = await api.get(
                        `kb-wallet/transaction?limit=${query.pageSize}&offset=${
                          query.page * query.pageSize
                        }&search=${query.search}`
                      );
                      const { data } = result;
                      resolve({
                        data: data.data,
                        page: query.page,
                        totalCount: data.total,
                      });
                    })
                  }
                />
              </Card>
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Card className="spaceBottomX">
                <MaterialTable
                  tableRef={this.withdrawTable}
                  title="KB-Wallet withdraw activity"
                  columns={[
                    {
                      title: "No",
                      render: (rowData) =>
                        rowData ? rowData.tableData.id + 1 : "",
                    },
                    {
                      title: "Date Requested",
                      field: "created_at",
                      type: "date",
                    },
                    {
                      title: "Amount(SGD)",
                      field: "amount",
                      render: (rowData) =>
                        "SGD " + hp.format_money(rowData.amount),
                    },
                    {
                      title: "Status",
                      field: "status",
                      render: (rowData) =>
                        rowData.status == 1
                          ? `Paid on ${rowData.transfer_at}`
                          : "On Progress",
                    },
                    {
                      title: "Proof of Transfer",
                      field: "proof",
                      render: (rowData) => (
                        <a href={rowData.proof} target="_blank">
                          <img src={rowData.proof} height="200px" />
                        </a>
                      ),
                    },
                  ]}
                  options={{
                    pageSize: 5,
                    pageSizeOptions: [5, 10, 20, 50, 100, 200],
                  }}
                  data={(query) =>
                    new Promise(async (resolve) => {
                      const result = await api.get(
                        `kb-wallet/withdraw?limit=${query.pageSize}&offset=${
                          query.page * query.pageSize
                        }&search=${query.search}`
                      );
                      const { data } = result;
                      resolve({
                        data: data.data,
                        page: query.page,
                        totalCount: data.total,
                      });
                    })
                  }
                />
              </Card>
            </Grid>
          </Grid>
        </Container>

        <Dialog
          open={this.state.open_withdraw}
          onClose={this.onWithdrawClose}
          fullWidth
          maxWidth="md"
        >
          <DialogContent className="dialog-title">
            <Typography variant="h5">Withdraw balance</Typography>
            <Button
              onClick={this.onWithdrawClose}
              variant="outlined"
              disableElevation
            >
              <Close />
            </Button>
          </DialogContent>
          <form onSubmit={(e) => this.onWithdraw(e)}>
            <DialogContent dividers>
              <Typography className="space">
                Funds will be transferred to the following bank account:
              </Typography>
              {accounts && accounts.length > 0 ? (
                <>
                  <Select
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={withdraw_bank}
                    onChange={this.onChangeSelect}
                    displayEmpty
                  >
                    <MenuItem value="" disabled>
                      Please select a bank account
                    </MenuItem>
                    {accounts.map((account, i) => (
                      <MenuItem value={account.id} key={i}>
                        {account.account_name} {account.bank_name}{" "}
                        {account.account_number}
                      </MenuItem>
                    ))}
                  </Select>
                </>
              ) : (
                <Link to="/dashboard/bank-account">Add Bank Account</Link>
              )}

              <FormControl fullWidth className="spaceBig" variant="outlined">
                <InputLabel htmlFor="standard-adornment-amount">
                  How much would you like to withdraw?
                </InputLabel>
                <Input
                  id="standard-adornment-amount"
                  value={withdraw_amount}
                  onChange={(e) => this.onChange1(e, "withdraw_amount")}
                  startAdornment={
                    <InputAdornment position="start">$</InputAdornment>
                  }
                />
                <small style={{marginTop: '7px'}}>The minimum amount for withdrawal is $500</small>
              </FormControl>
            </DialogContent>
            <DialogContent dividers>
              {withdraw_loading ? (
                <Button
                  margin="normal"
                  variant="contained"
                  color="primary"
                  fullWidth
                  className="space"
                >
                  <CircularProgress color="default" size={25} />
                </Button>
              ) : (
                <Button
                  margin="normal"
                  variant="contained"
                  color="primary"
                  fullWidth
                  type="submit"
                  className="space"
                >
                  Withdraw
                </Button>
              )}
            </DialogContent>
          </form>
        </Dialog>

        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={this.state.openSuccessSnack}
          autoHideDuration={6000}
          onClose={() => this.setState({ openSuccessSnack: false })}
        >
          <div className="success-alert">
            <Typography>
              Your request has been successfully submitted
            </Typography>
          </div>
        </Snackbar>
      </div>
    );
  }
}

export default Page;
