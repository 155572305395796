import React, { Component, Fragment } from "react";
import { Link, withRouter } from "react-router-dom";

import {
  Container,
  AppBar,
  Tabs,
  Tab,
  InputAdornment,
  Paper,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  Toolbar,
  MenuItem,
  FormControl,
  Typography,
  Button,
  InputLabel,
  Hidden,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Grid,
  Select,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { AccountCircle, Close } from "@material-ui/icons";
import api from "services/api";
import hp from "services/hp";

class Page extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      campaign: null,
      invest: null,
      open_attach: false,
      bank_name: "",
      account_name: "",
      account_number: "",
      file: null,
      submit_loading: false,
      file_error: false,
      open_wallet: false,
      wallet_amount: null,
      wallet_pay_loading: false,
      open_invest_confirm: false,
      invest_amount: 0,
      is_investing: false,
      user: null,
      accounts: null,
      select_account: ""
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
    let { pathname } = this.props.location;
    let id = pathname.split("/")[3];
    this.setState({ id }, () => {
      this.onStart();
    });
  }

  onStart = async () => {
    const { id, slug } = this.state;
    const invest_res = await api.get(`invest/${id}`);
    const invest = invest_res.data;
    const campaignSlug = invest.campaign.slug;
    // console.log(campaignSlug);
    const campaign_res = await api.get(`campaign/${campaignSlug}`);
    const campaign = campaign_res.data;
    const user = await hp.get_user();
    
    const res = await api.get("bank");
    const { data } = res.data;

    this.setState({ invest, user, campaign, invest_amount: invest.amount, accounts: data });
  };

  onOpenAttach = () => {
    this.setState({ open_attach: true });
  };
  onCloseAttach = () => {
    this.setState({ open_attach: false });
  };
  onSubmit = async (e) => {
    e.preventDefault();
    const { bank_name, account_name, account_number, file, invest } =
      this.state;
    this.setState({ file_error: false });
    if (!file) {
      this.setState({ file_error: true });
      return;
    }
    const body = new FormData();
    body.append("file", file);
    body.append("bank_name", bank_name);
    body.append("account_name", account_name);
    body.append("account_number", account_number);
    body.append("investment_id", invest.id);
    this.setState({ submit_loading: true });
    const res = await api.post("payment-bank", body);
    const { data } = res;
    this.setState({ submit_loading: false });
    if (data.error) {
      alert(data.message);
      // return;
      window.location.reload();
    }
    this.props.history.push(`/dashboard/portfolio-thankyou/${invest.id}`);
  };
  onChange = (e, f) => {
    let { state } = this;
    state[f] = e.target.value;
    this.setState({ state });
  };
  onAttach = (e) => {
    let file = e.target.files[0];
    this.setState({ file });
  };
  onOpenWallet = async () => {
    this.setState({ open_wallet: true, wallet_amount: null });
    let dashboard_res = await api.get("dashboard");
    let wallet_amount = dashboard_res.data.wallet;
    this.setState({ wallet_amount });
  };
  onCloseWallet = () => {
    this.setState({ open_wallet: false });
  };
  onWalletPay = async (e) => {
    e.preventDefault();
    const { invest } = this.state;
    const { history } = this.props;
    this.setState({ wallet_pay_loading: true });
    let pay_res = await api.post("wallet-pay", { investment_id: invest.id });
    this.setState({ wallet_pay_loading: false });
    let pay = pay_res.data;
    if (pay.error) {
      alert(pay.message);
      return;
    }
    history.push("/dashboard/portfolio");
    alert("Your payment is successful");
  };
  onOpenPaypal = async (e) => {
    e.preventDefault();
    this.setState({ open_invest_confirm: true });
    const { invest } = this.state;
    // console.log(invest);
  };
  onPaypalConfirm = async (e) => {
    const { invest } = this.state;
    // console.log(invest);
    if (invest.error || !invest.amount) {
      this.setState({ is_investing: false });
      this.onStart();
      alert(invest.message);
      return;
    }
    this.setState({ is_investing: true });
    // this.renderPaypall();
    this.paypal_form.submit();
  };
  onCloseInvestConfirm = () => {
    this.setState({ open_invest_confirm: false });
  };
  
  onSelectBank = (e) => {
    let { state } = this;

    let account = state.accounts.filter(function (ac) {
      return ac.id == parseInt(e.target.value)
    })

    if (account.length === 0) {
      state.bank_name = ""
      state.account_name = ""
      state.account_number = ""
    } else {
      state.bank_name = account[0].bank_name
      state.account_name = account[0].account_name
      state.account_number = account[0].account_number
    }
    state.select_account = e.target.value
    state.file = null

    this.setState({ state });
  }

  render() {
    const {
      invest,
      bank_name,
      account_name,
      account_number,
      file,
      submit_loading,
      file_error,
      open_wallet,
      wallet_amount,
      wallet_pay_loading,
      is_investing,
      user,
      accounts,
      select_account
    } = this.state;
    return (
      <div id="portfolio" className="padding">
        {invest ? (
          <Container maxWidth="xl">
            {invest.payment_method == "bank-transfer" &&
              invest.bank_name &&
              !invest.is_paid && (
                <Alert severity="info" className="spaceBottom">
                  We've received your bank transfer information. Please allow us
                  2-3 working days to approve your payment.
                </Alert>
              )}
            <Paper id="portfolio-content">
              <div className="padding">
                <Typography
                  variant="h5"
                  component="h1"
                  className="spaceBottomX blue"
                >
                  Thank you for your{" "}
                  {invest.campaign.type == "sme" ? "commitment" : "donation"} of
                  <strong> SGD {hp.format_money(invest.amount)}</strong> in the{" "}
                  <strong>{invest.campaign.acronim}</strong> campaign.
                </Typography>

                { user.country === 'SINGAPORE' && 
                  <Fragment>
                    <Typography className="spaceBottom">
                      Kindly complete payment within the next 48 hours via PayNow or Bank Transfer. Please input the campaign name in the description / remarks / reference section of your transfer.
                    </Typography>
                    <Typography className="bold">
                      (i) Payment method - Bank transfer
                    </Typography>
                    <table className="m-left spaceBottom">
                      <tbody>
                        <tr>
                          <td>
                            <Typography>Account name</Typography>
                          </td>
                          <td>
                            <Typography className="bold">
                              : Kapital Boost Pte Ltd
                            </Typography>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Typography>Bank name</Typography>
                          </td>
                          <td>
                            <Typography className="bold">
                              : CIMB Bank Berhad, Singapore
                            </Typography>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Typography>Account number</Typography>
                          </td>
                          <td>
                            <Typography className="bold">: 2000 562 641</Typography>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <Typography className="bold">
                      (ii) Payment method - PayNow using QR Code
                    </Typography>
                    <img className="spaceBottom" src={require('images/PayNowQRCode.png')} />
                    <Typography className="bold">
                      (iii) Payment method – PayNow using PayNowID / UEN Number 
                    </Typography>
                    <div className="spaceBottom">
                      <table className="m-left spaceBottom">
                        <tbody>
                          <tr>
                            <td>
                              <Typography>Account name</Typography>
                            </td>
                            <td>
                              <Typography className="bold">
                                : Kapital Boost Pte Ltd
                              </Typography>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <Typography>PayNowID</Typography>
                            </td>
                            <td>
                              <Typography className="bold">
                                : 201525866WC01
                              </Typography>
                            </td>
                          </tr> 
                        </tbody>
                      </table>
                    </div>
                    <Grid container style={{
                      margin: '50px 0 20px 0'
                    }}>
                      <Grid item xs={12}>
                        <Typography className="spaceBottom">
                          Once transferred, please click below to submit the proof
                          of payment
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={this.onOpenAttach}
                          style={{}}
                        >
                          Attach transfer slip
                        </Button>
                      </Grid>
                    </Grid>
                  </Fragment>
                }
                { user.country !== 'SINGAPORE' && 
                  <Fragment>
                    {/* start Bank Transfer */}
                    <Typography className="bold">
                      (i) Payment method - Bank transfer
                    </Typography>
                    <Typography className="space m-left">
                      Please make a transfer to the following account:
                    </Typography>

                    <table className="m-left">
                      <thead></thead>
                      <tbody>
                        <tr>
                          <td>
                            <Typography>Account name</Typography>
                          </td>
                          <td>
                            <Typography className="bold">
                              : Kapital Boost Pte Ltd
                            </Typography>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Typography>Bank name</Typography>
                          </td>
                          <td>
                            <Typography className="bold">
                              : CIMB Bank Berhad, Singapore
                            </Typography>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Typography>Account number</Typography>
                          </td>
                          <td>
                            <Typography className="bold">: 2000 562 641</Typography>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Typography>SWIFT code</Typography>
                          </td>
                          <td>
                            <Typography className="bold">: CIBBSGSG</Typography>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Typography>Bank code</Typography>
                          </td>
                          <td>
                            <Typography className="bold">: 7986</Typography>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Typography>Branch code</Typography>
                          </td>
                          <td>
                            <Typography className="bold">: 001</Typography>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <Grid container>
                      <Grid item md={9} xs={12}>
                        <Typography className="m-left spaceBig">
                          Once transferred, please click below to submit the proof
                          of payment
                        </Typography>
                        <Button
                          variant="contained"
                          className="m-left"
                          color="primary"
                          onClick={this.onOpenAttach}
                          style={{}}
                        >
                          Attach transfer slip
                        </Button>
                      </Grid>
                    </Grid>
                    {/* end Bank Transfer */}
                    
                    {/* start Wallet */}
                    {hp.wallet_available() && (
                      <div className="spaceBig">
                        <Grid container>
                          <Grid item md={9} xs={12}>
                            <Typography className="bold space">
                              (ii) Payment method - Wallet
                            </Typography>
                            <Button
                              variant="contained"
                              className="m-left space"
                              color="primary"
                              onClick={this.onOpenWallet}
                            >
                              Pay with wallet
                            </Button>
                          </Grid>
                        </Grid>

                        <Typography className="dim small">
                          *This option is only available for non-Singaporean
                          investors intending to re-invest their payouts
                        </Typography>
                      </div>
                    )}
                    {/* end Wallet */}
                  </Fragment>
                }

                {/* start Paypal */}
                {/* {invest.payment_method == "paypal" && (
                  <div>
                    <Grid container>
                      <Grid item md={9} xs={12}>
                        <Typography className="bold space">
                          (iii) Payment method - Paypal
                        </Typography>
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <Button
                          variant="contained"
                          className="space"
                          color="primary"
                          onClick={this.onOpenPaypal}
                        >
                          Paypal
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                )} */}
                {/* end Paypal */}
              </div>
            </Paper>

            <Dialog open={this.state.open_attach} onClose={this.onCloseAttach}>
              <DialogContent className="dialog-title">
                <Typography variant="h5">
                  Please submit your payment detail to Kapital Boost
                </Typography>
                <Button
                  onClick={this.onCloseAttach}
                  variant="outlined"
                  disableElevation
                >
                  <Close />
                </Button>
              </DialogContent>
              <DialogContent dividers>

                {accounts.length > 0 && (
                  <>
                    <Typography>Campaign: {invest.campaign.name}</Typography>
                    <FormControl margin="normal" fullWidth>
                      <InputLabel>Select Bank Account</InputLabel>
                      <Select
                        value={select_account}
                        onChange={(e) => this.onSelectBank(e, accounts)}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {accounts.map((account, i) => (
                          <MenuItem value={account.id}>{`${account.account_name} - ${account.bank_name} (${account.account_number})`}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </>
                )}
                
                <form onSubmit={this.onSubmit}>
                  <TextField
                    margin="normal"
                    label="Bank name"
                    type="text"
                    value={bank_name}
                    onChange={(e) => this.onChange(e, "bank_name")}
                    fullWidth
                    required
                  />
                  <TextField
                    margin="normal"
                    label="Account holder's name"
                    type="text"
                    value={account_name}
                    onChange={(e) => this.onChange(e, "account_name")}
                    fullWidth
                    required
                  />
                  <TextField
                    margin="normal"
                    label="Bank account number"
                    type="text"
                    value={account_number}
                    onChange={(e) => this.onChange(e, "account_number")}
                    fullWidth
                    required
                  />
                  <input
                    accept="image/*"
                    id="bank-attach-file"
                    type="file"
                    style={{ display: "none" }}
                    onChange={this.onAttach}
                  />
                  <label htmlFor="bank-attach-file">
                    <Button
                      variant="outlined"
                      color="secondary"
                      component="span"
                      className="space"
                    >
                      Attach Transaction Receipt
                    </Button>
                  </label>
                  {file_error && (
                    <Typography className="red">
                      Please attach transaction receipt
                    </Typography>
                  )}
                  {file && <Typography>Attached: {file.name}</Typography>}
                  {submit_loading ? (
                    <Button
                      margin="normal"
                      variant="contained"
                      color="primary"
                      fullWidth
                      className="spaceBig"
                    >
                      <CircularProgress color="default" size={25} />
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      type="submit"
                      color="primary"
                      fullWidth
                      className="spaceBig"
                    >
                      Submit
                    </Button>
                  )}
                </form>
              </DialogContent>
            </Dialog>

            <Dialog open={open_wallet} onClose={this.onCloseWallet}>
              <DialogContent className="dialog-title">
                <Typography variant="h5">
                  Make payment with KB-Wallet
                </Typography>
                <Button
                  onClick={this.onCloseWallet}
                  variant="outlined"
                  disableElevation
                >
                  <Close />
                </Button>
              </DialogContent>
              <DialogContent dividers>
                <Typography className="space">
                  My KB Wallet Balance:{" "}
                  <strong>
                    SGD{" "}
                    {wallet_amount
                      ? hp.format_money(wallet_amount)
                      : // <CircularProgress color="default" size={20} />
                        hp.format_money(0)}
                  </strong>
                </Typography>
                <Typography className="space">
                  Campaign Investment Amount:{" "}
                  <strong>SGD {hp.format_money(invest.amount)} </strong>
                </Typography>
                {wallet_amount ? (
                  <Fragment>
                    {wallet_amount >= parseFloat(invest.amount) ? (
                      <Fragment>
                        {wallet_pay_loading ? (
                          <Button
                            margin="normal"
                            variant="contained"
                            color="primary"
                            fullWidth
                            className="space"
                          >
                            <CircularProgress color="default" size={25} />
                          </Button>
                        ) : (
                          <Button
                            margin="normal"
                            variant="contained"
                            color="primary"
                            fullWidth
                            className="space"
                            onClick={this.onWalletPay}
                          >
                            Pay
                          </Button>
                        )}
                      </Fragment>
                    ) : (
                      <Button
                        margin="normal"
                        variant="contained"
                        color="primary"
                        fullWidth
                        className="space red"
                        style={{ textTransform: "none" }}
                        disabled={true}
                      >
                        You have insufficient wallet balance to make payment.
                        Please top-up your Wallet by transferring funds to
                        Kapital Boost
                      </Button>
                    )}
                  </Fragment>
                ) : (
                  <Button
                    margin="normal"
                    variant="contained"
                    color="primary"
                    fullWidth
                    className="space red"
                    style={{ textTransform: "none" }}
                    disabled={true}
                  >
                    You have insufficient wallet balance to make payment. Please
                    top-up your Wallet by transferring funds to Kapital Boost
                  </Button>
                )}
                {/* {!wallet_amount && "asdad"} */}
                {/* {wallet_amount && (
                  <Fragment>
                    {wallet_amount >= parseFloat(invest.amount) ? (
                      <Fragment>
                        {wallet_pay_loading ? (
                          <Button
                            margin="normal"
                            variant="contained"
                            color="primary"
                            fullWidth
                            className="space"
                          >
                            <CircularProgress color="default" size={25} />
                          </Button>
                        ) : (
                          <Button
                            margin="normal"
                            variant="contained"
                            color="primary"
                            fullWidth
                            className="space"
                            onClick={this.onWalletPay}
                          >
                            Pay
                          </Button>
                        )}
                      </Fragment>
                    ) : (
                      <Button
                        margin="normal"
                        variant="contained"
                        color="primary"
                        fullWidth
                        className="space red"
                        style={{ textTransform: "none" }}
                        disabled={true}
                      >
                        You have insufficient wallet balance to make payment.
                        Please top-up your Wallet by transferring funds to
                        Kapital Boost
                      </Button>
                    )}
                  </Fragment>
                )} */}
              </DialogContent>
            </Dialog>
            {/* start Dialog Paypal */}
            <Dialog
              open={this.state.open_invest_confirm}
              onClose={this.onCloseInvestConfirm}
            >
              <DialogContent className="dialog-title">
                <Typography variant="h5">Donation Confirmation</Typography>
                <Button
                  onClick={this.onCloseInvestConfirm}
                  variant="outlined"
                  disableElevation
                >
                  <Close />
                </Button>
              </DialogContent>
              <DialogContent dividers>
                <Typography>
                  By clicking <strong>Next</strong>, you have confirmed to
                  donate
                  <strong> S$ {hp.format_money(invest.amount)}</strong> in the{" "}
                  donation campaign
                  {/* {!is_donation &&
                    " and have read and understood the Risk Statement related to making such investments"} */}
                  .
                </Typography>
              </DialogContent>
              <DialogActions>
                {is_investing ? (
                  <Button
                    variant="contained"
                    color="secondary"
                    className="bold"
                    autoFocus
                  >
                    <CircularProgress size={20} color="inherit" />
                  </Button>
                ) : (
                  <Fragment>
                    <Button onClick={this.onCloseInvestConfirm} color="default">
                      Cancel
                    </Button>
                    <Button
                      onClick={this.onPaypalConfirm}
                      variant="contained"
                      color="secondary"
                      className="bold"
                      autoFocus
                    >
                      Next
                    </Button>
                    {/* {this.renderPaypalForm()} */}
                  </Fragment>
                )}
              </DialogActions>
            </Dialog>
            {/* end Dialog Paypal */}
            {this.renderPaypalForm()}
          </Container>
        ) : (
          <div className="loading">
            <CircularProgress />
          </div>
        )}
      </div>
    );
  }
  renderPaypalForm() {
    const { invest, campaign, user, invest_amount } = this.state;
    return (
      <form
        action="https://www.paypal.com/cgi-bin/webscr"
        // action="https://www.sandbox.paypal.com/cgi-bin/webscr"
        method="POST"
        id="form"
        ref={(e) => (this.paypal_form = e)}
      >
        {/* <input type="hidden" name="business" value="kbbusiness@gmail.com" /> */}
        {/* <input type="hidden" name="business" value="reza@seller.com" /> */}
        <input type="hidden" name="business" value="erly@kapitalboost.com" />
        <input type="hidden" name="cmd" value="_xclick" />
        <input type="hidden" name="item_name" value={`${campaign.name}`} />
        <input type="hidden" name="item_number" value="1" />
        <input type="hidden" name="credits" value="510" />
        <input type="hidden" name="tx" value="TransactionID" />
        <input
          type="hidden"
          name="at"
          value="AaoP1MVoKJtxIYB_POhalYMFHuw8AUd3CNKp8UKd7-vWV2NX_wYdItzUbHA6zjvHs0wKTUjOE4Towtp5" // production Pak Erly
          // value="ABLhIs9LIHYd1oIB4G1a6tXqquRmAIJfwChjngbDUf3BnCQ8.L-iSkDq" // sandbox kbbusiness@gmail.com
          // value="Aa_dymI1O41EP9hxajsxeVk_16IxuSj6YIGpVktuidosNTPzh7CnBb9wAKJ3roYrobeLc31ksb4lrVX5" // sandbox reza@kapitalboost.co.id

          // Sandbox Paypal Pak Erly
          // value="ODJuzlFwX-WNtbrnJhd_EB33SXbFXYt7xkWb8AGPGQp-eqi5l0wKkTr8it0"
        />
        <input type="hidden" name="userid" value="1" />
        <input
          type="hidden"
          name="cpp_header_image"
          value="https://kapitalboost.com/assets/images/logo.png"
        />
        <input type="hidden" name="no_shipping" value="1" />
        <input type="hidden" name="currency_code" value="SGD" />
        <input type="hidden" name="handling" value="0" />
        <input type="hidden" name="amount" value={invest_amount} />
        <input type="hidden" name="custom" value="" />
        <input
          type="hidden"
          name="cancel_return"
          value="https://kapitalboost.com/cancel.php"
        />
        <input
          type="hidden"
          name="return"
          value="https://kapitalboost.com/payment"
        />

        <input type="hidden" name="order_id" value="123" />
        <input type="hidden" name="item_name_1" value={`${campaign.name}`} />
        <input type="hidden" name="item_item_id_1" value="12345" />
        <input
          type="hidden"
          name="item_description_1"
          value="Kapitalboost campaign"
        />
        <input type="hidden" name="item_quantity_1" value="1" />
        <input type="hidden" name="currency" value="SGD" />
        <input type="hidden" name="refundable" value="false" />
        <input type="hidden" name="username" value={user.firstname} />
        <input type="hidden" name="lastname" value={user.lastname} />
        <input type="hidden" name="xfersphone" value={user.phone_no} />
        <input type="hidden" name="email" value={user.email} />
      </form>
    );
  }
}

export default withRouter(Page);
