export const FAQs = {
  sme: {
    sme: [
      {
        q: "What type of funding is offered by Kapital Boost?",
        a: `<p>Kapital Boost offers 2 types of funding namely Asset Purchase and Invoice Financing, based on the structures of Murabaha (cost plus profit) and Wakalah bil Ujrah with Qard (paid agency and loan), respectively.</p>
        <p>The Asset Purchase arrangement helps SMEs to raise funds for goods and capital purchases. Invoice Financing offers more flexibility on the use of funds as it offers cash advance to SMEs based on existing receivables.</p>
        <p>Both are short-term funding, with maximum payment tenor of 12 months.</p>
        <p>For an illustration of the Asset Purchase and Invoice Financing structures, view our infographics on the How it Works page.</p>`,
        c: false
      },
      {
        q: "What are the eligibility requirements to get funding?",
        a: `<p>To be eligible for funding from Kapital Boost, a business will need to meet the following requirements:</p>
        <ul>
        <li>At least one year of operations as a registered company</li>
        <li>At least SGD100,000 of sales revenue over the past financial year</li>
        <li>Positive free cash flow in the past year</li>
        <li>Existing purchase/work order from customers (for PO financing)</li>
        <li>Unpaid invoice from long-established customers* or state-linked entities (for Invoice Financing)</li>
        </ul>
        <p>For more detailed information on the requirements, please email us at <a href="mailto:support@kapitalboost.com">support@kapitalboost.com</a> or WhatsApp us at +65 8433 1760.</p>
        <p>*A creditworthy customer would reflect favorably when we evaluate your funding request.</p>`,
        c: false
      },
      {
        q: "How much funding can I raise through Kapital Boost?",
        a: `<p>A business can raise up to SGD150,000 through purchase order financing and up to 85% of the invoice value through invoice financing.</p>
        <p>You may raise financing based on multiple invoices at any one time.</p>`,
        c: false
      },
      {
        q: "How can I submit my funding application to Kapital Boost?",
        a: `<p>Complete and submit the Get Funded form to provide us with information on your business and funding needs. A Kapital Boost representative will then follow-up with you for further information.</p>
        <p>Alternatively, you may email us at <a href="mailto:support@kapitalboost.com">support@kapitalboost.com</a> or WhatsApp us at +65 8433 1760.</p>`,
        c: false
      },
      {
        q: "How quickly can I get a Kapital Boost funding?",
        a: `<p>Once your application (with complete documents) is submitted, we will get back to you within 5 working days. If everything is in order, your crowdfunding campaign is launched and funds can be raised within 7 days.</p>`,
        c: false
      },
      {
        q: "Is this type of funding secured?",
        a: `<p>No. Both the Asset Purchase and Invoice Financing structures are considered as unsecured financing.</p>`,
        c: true
      },
      {
        q: "Do I need to provide any security or collateral for the financing?",
        a: `<p>At the minimum, we require the Directors and/or Shareholders of the business to sign a personal guarantee on the financing via Kapital Boost. We may request for other types of securities depending on the risk profile of the business.</p>`,
        c: false
      },
      {
        q: "What if my customer defaults on payment?",
        a: `<p>Your business is contractually obligated to make payments owed to financiers, regardless of the payment from your customers.</p>`,
        c: false
      },
      {
        q: "What differentiates Kapital Boost to other fund providers?",
        a: `<p>Compared with most non-bank money lenders, we provide lower funding costs. Versus banks, we offer a non-secured financing and simpler approval process. Additionally, Kapital Boost's funding adheres to Shariah principles, which are ethical. The use of an asset purchase/cost-plus profit structure versus a loan increases the sharing of risk and reward between businesses and financiers.</p>`,
        c: false
      },
      {
        q: "How much does Kapital Boost charge for crowdfunding?",
        a: `<p>A successful campaign will be charged an administrative fee of 5% of the total funding amount. The returns to investors will be determined by Kapital Boost, and will depend on the type of financing, repayment period and risk profile of the business.</p>`,
        c: false
      },
      {
        q: "Is there a penalty for early repayment?",
        a: `<p>None. Kapital Boost encourages an accelerated payout by businesses.</p>`,
        c: true
      },
      {
        q: "Does Kapital Boost offer funding to SMEs outside of Singapore?",
        a: `<p>Yes we do. We offer funding to businesses registered in Singapore and Indonesia. However at this moment, Invoice Financing is only offered in Indonesia.</p>`,
        c: false
      },
      {
        q: "Is there any penalty for late payment?",
        a: `<p>Kapital Boost imposes a late payment charge (LPC) on the SME. We employ 2 different calculations for LPC. It may either be based on annualised returns that is pro-rated according to number of days late, or a daily LPC rate. In line with Shariah guidelines, we collect late payment charges from the SME and donate them to charity.</p>`,
        c: true
      },
      {
        q: "Is there a penalty for cancelling a campaign?",
        a: `<p>We will charge the borrower a flat fee of SGD300 / IDR3,000,000 for cancelling a funding campaign that is already live on the platform. If the campaign is cancelled before it is live, no penalty is imposed on the borrower.</p>`,
        c: true
      },
    ],
    member: [
      {
        q: "Who can invest in the crowdfunding campaigns?",
        a: `<p>The crowdfunding campaign is open to all regardless of geographical location. Before investing, you should carefully consider whether investing in SMEs is appropriate for you in light of your risk profile, financial objectives and resources, experience as well as other relevant circumstances.</p>`,
        c: false
      },
      {
        q: "Are there any fees charged to investors?",
        a: `<p>There are no fees charged to invest via Kapital Boost.</p>`,
        c: true
      },
      {
        q: "Why are investments via Kapital Boost ethical?",
        a: `<p>At Kapital Boost, our investment opportunities are guided by moral and ethical values. For instance, we do not support activities involved in gambling, weapons or those causing environmental harm.</p>
        <p>Additionally, our financing arrangement ensures that the funding is for productive rather than consumptive purposes. The former creates far more positive impact, in terms of production and growth in real economic activities that is beneficial for the society.</p>`,
        c: true
      },
      {
        q: "What do risk ratings on the SME campaigns indicate?",
        a: `<p>The risk ratings from A to C reflect the indicative risk profile of SMEs based on our proprietary credit scoring system. The ratings do not assess or indicate the likelihood of loss or default on your investment.</p>
        <p>All investments carry a degree of risk, including the risk of principal loss. We recommend you discuss your capacity to invest via Kapital Boost with your financial advisor. We also strongly advise investors to diversify their investments across different funding campaigns and asset classes to lower potential investment losses.</p>
        <b>INVOICE FINANCING</b>
        <table>
          <tr>
            <th>Risk score</th>
            <th>Annualised return</th>
            <th>Risk profile</th>
          </tr>
          <tr><td>A</td><td>12%</td><td>Low</td></tr>
          <tr><td>A-</td><td>13%</td><td>Low</td></tr>
          <tr><td>B+</td><td>14-15%</td><td>Low to moderate</td></tr>
          <tr><td>B</td><td>16-17%</td><td>Moderate</td></tr>
          <tr><td>B-</td><td>18%</td><td>Moderate/High</td></tr>
          <tr><td>C+</td><td>20%</td><td>High/Moderate</td></tr>
          <tr><td>C</td><td>22%</td><td>High</td></tr>
          <tr><td>C-</td><td>REJECTED</td><td>Very High</td></tr>
        </table>
        <b>MURABAHA FINANCING</b>
        <table>
          <tr>
            <th>Risk score</th>
            <th>Annualised return</th>
            <th>Risk profile</th>
          </tr>
          <tr><td>A</td><td>14-15%</td><td>Low</td></tr>
          <tr><td>A-</td><td>16-17%</td><td>Low</td></tr>
          <tr><td>B+</td><td>18-19%</td><td>Low to moderate</td></tr>
          <tr><td>B</td><td>19-21%</td><td>Moderate</td></tr>
          <tr><td>B-</td><td>22-23%</td><td>Moderate/High</td></tr>
          <tr><td>C+</td><td>24-25%</td><td>High/Moderate</td></tr>
          <tr><td>C</td><td>26%</td><td>High</td></tr>
          <tr><td>C-</td><td>REJECTED</td><td>Very High</td></tr>
        </table>`,
        c: false
      },
      {
        q: "How can I reduce investment risks?",
        a: `<p>All investments, including investing in SMEs, involves substantial risk. Such investments may not be suitable for everyone and can result in possible loss of principal amount invested.</p>
        <p>We highlight the importance of diversification by spreading your principal invested across different funding campaigns to reduce the risk of loss on your potential returns. This helps protect your investment from the effects of any one SME missing a payment or defaulting.</p>`,
        c: true
      },
      {
        q: "What steps are taken to reduce the funding risk to financiers?",
        a: `<p>Apart from our due diligence process, we require at least one of the following credit risk mitigants from SMEs.</p>
        <ul>
          <li>Personal guarantee by one or more of the directors</li>
          <li>A joint bank account between the SME and Kapital Boost to control the use of funds and the prioritisation of customer payments for payouts to Investors.</li>
          <li>Requirement of a post-dated Bilyet Giro (giro payment) by the SME to Kapital Boost</li>
          <li>The direct transfer of funds from Investors to asset suppliers (for Asset Purchase financing)</li>
          <li>A security/collateral with estimated value at least 150% of the requested funding amount</li>
        </ul>`,
        c: false
      },
      {
        q: "How does Kapital Boost screen business for funding eligibility?",
        a: `<p>Businesses must meet the following requirements:</p>
        <ul>
          <li>At least one year of operation</li>
          <li>At least SGD100,000 of sales in the past year</li>
          <li>Positive free cash flow in the past year</li>
        </ul>
        <p>Additionally, we have a credit risk scoring system based on the company's business profile, financial position, and corporate governance. On a risk scoring scale of 1 (lowest) to 10 (highest), we only allow businesses with a risk scale of below 4 to raise a crowdfunding campaign through Kapital Boost</p>`,
        c: false
      },
      {
        q: "Can you provide more information on the Kapital Boost scoring system?",
        a: `<p>The three core areas we focus on are the company's business profile, financial position, and corporate governance.</p>
        <ol>
          <li>
            Business risk:
            <ul>
              <li>Industry profile (e.g. industry competitive landscape, profitability, life cycle/growth potential, economic sensitivity, and regulatory risk)</li>
              <li>Company profile (e.g. size in terms of annual sales, historical profit, operating track record, differentiation)</li>
              <li>Management (e.g. experience, risk profile, risk mitigation)</li>
            </ul>
          </li>
          <li>
            Financial risk:
            <ul>
              <li>Cash flow (operating cash flow/interest, historical free cash flow)</li>
              <li>Operating performance (net income margin, sales/net profit growth)</li>
              <li>Capital structure (Total debt/EBITDA, total debt/assets)</li>
              <li>Liquidity (current ratio)</li>
            </ul>
          </li>
          <li>
            Corporate governance risk:
            <ul>
              <li>Credit score of largest shareholder</li>
              <li>Clarity of company strategy</li>
              <li>Availability of a business plan</li>
              <li>Availability of historical financial statements audited or not</li>
            </ul>
          </li>
        </ol>`,
        c: false
      },
      {
        q: "Should investors solely rely on Kapital Boost due diligence?",
        a: `<p>No. While we screen for the most attractive investment opportunities before launching them on our platform, we are not responsible for formal due diligence on the funding investments. The need and onus to do due diligence lies squarely with the financiers as we do not profess to advise on the same. All dealings and transactions are directly with businesses and financiers.</p>`,
        c: false
      },
      {
        q: "How are the agreements between parties finalised?",
        a: `<p>All contracts/agreements will be sent via digital signing app Eversign to the investor’s email address that is registered with Kapital Boost. Signing is done electronically.</p>
        <p>Digital signature is binding and acceptable in countries which we operate, namely Singapore and Indonesia.</p>`,
        c: false
      },
      {
        q: "What happens in a default?",
        a: `<p>In the event of a default, Kapital Boost will immediately take the following steps:</p>
        <ul>
        <li>Engage with the business to find out the reason for the missed payment. We will attempt to find a solution which will satisfy the financiers and the business</li>
        <li>If after eight weeks there is a failure to come to an agreement between the financiers and the business and there is continued default, Kapital Boost will send a legal demand letter to the SME requesting for immediate payment.</li>
        <li>If payment default continues after 12 weeks, Investors may take legal actions against the SME. Kapital Boost will not be involved in the legal matters between the financier and the business. However, we may act as a broker between both parties</li>
        </ul>`,
        c: true
      },
      {
        q: "Is investment via Kapital Boost Shariah compliant?",
        a: `<p>Kapital Boost's Murabaha (asset purchase) financing structure is certified Shariah compliant by the Financial Shariah Advisory & Consultancy (FSAC), a consultancy unit of Pergas Investment Holdings in Singapore. Our Invoice financing structure is based on a fatwa issued by the Majelis Ulama Indonesia (MUI), an authoritative body of Islamic scholars in Indonesia.</p>`
      }
    ]
  }, 
  donation: [
    {
      q: "What kind of social projects do you fund via donation crowdfunding?",
      a: `<p>Any project which supports less-privileged communities in Southeast Asia. This may include, but not limited to, the building/renovation of schools or mosques and funding for the development of infrastructures in lower-income communities.</p>`,
      c: false
    },
    {
      q: "How can I set up a donation-based crowdfunding campaign?",
      a: `<p>Please email <a href="mailto:hello@kapitalboost.com">hello@kapitalboost.com</a> and provide us with details of the social project that is in need of funding. Our team will review the project and determine the funding eligibility. It will be up to Kapital Boost's discretion on which projects will be featured.</p>`,
      c: false
    },
    {
      q: "Does Kapital Boost charge any fees for donation crowdfunding?",
      a: `<p>No, Kapital Boost does not charge any admin fees for our donation crowdfunding campaigns. We are committed to ensuring that the full amount raised goes towards the intended cause. However, please note that some of our partners may charge an admin fee to cover their operational needs in ensuring the funds are disbursed appropriately.</p>`,
      c: false
    },
    {
      q: "Can I donate to the project even when the target funding is full?",
      a: `<p>We encourage our members to donate generously. Therefore they can contribute more than the target funding indicated, provided that the campaign is still open.</p>`,
      c: false
    },
    {
      q: "Do I need to register as a member to donate?",
      a: `<p>Yes, only members are allowed to participate in our campaigns, be it for donation or investing in SMEs.</p>`,
      c: false
    },
    {
      q: "Will I be updated on the project I donate to?",
      a: `<p>Yes, donors will be given periodic updates, via email, on how far the project has progressed. Donors can be assured that the money will be spent according to the intended use as described in the campaign.</p>`,
      c: false
    },
  ]
}