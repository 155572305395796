import React, {Component, Fragment} from 'react'
import {Link} from 'react-router-dom'

import {Container, AppBar, CircularProgress, Paper, ExpansionPanel, LinearProgress, ExpansionPanelSummary, ExpansionPanelDetails, Tabs, Tab, InputAdornment, FormControlLabel, Checkbox, Toolbar, MenuItem, FormControl, Typography, Button, InputLabel, Hidden, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Grid, Select} from '@material-ui/core'
import {AccountCircle, Close} from '@material-ui/icons'
import api from 'services/api'
import hp from 'services/hp'

class Page extends Component {

   state = {
      is_verified: 'null',
   }

   componentDidMount() {
      window.scrollTo(0,0)
      this.onStart()
   }

   onStart = async() => {
      const {match} = this.props
      if(hp.is_login()) {
         this.props.history.push('/')
      }
      setTimeout( async() => {
         const res = await api.post('verify', {code: match.params.code})
         const {data} = res
         if(data.error) {
            this.setState({ is_verified: 'no' })
         }else {
            this.setState({ is_verified: 'yes' })
         }
      }, 2000)
   }

   render() {
      const {is_verified} = this.state
      return (
         <Container id="verify">

            <Paper id="paper" className="paddingBig" elevation={3} >
               {is_verified == 'null' ?
               <Fragment>
                  <Typography className="space">Please wait while we verify your account</Typography>
                  <LinearProgress className="space" />
               </Fragment>
               : is_verified == 'no' ?
               <Fragment>
                  <Typography className="red spaceBottom" >Verify Unsuccessful</Typography>
                  <a href="mailto:support@kapitalboost.com" ><Typography>Email Us</Typography></a>
               </Fragment>
               :
               <Fragment>
                  <Typography className="blue spaceBottom">Verification Successful</Typography>
                  <Button variant="contained" color="primary" fullWidth onClick={this.props.onLoginDialog} >Please Login</Button>
               </Fragment>
            }

         </Paper>

      </Container>
   )
}
}

export default Page
