import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";

import {
  Container,
  AppBar,
  Tabs,
  Tab,
  Paper,
  InputAdornment,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  Toolbar,
  MenuItem,
  FormControl,
  Typography,
  Button,
  InputLabel,
  Hidden,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Grid,
  Select,
} from "@material-ui/core";
import { AccountCircle, Close } from "@material-ui/icons";
import api from "services/api";
import { format } from "date-fns";

class Page extends Component {
  state = {
    updates: null,
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.onStart();
  }

  onStart = async () => {
    const updates_res = await api.get("campaign-update");
    const updates = updates_res.data;
    this.setState({ updates });
  };

  render() {
    const { updates } = this.state;
    return (
      <div id="campaign-updates">
        {updates ? (
          <Container maxWidth="xl">
            {updates.length > 0 ? (
              <Fragment>
                {updates.map((update, i) => (
                  <Link key={i} to={`/dashboard/campaign-update/${update.id}`}>
                    <Paper className="spaceBottom padding" elevation={3}>
                      <Grid
                        container
                        spacing={1}
                        alignItems="center"
                        justify="center"
                        direction="row"
                      >
                        {/* <Grid item xs={1}>
                                    <div className="padding-small">
                                       <img src={update.campaign.cover_image} width="70%" />
                                    </div>
                                 </Grid> */}
                        <Grid item xs={10}>
                          <Typography component="h3" className="bold blue">
                            {update.campaign.acronim}
                          </Typography>
                          <Typography component="h4">{update.title}</Typography>
                          <Typography className="dim">
                            {update.content}
                          </Typography>
                        </Grid>
                        <Grid item xs={2} style={{ textAlign: "center" }}>
                          <Typography>
                            {format(
                              new Date(update.created_at.replace(/ /g, "T")),
                              "do LLL yyyy"
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Link>
                ))}
              </Fragment>
            ) : (
              <div className="space center">
                <Typography>No campaign update yet</Typography>
              </div>
            )}
          </Container>
        ) : (
          <div className="loading">
            <CircularProgress />
          </div>
        )}
      </div>
    );
  }
}

export default Page;
