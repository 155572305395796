import React from "react";
import {
  makeStyles,
  Typography,
  Box,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import { DoneAllRounded, CheckBoxOutlined } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      borderRadius: 0,
      flexDirection: "column",
      padding: "12px 0 24px 0",
      height: "auto",
    },
    padding: "0 41px",
    borderRadius: 15,
    backgroundColor: "#023E8A",
    display: "flex",
    alignItems: "center",
    width: "max-content",
    height: 95,
  },
  title: {
    [theme.breakpoints.down("xs")]: {
      fontSize: 14,
      marginLeft: 0,
      marginRight: 0,
      width: 186,
      height: 39,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: 5,
    },
    fontSize: 23,
    fontWeight: "bold",
    color: "#fff",
    lineHeight: 2,
    marginLeft: 30,
    marginRight: 30,
  },
  wrapper: {
    display: "flex",
    marginLeft: 10,
    marginRight: 10,
  },
  labelWrapper: {
    display: "flex",
    alignItems: "center",
    paddingLeft: 10,
    paddingRight: 10,
  },
  item: {
    [theme.breakpoints.down("xs")]: {
      fontSize: 12,
    },
    fontSize: 18,
    color: "#F2F2F2",
  },
  icon: {
    [theme.breakpoints.down("xs")]: {
      marginRight: 5,
    },
    color: "#f3a830",
    marginRight: 10,
  },
}));

export default function Achievement() {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <div className={classes.root}>
      <Typography
        classes={{
          root: classes.title,
        }}
      >
        1st Islamic SME P2P platform in Asia
      </Typography>
      <div className={classes.wrapper}>
        <div className={classes.labelWrapper}>
          <CheckBoxOutlined
            fontSize="large"
            classes={{
              root: classes.icon,
            }}
          />
          <Typography
            classes={{
              root: classes.item,
            }}
          >
            Ethical
          </Typography>
        </div>

        <div className={classes.labelWrapper}>
          <CheckBoxOutlined
            fontSize="large"
            classes={{
              root: classes.icon,
            }}
          />
          <Typography
            classes={{
              root: classes.item,
            }}
          >
            Transparent
          </Typography>
        </div>

        <div className={classes.labelWrapper}>
          <CheckBoxOutlined
            fontSize="large"
            classes={{
              root: classes.icon,
            }}
          />
          <Typography
            classes={{
              root: classes.item,
            }}
          >
            100% Online
          </Typography>
        </div>
      </div>
    </div>
  );
}
