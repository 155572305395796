import React, {Component} from 'react'
import {Link} from 'react-router-dom'

import {Container, AppBar, CircularProgress, Divider, Paper, ExpansionPanel, Chip, ExpansionPanelSummary, ExpansionPanelDetails, Tabs, Tab, InputAdornment, FormControlLabel, Checkbox, Toolbar, MenuItem, FormControl, Typography, Button, InputLabel, Hidden, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Grid, Select} from '@material-ui/core'
import {AccountCircle, Close} from '@material-ui/icons'
import api from 'services/api'

class Page extends Component {

   constructor(props) {
      super(props);
      this.state = {
         slug: '',
         blog: null
      }
   }

   componentDidMount() {
      window.scrollTo(0,0)
      let {pathname} = this.props.location
      let slug = pathname.split('/')[2]
      this.setState({ slug }, () => {
         this.onStart()
         this.countVisitor()
      })
   }

   onStart = async() => {
      const {slug} = this.state
      const blog_res = await api.get(`blog/${slug}`)
      const blog = blog_res.data
      this.setState({ blog })
   }

   countVisitor = async () => {
      const {slug} = this.state
      const summary_res = await api.get(`blog-viewer/${slug}`)
      const blog = summary_res.data
      console.log(blog);
   }

   render() {
      const {blog} = this.state
      return (
         <div id="blog">
            <Container maxWidth="md" >
               {blog ?
                  <div id="blog-content">
                     <Typography variant="h4" component="h1" className="center space" >{blog.title}</Typography>
                     <Typography className="center spaceBottom" >{blog.date}</Typography>
                     <img src={blog.image} width="100%" />

                     <Typography className="spaceBottom" dangerouslySetInnerHTML={{ __html: blog.content }} />

                  <Divider className="spaceBig"/>
                     <Typography className="space">Tags</Typography>
                     {blog.tags && blog.tags.map( (tag, i) => (
                        <Chip key={i} label={tag} color="primary" component={Link} className="tags" to={`/blog?search=${tag}`} />
                     ))}

                  </div>
                  :
                  <div className="loading"><CircularProgress /></div>
               }
            </Container>
         </div>
      )
   }
}

export default Page
