import React, { Component, Fragment } from "react";
import { Link, withRouter } from "react-router-dom";

import {
  Container,
  List,
  ListItem,
  LinearProgress,
  ListItemText,
  Divider,
  CircularProgress,
  Paper,
  MenuItem,
  FormControl,
  Typography,
  Button,
  InputLabel,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Grid,
  Select,
} from "@material-ui/core";
import { Close, LocationOn, Business, PlayArrow } from "@material-ui/icons";
import { withGoogleReCaptcha } from "react-google-recaptcha-v3";
import api from "services/api";
import hp from "services/hp";
import Lightbox from "react-image-lightbox";
import Campaign from "components/Campaign";
import ReactImageVideoLightbox from "react-image-video-lightbox";

class Page extends Component {
  constructor(props) {
    super(props);
    this.state = {
      campaign: null,
      slug: props.match.params.slug,
      count: 1,
      page: 1,
      open_gallery: false,
      gallery: [],
      gallery_index: 0,
      left_unit: "Days",
      day_left: 0,
      open_enqurie: false,
      open_enquire_thank: false,
      enquire: "",
      payment: "",
      invest_amount: 0,
      payment_error: false,
      invest_amount_error: false,
      open_invest: false,
      open_invest_confirm: false,
      is_investing: false,
      enquire_loading: false,
      is_less_then_minimum: false,
      campaign_password: "",
      campaign_request_loading: false,
      user: null,
      open_not_investable: false,
      countdown: "",
      invest_amount_error_type: "",
      is_donation: false,
      is_prelaunch: null,
      investmentId: 0,
      matches: window.matchMedia("(min-width: 768px)").matches, //768px
      vendor: null,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.onStart();
    const handler = (e) => this.setState({ matches: e.matches });
    window.matchMedia("(min-width: 768px)").addListener(handler);
  }

  onStart = async () => {
    // console.log('dump', this.props);
    const { slug, campaign_password } = this.state;
    const campaign_res = await api.get(
      `campaign/${slug}?campaign_password=${campaign_password}`
    );
    const params = new URLSearchParams(this.props.location.search);
    if(params.get('a')) {
      let res = await api.get(`partner/${params.get('a')}`);
      if(res.data && res.data.status)  {
        this.setState({vendor: res.data.data});
      }
    }
    const campaign = campaign_res.data;
    // console.log(campaign);
    this.setState({ is_prelaunch: campaign.is_prelaunch });
    let [day_left, left_unit] = hp.get_day_left(campaign);
    if (campaign.type == "donation") {
      this.setState({ is_donation: true });
      if (day_left != 0) {
        campaign.is_open = true;
      }
    }

    let is_less_then_minimum = hp.is_less_then_minimum(campaign);
    const user = await hp.get_user();
    this.setState({
      campaign: campaign,
      day_left,
      left_unit,
      is_less_then_minimum,
      user,
    });
    if (campaign_password != "" && !campaign.is_password_open) {
      alert("Incorrect campaign password");
      this.setState({ campaign_password: "" });
    }
    if (campaign.is_prelaunch) {
      // console.log(new Date(campaign.release_time), campaign.release_time);
      let releaseTime = campaign.release_time;
      this.timer = setInterval(() => {
        // let _release_datetime = campaign.release_datetime.replace(/ /g, "T")
        // let distance = formatDistanceStrict(new Date(_release_datetime), new Date(), { unit: 'second' })
        // let countdown = this.toHHMMSS(distance)
        if (releaseTime > 0) {
          let countdown = this.toHHMMSS(--releaseTime);
          this.setState({ countdown });
        } else {
          this.setState({ is_prelaunch: false });
        }
      }, 1000);
    }
  };
  toHHMMSS = (second) => {
    var sec_num = parseInt(second, 10);
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - hours * 3600) / 60);
    var seconds = sec_num - hours * 3600 - minutes * 60;

    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (seconds < 10) {
      seconds = "0" + seconds;
    }
    return hours + ":" + minutes + ":" + seconds;
  };
  componentWillUnmount() {
    clearInterval(this.timer);
    // window.removeEventListener("resize", this.checkWidth);
  }

  onRequestCloseCampaign = async (e) => {
    e.preventDefault();
    this.setState({ campaign_request_loading: true });
    await this.onStart();
    this.setState({ campaign_request_loading: false });
  };

  onOpenInvest = async () => {
    const { campaign, is_less_then_minimum, is_donation } = this.state;
    if (is_less_then_minimum) {
      if (campaign.type == "donation") {
        this.setState({ invest_amount: 0 });
      } else {
        this.setState({ invest_amount: hp.get_maximum_investment(campaign) });
      }
    }
    let banks = hp.get_banks();
    let user = await hp.get_user();
    if (user.status == "approved" || is_donation) {
      this.setState({ open_invest: true });
    } else {
      this.setState({ open_not_investable: true });
    }
  };
  onCloseInvest = () => {
    this.setState({ open_invest: false });
  };
  onInvest = async (e) => {
    e.preventDefault();
    const { payment, invest_amount, campaign, is_less_then_minimum } =
      this.state;
    // console.log(campaign);
    // console.log("Invest amount = " + invest_amount);
    // console.log("Total Invest Amount = " + campaign.total_invest_amount);
    // console.log("Invesment Count = " + campaign.investments_count);
    this.setState({
      payment_error: false,
      invest_amount_error: false,
      invest_amount_error_type: "",
    });
    if (payment == "") {
      this.setState({ payment_error: true });
      return;
    }
    if (invest_amount == 0 || invest_amount == "") {
      this.setState({
        invest_amount_error: true,
        invest_amount_error_type: "zero",
      });
      return;
    }
    // if (invest_amount < campaign.total_invest_amount) {
    //   this.setState({
    //     invest_amount_error: true,
    //     invest_amount_error_type: "minimum",
    //   });
    //   // todo If user type twice after get error message
    //   this.setState({
    //     invest_amount_error: false,
    //   });
    //   return;
    // }

    // todo if invest amount is negatif
    if (invest_amount < 0) {
      // invest_amount % 10 != 0
      this.setState({
        invest_amount_error: true,
        invest_amount_error_type: "negatif",
      });
      // todo If user type twice after get error message
      // this.setState({
      //   invest_amount_error: false,
      // });
      return;
    }
    // todo If inn denominations
    if (invest_amount % 10 != 0) {
      // invest_amount % 10 != 0
      if (campaign.type == "donation") {
        // console.log("This campaign is donation");
        this.setState({
          invest_amount_error: false,
          invest_amount_error_type: "",
        });
        // todo If user type twice after get error message
        // this.setState({
        //   invest_amount_error: false,
        // });
        // return;
      } else {
        this.setState({
          invest_amount_error: true,
          invest_amount_error_type: "ten",
        });
        // todo If user type twice after get error message
        // this.setState({
        //   invest_amount_error: false,
        // });
        return;
      }
    }
    // todo If more than 30%
    if (
      invest_amount > campaign.total_invest_amount * 0.3 &&
      campaign.type != "donation" //* Campaign donation don't have maximum invest
    ) {
      this.setState({
        invest_amount_error: true,
        invest_amount_error_type: "thirty",
      });
      return;
    }
    //todo If more than max
    if (
      invest_amount >
        campaign.total_invest_amount - campaign.investments_count &&
      campaign.type != "donation" //* Campaign donation don't have maximum invest
    ) {
      // if (invest_amount > campaign.total_invest_amount) {
      //   this.setState({
      //     invest_amount_error: true,
      //     invest_amount_error_type: "maximum",
      //   });
      //   return;
      // }
      this.setState({
        invest_amount_error: true,
        invest_amount_error_type: "maximum",
      });
      return;
    }
    // todo If less  than min
    if (
      // !is_less_then_minimum &&
      invest_amount < campaign.minimum_invest_amount
    ) {
      this.setState({
        invest_amount_error: true,
        invest_amount_error_type: "minimum",
      });
      return;
    }
    // else {
    //   if (
    //     !is_less_then_minimum &&
    //     invest_amount < campaign.minimum_invest_amount
    //   ) {
    //     this.setState({
    //       invest_amount_error: true,
    //       invest_amount_error_type: "minimum",
    //     });
    //     return;
    //   }
    // }
    this.setState({ open_invest: false, open_invest_confirm: true });
  };

  onInvestConfirm = async (e) => {
    const { payment, invest_amount, campaign, investmentId } = this.state;
    this.setState({ is_investing: true });
    const res = await api.post("invest", {
      payment,
      invest_amount,
      slug: campaign.slug,
      vendor_id: this.state.vendor ? this.state.vendor.id : null,
      from_device: 'Website'
    });
    const invest = res.data;
    if (invest.error || !invest.amount) {
      this.setState({ is_investing: false });
      this.onStart();
      alert(invest.message);
      return;
    }
    // console.log(investmentId);
    this.setState({ payment: "", investmentId: invest.id }); // invest_amount: 0
    // this.props.history.push(`/dashboard/portfolio/${invest.id}`);
    if (campaign.type == "donation" && payment == "paypal") {
      this.paypal_form.submit();
      // if (this.paypal_form.submit()) {
      //   const res = await api.post(`/invest_is_paid/${invest.id}`);
      // } else {
      //   alert("Something wrong!");
      // }
      // return;
    } else {
      this.props.history.push(`/dashboard/portfolio/${invest.id}`);
    }
  };
  onCloseInvestConfirm = () => {
    this.setState({ open_invest_confirm: false });
  };

  onOpenEnquire = () => {
    this.setState({ open_enqurie: true });
  };
  onCloseEnquireDialog = () => {
    this.setState({ open_enqurie: false });
  };
  onCloseEnquireThankDialog = () => {
    this.setState({ open_enquire_thank: false });
  };
  onEnquire = async (e) => {
    e.preventDefault();
    const { enquire, campaign } = this.state;
    this.setState({ enquire_loading: true });
    const recaptcha_token =
      await this.props.googleReCaptchaProps.executeRecaptcha("enquire");
    const res = await api.post("enquire", {
      enquire,
      slug: campaign.slug,
      recaptcha_token,
    });
    this.setState({ open_enqurie: false, enquire: "", enquire_loading: false });
    const { data } = res;
    if (data.success) {
      this.setState({ open_enquire_thank: true });
    } else {
      alert("Error Submitting Enquire");
    }
  };
  onChange = (e, f) => {
    let { state } = this;
    state[f] = e.target.value;
    this.setState({ state });
  };

  onOpenGallery = (i) => {
    this.setState({ open_gallery: true, gallery_index: i });
  };

  render() {
    const {
      campaign,
      is_donation,
      open_gallery,
      user,
      gallery,
      gallery_index,
      countdown,
      invest_amount_error_type,
      enquire_loading,
      day_left,
      left_unit,
      campaign_request_loading,
      campaign_password,
      open_enqurie,
      open_enquire_thank,
      open_invest,
      payment_error,
      invest_amount,
      invest_amount_error,
      payment,
      is_investing,
      is_less_then_minimum,
      is_prelaunch,
    } = this.state;

    return (
      <div id="campaign">
        {campaign ? (
          <div id="campaign-content">
            <div
              id="banner"
              className="spaceBottom"
              style={{ backgroundImage: `url(${campaign.cover_image})` }}
            >
              <div id="overlay" />
              {/* start Big screen: min width: 768px */}
              {this.state.matches && (
                <>
                  <Paper elevation={6} id="banner-title">
                    <Typography
                      variant="h5"
                      component="h1"
                      className="bold spaceBig"
                    >
                      {campaign.acronim}
                    </Typography>
                    <Typography className="spaceBottom">
                      {campaign.classification}
                    </Typography>
                    <Grid
                      container
                      className="spaceBottom"
                      justify="center"
                      alignItems="center"
                    >
                      <Grid
                        item
                        xs={12}
                        md={6}
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          fontSize: 11,
                        }}
                      >
                        <LocationOn style={{ marginRight: 5 }} />{" "}
                        <Typography style={{ fontSize: 11 }}>
                          {campaign.country}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                      >
                        <Business style={{ marginRight: 5 }} />{" "}
                        <Typography style={{ fontSize: 11 }}>
                          {campaign.industry}
                        </Typography>
                      </Grid>
                    </Grid>
                    {!campaign.is_open ? (
                      <Button variant="contained" size="large" color="default">
                        Closed
                      </Button>
                    ) : (
                      <Fragment>
                        {is_prelaunch ? (
                          <Button
                            variant="contained"
                            size="large"
                            color="primary"
                          >
                            Campaign launches in <br />
                            {countdown}
                          </Button>
                        ) : (
                          <Button
                            variant="contained"
                            size="large"
                            color="primary"
                            className={is_donation && "yellowBg"}
                            onClick={this.onOpenInvest}
                          >
                            {is_donation ? "Donate Now" : "Invest Now"}
                          </Button>
                        )}

                        <Dialog
                          open={open_invest}
                          onClose={this.onCloseInvest}
                          maxWidth="md"
                          fullWidth
                        >
                          <DialogContent className="dialog-title">
                            <Typography variant="h5">
                              {is_donation ? "Donation" : "SME Investment"}
                            </Typography>
                            <Button
                              onClick={this.onCloseInvest}
                              variant="outlined"
                              disableElevation
                            >
                              <Close />
                            </Button>
                          </DialogContent>
                          <DialogContent dividers>
                            <Typography style={{paddingBottom: 10}}>
                              Thank you for your interest in the{" "}
                              <strong>{campaign.acronim}</strong> campaign.
                            </Typography>
                            <Typography>
                              Please indicate the amount you would like to{" "}
                              {is_donation ? "donate" : "invest"}.
                            </Typography>
                            <form
                              onSubmit={this.onInvest}
                              className="spaceBigX"
                            >
                              <table id="invest-table">
                                <thead></thead>
                                <tbody>
                                  <tr>
                                    <td>
                                      <Typography>Payment</Typography>
                                    </td>
                                    <td>
                                      <Typography>Minimum</Typography>
                                    </td>
                                    {is_donation ? (
                                      ""
                                    ) : (
                                      <td>
                                        <Typography>Maximum</Typography>
                                      </td>
                                    )}
                                    {/* <td>
                                      <Typography>
                                        {is_donation ? "" : "Maximum"}
                                      </Typography>
                                    </td> */}
                                    <td>
                                      <Typography>
                                        {is_donation
                                          ? "Donation"
                                          : "Investment"}{" "}
                                        amount
                                      </Typography>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <FormControl
                                        margin="normal"
                                        fullWidth
                                        required
                                        error={payment_error}
                                        variant="outlined"
                                      >
                                        <InputLabel>Select Payment</InputLabel>
                                        <Select
                                          value={payment}
                                          onChange={(e) =>
                                            this.onChange(e, "payment")
                                          }
                                          required
                                        >
                                          <MenuItem value="bank-transfer">
                                            Bank Transfer {user.country !== 'SINGAPORE' ? '' : '/ PayNow'}
                                          </MenuItem>
                                          {is_donation && user.country !== 'SINGAPORE' && (
                                            <MenuItem value="paypal">
                                              Paypal
                                            </MenuItem>
                                          )}
                                        </Select>
                                      </FormControl>
                                    </td>
                                    {campaign.type == "donation" ? (
                                      <td>
                                        <Typography>
                                          SGD{" "}
                                          {hp.format_money(
                                            campaign.minimum_invest_amount
                                          )}
                                        </Typography>
                                      </td>
                                    ) : is_less_then_minimum ? (
                                      <td>
                                        <Typography>
                                          SGD{" "}
                                          {hp.format_money(
                                            campaign.total_invest_amount -
                                              parseInt(
                                                campaign.investments_count
                                              )
                                          )}
                                        </Typography>
                                      </td>
                                    ) : (
                                      <td>
                                        <Typography>
                                          SGD{" "}
                                          {hp.format_money(
                                            campaign.minimum_invest_amount
                                          )}
                                        </Typography>
                                      </td>
                                    )}
                                    {/* {is_less_then_minimum ? (
                                  <td>
                                    <Typography>
                                      SGD{" "}
                                      {hp.format_money(
                                        campaign.total_invest_amount -
                                          parseInt(campaign.investments_count)
                                      )}
                                    </Typography>
                                  </td>
                                ) : (
                                  <td>
                                    <Typography>
                                      SGD{" "}
                                      {hp.format_money(
                                        campaign.minimum_invest_amount
                                      )}
                                    </Typography>
                                  </td>
                                )} */}
                                    {/* //todo donation do not need this */}
                                    {campaign.type == "donation" ? (
                                      // <td>
                                      //   <Typography>
                                      //     SGD{" "}
                                      //     {hp.format_money(
                                      //       campaign.total_invest_amount * 0.3
                                      //     )}
                                      //   </Typography>
                                      // </td>
                                      ""
                                    ) : parseInt(campaign.investments_count) >
                                      campaign.total_invest_amount * 0.3 ? (
                                      <td>
                                        <Typography>
                                          SGD{" "}
                                          {hp.format_money(
                                            campaign.total_invest_amount -
                                              parseInt(
                                                campaign.investments_count
                                              )
                                          )}
                                        </Typography>
                                      </td>
                                    ) : (
                                      <td>
                                        <Typography>
                                          SGD{" "}
                                          {hp.format_money(
                                            campaign.total_invest_amount * 0.3
                                          )}
                                        </Typography>
                                      </td>
                                    )}
                                    {/* //todo donation do not need that */}
                                    {/* {parseInt(campaign.investments_count) >
                                campaign.total_invest_amount * 0.3 ? (
                                  <td>
                                    <Typography>
                                      SGD{" "}
                                      {hp.format_money(
                                        campaign.total_invest_amount -
                                          parseInt(campaign.investments_count)
                                      )}
                                    </Typography>
                                  </td>
                                ) : (
                                  <td>
                                    <Typography>
                                      SGD{" "}
                                      {hp.format_money(
                                        campaign.total_invest_amount * 0.3
                                      )}
                                    </Typography>
                                  </td>
                                )} */}

                                    {/* <td><Typography>SGD { campaign.investments_count ? hp.format_money(campaign.total_invest_amount - parseInt(campaign.investments_count)) : hp.format_money(campaign.total_invest_amount) }</Typography></td> */}

                                    <td>
                                      <TextField
                                        margin="normal"
                                        label="SGD"
                                        type="number"
                                        fullWidth
                                        required
                                        variant="outlined"
                                        error={invest_amount_error}
                                        value={invest_amount}
                                        onChange={(e) =>
                                          this.onChange(e, "invest_amount")
                                        }
                                        // InputProps={{
                                        //   readOnly: is_less_then_minimum,
                                        // }}
                                        style={{ width: 300 }}
                                        helperText={
                                          invest_amount_error_type == "minimum"
                                            ? `Minimum investment amount is ${campaign.minimum_invest_amount}`
                                            : invest_amount_error_type ==
                                              "maximum"
                                            ? `Maximum investment amount is ${hp.format_money(
                                                hp.get_maximum_investment(
                                                  campaign
                                                )
                                              )}`
                                            : invest_amount_error_type == "zero"
                                            ? "Please enter investment amount"
                                            : invest_amount_error_type ==
                                              "negatif"
                                            ? "The amount must be in positif" // "The amount must be in denominations of $1"
                                            : invest_amount_error_type == "ten"
                                            ? "The amount must be in denominations of $10"
                                            : invest_amount_error_type ==
                                              "thirty"
                                            ? "The amount has exceeded the 30% limit per investor."
                                            : ""
                                        }
                                      />
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <Button
                                margin="normal"
                                variant="contained"
                                color="primary"
                                fullWidth
                                type="submit"
                                className="space"
                              >
                                Submit
                              </Button>
                            </form>
                          </DialogContent>
                        </Dialog>

                        <Dialog
                          open={this.state.open_invest_confirm}
                          onClose={this.onCloseInvestConfirm}
                        >
                          <DialogContent className="dialog-title">
                            <Typography variant="h5">
                              {is_donation ? "Donate" : "Invest"} Confirmation
                            </Typography>
                            <Button
                              onClick={this.onCloseInvestConfirm}
                              variant="outlined"
                              disableElevation
                            >
                              <Close />
                            </Button>
                          </DialogContent>
                          <DialogContent dividers>
                          {
                          is_donation ? 
                            <Typography>
                              By clicking <strong>Next</strong>, you confirm your donation of <strong>S$ {hp.format_money(invest_amount)}</strong> in the <strong>{campaign.acronim}</strong> campaign.
                            </Typography> : 
                            <Typography>
                              By clicking <strong>Next</strong>, you confirm your investment of 
                              <strong> S$ {hp.format_money(invest_amount)}</strong> in the <strong>{campaign.acronim}</strong> campaign. You also acknowledge that you have read and understood the <a href="/legal#risk" target={`_blank`}>Risk Statement</a> associated with such investment.
                            </Typography>
                          }
                            

                            {/* <Typography>
                              By clicking <strong>Next</strong>, you have
                              confirmed to {is_donation ? "donate" : "invest"}{" "}
                              <strong>
                                S$ {hp.format_money(invest_amount)}
                              </strong>{" "}
                              in the {is_donation ? "donation" : "crowdfunding"}{" "}
                              campaign.{" "}
                              {payment == "paypal"
                                ? "You have chosen Paypal as a payment option."
                                : ""}
                              {!is_donation &&
                                " and have read and understood the Risk Statement related to making such investments."}
                            </Typography> */}
                            {/* <Typography>By clicking <strong>Next</strong>, you have confirmed to {is_donation ? 'donate' :'invest' } <strong>S$ {hp.format_money(invest_amount)}</strong> in the {is_donation ? 'donation' : 'crowdfunding' } campaign{!is_donation && ' and have read and understood the '+ <a target="_blank" href="/legal#risk" >Risk Statement</a> + ' related to making such investments' }.</Typography> */}
                          </DialogContent>
                          <DialogActions>
                            {is_investing ? (
                              <Button
                                variant="contained"
                                color="secondary"
                                className="bold"
                                autoFocus
                              >
                                <CircularProgress size={20} color="inherit" />
                              </Button>
                            ) : (
                              <Fragment>
                                <Button
                                  onClick={this.onCloseInvestConfirm}
                                  color="default"
                                >
                                  Cancel
                                </Button>
                                <Button
                                  onClick={this.onInvestConfirm}
                                  variant="contained"
                                  color="secondary"
                                  className="bold"
                                  autoFocus
                                >
                                  Next
                                </Button>
                              </Fragment>
                            )}
                          </DialogActions>
                        </Dialog>
                      </Fragment>
                    )}
                  </Paper>
                </>
              )}
              {/* end Big screen: min width: 768px */}
              {/* start Small screen with min width < 768px */}
              {!this.state.matches && (
                <>
                  {/* start Box information */}
                  <Paper
                    elevation={6}
                    id="banner-title"
                    style={{ width: "50%", left: "20%" }}
                  >
                    <Typography
                      variant="h5"
                      component="h1"
                      className="bold spaceBig"
                    >
                      {campaign.acronim}
                    </Typography>
                    <Typography className="spaceBottom">
                      {campaign.classification}
                    </Typography>
                    <Grid
                      container
                      className="spaceBottom"
                      justify="center"
                      alignItems="center"
                    >
                      <Grid
                        item
                        xs={12}
                        md={6}
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          fontSize: 11,
                        }}
                      >
                        <LocationOn style={{ marginRight: 5 }} />{" "}
                        <Typography style={{ fontSize: 11 }}>
                          {campaign.country}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                      >
                        <Business style={{ marginRight: 5 }} />{" "}
                        <Typography style={{ fontSize: 11 }}>
                          {campaign.industry}
                        </Typography>
                      </Grid>
                    </Grid>
                    {!campaign.is_open ? (
                      <Button
                        variant="contained"
                        size="large"
                        color="default"
                        style={{ marginLeft: "35px" }}
                      >
                        Closed
                      </Button>
                    ) : (
                      <Fragment>
                        {is_prelaunch ? (
                          <Button
                            variant="contained"
                            size="large"
                            color="primary"
                          >
                            Campaign launches in <br />
                            {countdown}
                          </Button>
                        ) : (
                          <Button
                            variant="contained"
                            size="large"
                            color="primary"
                            className={is_donation && "yellowBg"}
                            onClick={this.onOpenInvest}
                            style={{ marginLeft: "27px" }}
                          >
                            {is_donation ? "Donate Now" : "Invest Now"}
                          </Button>
                        )}

                        <Dialog
                          open={open_invest}
                          onClose={this.onCloseInvest}
                          maxWidth="md"
                          fullWidth
                        >
                          <DialogContent className="dialog-title">
                            <Typography variant="h5">
                              {is_donation ? "Donation" : "SME Investment"}
                            </Typography>
                            <Button
                              onClick={this.onCloseInvest}
                              variant="outlined"
                              disableElevation
                            >
                              <Close />
                            </Button>
                          </DialogContent>
                          <DialogContent dividers>
                            <Typography style={{paddingBottom: 10}}>
                              Thank you for your interest in the{" "}
                              <strong>{campaign.acronim}</strong> campaign.
                            </Typography>
                            <Typography>
                              Please indicate the amount you would like to{" "}
                              {is_donation ? "donate" : "invest"}.
                            </Typography>
                            <form
                              onSubmit={this.onInvest}
                              className="spaceBigX"
                            >
                              {/* Start Container */}
                              <Container maxWidth="lg" className="spaceBigX">
                                <Grid
                                  container
                                  spacing={1}
                                  style={{ marginTop: "-30px" }}
                                >
                                  <Grid item xs={12}>
                                    <Typography>Payment</Typography>
                                    <FormControl
                                      margin="normal"
                                      fullWidth
                                      required
                                      error={payment_error}
                                      variant="outlined"
                                    >
                                      <InputLabel>Select Payment</InputLabel>
                                      <Select
                                        value={payment}
                                        onChange={(e) =>
                                          this.onChange(e, "payment")
                                        }
                                        required
                                      >
                                        <MenuItem value="bank-transfer">
                                          Bank Transfer {user.country !== 'SINGAPORE' ? '' : '/ PayNow'}
                                        </MenuItem>
                                        {is_donation && user.country !== 'SINGAPORE' && (
                                          <MenuItem value="paypal">
                                            Paypal
                                          </MenuItem>
                                        )}
                                      </Select>
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={6}>
                                    <Typography>Minimum</Typography>
                                    {campaign.type == "donation" ? (
                                      <Typography>
                                        SGD{" "}
                                        {hp.format_money(
                                          campaign.minimum_invest_amount
                                        )}
                                      </Typography>
                                    ) : is_less_then_minimum ? (
                                      <Typography>
                                        SGD{" "}
                                        {hp.format_money(
                                          campaign.total_invest_amount -
                                            parseInt(campaign.investments_count)
                                        )}
                                      </Typography>
                                    ) : (
                                      <Typography>
                                        SGD{" "}
                                        {hp.format_money(
                                          campaign.minimum_invest_amount
                                        )}
                                      </Typography>
                                    )}
                                  </Grid>
                                  <Grid item xs={6}>
                                    <Typography>
                                      {campaign.type == "donation"
                                        ? ""
                                        : "Maximum"}
                                    </Typography>
                                    {campaign.type == "donation" ? (
                                      // <Typography>
                                      //   SGD{" "}
                                      //   {hp.format_money(
                                      //     campaign.total_invest_amount * 0.3
                                      //   )}
                                      // </Typography>
                                      ""
                                    ) : parseInt(campaign.investments_count) >
                                      campaign.total_invest_amount * 0.3 ? (
                                      <Typography>
                                        SGD{" "}
                                        {hp.format_money(
                                          campaign.total_invest_amount -
                                            parseInt(campaign.investments_count)
                                        )}
                                      </Typography>
                                    ) : (
                                      <Typography>
                                        SGD{" "}
                                        {hp.format_money(
                                          campaign.total_invest_amount * 0.3
                                        )}
                                      </Typography>
                                    )}
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Typography>
                                      {is_donation ? "Donation" : "Investment"}{" "}
                                      amount
                                    </Typography>
                                    <TextField
                                      margin="normal"
                                      label="SGD"
                                      type="number"
                                      fullWidth
                                      required
                                      variant="outlined"
                                      error={invest_amount_error}
                                      value={invest_amount}
                                      onChange={(e) =>
                                        this.onChange(e, "invest_amount")
                                      }
                                      // InputProps={{
                                      //   readOnly: is_less_then_minimum,
                                      // }}
                                      // style={{ width: 300 }}
                                      helperText={
                                        invest_amount_error_type == "minimum"
                                          ? `Minimum investment amount is ${campaign.minimum_invest_amount}`
                                          : invest_amount_error_type ==
                                            "maximum"
                                          ? `Maximum investment amount is ${hp.format_money(
                                              hp.get_maximum_investment(
                                                campaign
                                              )
                                            )}`
                                          : invest_amount_error_type == "zero"
                                          ? "Please enter investment amount"
                                          : invest_amount_error_type ==
                                            "negatif"
                                          ? "The amount must be in positif" // "The amount must be in denominations of $1"
                                          : invest_amount_error_type == "ten"
                                          ? "The amount must be in denominations of $10"
                                          : invest_amount_error_type == "thirty"
                                          ? "The amount has exceeded the 30% limit per investor."
                                          : ""
                                      }
                                    />
                                  </Grid>
                                </Grid>
                              </Container>
                              {/* End Container */}
                              <Button
                                margin="normal"
                                variant="contained"
                                color="primary"
                                fullWidth
                                type="submit"
                                // className="space"
                                style={{ marginTop: "-30px" }}
                              >
                                Submit
                              </Button>
                            </form>
                          </DialogContent>
                        </Dialog>

                        <Dialog
                          open={this.state.open_invest_confirm}
                          onClose={this.onCloseInvestConfirm}
                        >
                          <DialogContent className="dialog-title">
                            <Typography variant="h5">
                              {is_donation ? "Donate" : "Invest"} Confirmation
                            </Typography>
                            <Button
                              onClick={this.onCloseInvestConfirm}
                              variant="outlined"
                              disableElevation
                            >
                              <Close />
                            </Button>
                          </DialogContent>
                          <DialogContent dividers>
                            <Typography>
                              By clicking <strong>Next</strong>, you have
                              confirmed to {is_donation ? "donate" : "invest"}{" "}
                              <strong>
                                S$ {hp.format_money(invest_amount)}
                              </strong>{" "}
                              in the {is_donation ? "donation" : "crowdfunding"}{" "}
                              campaign.{" "}
                              {payment == "paypal"
                                ? "You have chosen Paypal as a payment option."
                                : ""}
                              {!is_donation &&
                                " and have read and understood the Risk Statement related to making such investments."}
                            </Typography>
                            {/* <Typography>By clicking <strong>Next</strong>, you have confirmed to {is_donation ? 'donate' :'invest' } <strong>S$ {hp.format_money(invest_amount)}</strong> in the {is_donation ? 'donation' : 'crowdfunding' } campaign{!is_donation && ' and have read and understood the '+ <a target="_blank" href="/legal#risk" >Risk Statement</a> + ' related to making such investments' }.</Typography> */}
                          </DialogContent>
                          <DialogActions>
                            {is_investing ? (
                              <Button
                                variant="contained"
                                color="secondary"
                                className="bold"
                                autoFocus
                              >
                                <CircularProgress size={20} color="inherit" />
                              </Button>
                            ) : (
                              <Fragment>
                                <Button
                                  onClick={this.onCloseInvestConfirm}
                                  color="default"
                                >
                                  Cancel
                                </Button>
                                <Button
                                  onClick={this.onInvestConfirm}
                                  variant="contained"
                                  color="secondary"
                                  className="bold"
                                  autoFocus
                                >
                                  Next
                                </Button>
                              </Fragment>
                            )}
                          </DialogActions>
                        </Dialog>
                      </Fragment>
                    )}
                  </Paper>
                  {/* end Box information */}
                </>
              )}
              {/* end Small screen with min width < 768px */}
            </div>

            <Container maxWidth="lg" className="spaceBigX">
              <Grid container spacing={5}>
                {/* start Min-width: 768px is big screen */}
                {this.state.matches && (
                  <>
                    {/* start description */}
                    <Grid item md={8} xs={6}>
                      {campaign.is_password_open || campaign.is_open ? (
                        <Typography
                          className="spaceBottom"
                          id="description"
                          dangerouslySetInnerHTML={{
                            __html: campaign.description,
                          }}
                        />
                      ) : (
                        <div>
                          <Typography>
                            This campaign is closed for investment. If you are
                            an investor and want to access the campaign writeup,
                            please request for the password from <br />
                            <a href="mailto:hello@kapitalboost.com">
                              hello@kapitalboost.com
                            </a>
                          </Typography>
                          <form onSubmit={this.onRequestCloseCampaign}>
                            <TextField
                              margin="normal"
                              label="Campaign Password"
                              type="password"
                              fullWidth
                              required
                              value={campaign_password}
                              onChange={(e) =>
                                this.onChange(e, "campaign_password")
                              }
                            />
                            {campaign_request_loading ? (
                              <Button
                                margin="normal"
                                variant="contained"
                                color="primary"
                                fullWidth
                                className="space"
                              >
                                <CircularProgress color="default" size={25} />
                              </Button>
                            ) : (
                              <Button
                                margin="normal"
                                variant="contained"
                                color="primary"
                                fullWidth
                                type="submit"
                                className="space"
                              >
                                Submit
                              </Button>
                            )}
                          </form>
                        </div>
                      )}
                    </Grid>
                    {/* end description */}
                    {/* start overview */}
                    <Grid item md={4} xs={6}>
                      <Paper
                        elevation={6}
                        id="overview"
                        className="spaceBottom"
                      >
                        <Typography
                          variant="h5"
                          component="h2"
                          className="lightblue center bold spaceBottom"
                        >
                          Overview
                        </Typography>

                        <div style={{ padding: "0 16px" }}>
                          {is_prelaunch ? (
                            <LinearProgress
                              className="progress spaceBottom"
                              variant="determinate"
                              value={0}
                            />
                          ) : campaign.investments_count ? (
                            <LinearProgress
                              className={
                                campaign.type == "donation"
                                  ? "progress spaceBottom yellowBg"
                                  : campaign.subtype == "INVOICE  FINANCING"
                                  ? "progress spaceBottom darkblueBg"
                                  : "progress spaceBottom"
                              }
                              variant="determinate"
                              value={
                                Math.min((parseInt(campaign.investments_count) /
                                  campaign.total_invest_amount) *
                                100, 100)
                              }
                            />
                          ) : (
                            <LinearProgress
                              className="progress spaceBottom"
                              variant="determinate"
                              value={0}
                            />
                          )}
                          {/* {campaign.investments_count ? (
                        <LinearProgress
                          className={
                            campaign.type == "donation"
                              ? "progress spaceBottom yellowBg"
                              : "progress spaceBottom"
                          }
                          variant="determinate"
                          value={
                            (parseInt(campaign.investments_count) /
                              campaign.total_invest_amount) *
                            100
                          }
                        />
                      ) : (
                        <LinearProgress
                          className="progress space"
                          variant="determinate"
                          value={0}
                        />
                      )} */}
                        </div>

                        <List component="nav" aria-label="main mailbox folders">
                          {/* Target Funding */}
                          <ListItem>
                            <Grid container>
                              <Grid item xs={12} md={8}>
                                <ListItemText primary="Target funding" />
                              </Grid>
                              <Grid item xs md={4}>
                                <Typography
                                  className="bold"
                                  style={{ float: "right" }}
                                >
                                  S${" "}
                                  {hp.format_money(
                                    campaign.total_invest_amount
                                  )}
                                </Typography>
                              </Grid>
                            </Grid>
                          </ListItem>
                          <Divider />
                          {/* Target Funding */}
                          {/* Funded */}
                          <ListItem>
                            <Grid container>
                              <Grid item xs={12} md={8}>
                                <ListItemText primary="Funded" />
                              </Grid>
                              <Grid item xs md={4}>
                                {is_prelaunch ? (
                                  <Typography
                                    style={{ float: "right" }}
                                    className="bold"
                                  >
                                    S$ 0
                                  </Typography>
                                ) : campaign.investments_count ? (
                                  <Typography
                                    style={{ float: "right" }}
                                    className="bold"
                                  >
                                    S${" "}
                                    {hp.format_money(
                                      campaign.investments_count
                                    )}
                                  </Typography>
                                ) : (
                                  <Typography
                                    style={{ float: "right" }}
                                    className="bold"
                                  >
                                    S$ 0
                                  </Typography>
                                )}
                              </Grid>
                            </Grid>
                          </ListItem>
                          <Divider />
                          {/* Funded */}
                          {/* Min Investment */}
                          <ListItem>
                            <Grid container>
                              <Grid item xs={12} md={8}>
                                <ListItemText primary="Min investment" />
                              </Grid>
                              <Grid item xs md={4}>
                                <Typography
                                  className="bold"
                                  style={{ float: "right" }}
                                >
                                  S${" "}
                                  {hp.format_money(
                                    campaign.minimum_invest_amount
                                  )}
                                </Typography>
                              </Grid>
                            </Grid>
                          </ListItem>
                          <Divider />
                          {/* Min Investment */}
                          {/* Returns */}
                          <ListItem>
                            <Grid container>
                              <Grid item xs={12} md={8}>
                                <ListItemText primary="Returns" />
                              </Grid>
                              <Grid item xs md={4}>
                                <Typography
                                  className="bold"
                                  style={{ float: "right" }}
                                >
                                  {campaign.return}%
                                </Typography>
                              </Grid>
                            </Grid>
                          </ListItem>
                          <Divider />
                          {/* Returns */}
                          {/* Days Left */}
                          <ListItem>
                            <Grid container>
                              <Grid item xs={12} md={8}>
                                <ListItemText primary={`${left_unit} left`} />
                              </Grid>
                              <Grid item xs md={4}>
                                <Typography
                                  className="bold"
                                  style={{ float: "right" }}
                                >
                                  {day_left}
                                </Typography>
                              </Grid>
                            </Grid>
                          </ListItem>
                          <Divider />
                          {/* Days Left */}
                          {/* Tenor */}
                          <ListItem>
                            <Grid container>
                              <Grid item xs={12} md={8}>
                                <ListItemText primary="Tenor" />
                              </Grid>
                              <Grid item xs md={4}>
                                <Typography
                                  className="bold"
                                  style={{ float: "right" }}
                                >
                                  {campaign.tenor}
                                </Typography>
                              </Grid>
                            </Grid>
                          </ListItem>
                          {/* Tenor */}
                        </List>
                      </Paper>
                      <div className="center spaceBottom spaceBigX ">
                        {!campaign.is_open ? (
                          <Button
                            variant="contained"
                            size="large"
                            color="default"
                          >
                            Closed
                          </Button>
                        ) : (
                          <Fragment>
                            <div className="spaceBottom">
                              {is_prelaunch ? (
                                <Button
                                  variant="contained"
                                  size="large"
                                  color="primary"
                                >
                                  Campaign launches in <br />
                                  {countdown}
                                </Button>
                              ) : (
                                <Button
                                  variant="contained"
                                  size="large"
                                  className={is_donation && "yellowBg"}
                                  color="primary"
                                  onClick={this.onOpenInvest}
                                >
                                  {is_donation ? "Donate Now" : "Invest Now"}
                                </Button>
                              )}
                            </div>
                            <div className="spaceBottomX">
                              <Button
                                variant="contained"
                                size="large"
                                color="primary"
                                onClick={this.onOpenEnquire}
                              >
                                Enquire
                              </Button>
                            </div>
                            <Dialog
                              open={open_enqurie}
                              onClose={this.onCloseEnquireDialog}
                            >
                              <DialogContent className="dialog-title">
                                <Typography variant="h5">Enquire</Typography>
                                <Button
                                  onClick={this.onCloseEnquireDialog}
                                  variant="outlined"
                                  disableElevation
                                >
                                  <Close />
                                </Button>
                              </DialogContent>
                              <DialogContent dividers>
                                <form onSubmit={this.onEnquire}>
                                  <Typography>
                                    Campaign:{" "}
                                    <Typography
                                      component="span"
                                      className="bold"
                                    >
                                      {campaign.name}
                                    </Typography>
                                  </Typography>
                                  <TextField
                                    multiline
                                    rows="6"
                                    rowsMax="10"
                                    variant="outlined"
                                    margin="normal"
                                    label="Enquiry"
                                    type="text"
                                    helperText="Type your enquiry and click Submit. We will respond within 1 working day."
                                    fullWidth
                                    required
                                    onChange={(e) =>
                                      this.onChange(e, "enquire")
                                    }
                                  />
                                  {enquire_loading ? (
                                    <Button
                                      margin="normal"
                                      variant="contained"
                                      color="primary"
                                      fullWidth
                                      className="space"
                                    >
                                      <CircularProgress
                                        color="default"
                                        size={25}
                                      />
                                    </Button>
                                  ) : (
                                    <Button
                                      margin="normal"
                                      variant="contained"
                                      color="primary"
                                      fullWidth
                                      type="submit"
                                      className="space"
                                    >
                                      Submit
                                    </Button>
                                  )}
                                </form>
                              </DialogContent>
                            </Dialog>
                            <Dialog
                              open={open_enquire_thank}
                              onClose={this.onCloseEnquireThankDialog}
                            >
                              <DialogContent className="dialog-title">
                                <Typography variant="h5" className="bold">
                                  Thank you for your inquiry!
                                </Typography>
                                <Button
                                  onClick={this.onCloseEnquireThankDialog}
                                  variant="outlined"
                                  disableElevation
                                >
                                  <Close />
                                </Button>
                              </DialogContent>
                              <DialogContent dividers>
                                <Typography>
                                  Our team is looking into it and will reply to
                                  you shortly.
                                </Typography>
                                <Typography>
                                  If your enquiry is urgent, you may call us at{" "}
                                  <a href="tel:+6598659648">+65 9865 9648</a>{" "}
                                  from
                                </Typography>
                                <Typography>
                                  Monday to Friday during our office hours.
                                </Typography>
                              </DialogContent>
                            </Dialog>
                          </Fragment>
                        )}
                      </div>

                      {campaign.campaign_images.length > 0 &&
                        (campaign.is_password_open || campaign.is_open) && (
                          <div id="gallery">
                            <Typography
                              variant="h5"
                              component="h2"
                              className="lightblue center bold spaceBig"
                            >
                              Gallery
                            </Typography>

                            <Grid container alignItems="center" spacing={2}>
                              
                              {campaign.campaign_images.map((value, i) => {
                                gallery[i] = {
                                  url: value.link, 
                                  type: (!value.type || value.type === 'image') ? 'photo' : value.type,
                                  name: value.name,
                                };
                                return (
                                  <Grid
                                    key={i}
                                    item
                                    xs={4}
                                    onClick={() => this.onOpenGallery(i)}
                                  >
                                    {
                                      (!value.type || value.type.toLowerCase() === 'image') && 
                                      <img src={value.link} width="100%" />
                                    }
                                    {
                                      value.type && value.type.toLowerCase() === 'video' &&
                                      <div class="shadow-box">
                                        <PlayArrow fontSize='large' color="primary"/>
                                      </div>
                                    }
                                  </Grid>
                                )
                              })}
                            </Grid>

                            {open_gallery && this.state.gallery && (
                              <div class='gallery-wrapper'>
                                <ReactImageVideoLightbox
                                data={this.state.gallery}
                                startIndex={gallery_index}
                                showResourceCount={true}
                                onCloseCallback={() => this.setState({open_gallery: false})}
                                onNavigationCallback={(gallery_index) =>
                                  this.setState({gallery_index})
                                }
                              />
                              <Typography
                                variant="h5"
                                component="h2"
                                className="center bold spaceBig gallery-caption"
                              >
                                {gallery[gallery_index].name}
                              </Typography>
                            </div>
                            )}
                          </div>
                        )}

                      {campaign.campaign_pdfs.length > 0 &&
                        (campaign.is_password_open || campaign.is_open) && (
                          <div id="pdf">
                            <Typography
                              variant="h5"
                              component="h2"
                              className="lightblue center bold spaceBig"
                            >
                              Documents
                            </Typography>

                            <ul>
                              {campaign.campaign_pdfs.map((pdf, i) => (
                                <li key={i}>
                                  <a target="_blank" href={pdf.link}>
                                    <Typography>{pdf.name}</Typography>
                                  </a>
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                    </Grid>
                    {/* end overview */}
                  </>
                )}
                {/* end Min-width: 768px is big screen */}

                {/* start small screen: width < 768px */}
                {!this.state.matches && (
                  <>
                    {/* start overview */}
                    <Grid item md={4} xs={12}>
                      <Paper
                        elevation={6}
                        id="overview"
                        className="spaceBottom"
                      >
                        <Typography
                          variant="h5"
                          component="h2"
                          className="lightblue center bold spaceBottom"
                        >
                          Overview
                        </Typography>

                        <div style={{ padding: "0 16px" }}>
                          {is_prelaunch ? (
                            <LinearProgress
                              className="progress spaceBottom"
                              variant="determinate"
                              value={0}
                            />
                          ) : campaign.investments_count ? (
                            <LinearProgress
                              className={
                                campaign.type == "donation"
                                  ? "progress spaceBottom yellowBg"
                                  : campaign.subtype == "INVOICE  FINANCING"
                                  ? "progress spaceBottom darkblueBg"
                                  : "progress spaceBottom"
                              }
                              variant="determinate"
                              value={
                                Math.min((parseInt(campaign.investments_count) /
                                  campaign.total_invest_amount) *
                                100, 100)
                              }
                            />
                          ) : (
                            <LinearProgress
                              className="progress spaceBottom"
                              variant="determinate"
                              value={0}
                            />
                          )}
                          {/* {campaign.investments_count ? (
                        <LinearProgress
                          className={
                            campaign.type == "donation"
                              ? "progress spaceBottom yellowBg"
                              : "progress spaceBottom"
                          }
                          variant="determinate"
                          value={
                            (parseInt(campaign.investments_count) /
                              campaign.total_invest_amount) *
                            100
                          }
                        />
                      ) : (
                        <LinearProgress
                          className="progress space"
                          variant="determinate"
                          value={0}
                        />
                      )} */}
                        </div>

                        <List component="nav" aria-label="main mailbox folders">
                          {/* Target Funding */}
                          <ListItem>
                            <Grid container>
                              <Grid item xs={6}>
                                <ListItemText primary="Target funding" />
                              </Grid>
                              <Grid item xs={6}>
                                <Typography
                                  className="bold"
                                  style={{ float: "right" }}
                                >
                                  S${" "}
                                  {hp.format_money(
                                    campaign.total_invest_amount
                                  )}
                                </Typography>
                              </Grid>
                            </Grid>
                          </ListItem>
                          <Divider />
                          {/* Target Funding */}
                          {/* Funded */}
                          <ListItem>
                            <Grid container>
                              <Grid item xs={6}>
                                <ListItemText primary="Funded" />
                              </Grid>
                              <Grid item xs={6}>
                                {is_prelaunch ? (
                                  <Typography
                                    style={{ float: "right" }}
                                    className="bold"
                                  >
                                    S$ 0
                                  </Typography>
                                ) : campaign.investments_count ? (
                                  <Typography
                                    style={{ float: "right" }}
                                    className="bold"
                                  >
                                    S${" "}
                                    {hp.format_money(
                                      campaign.investments_count
                                    )}
                                  </Typography>
                                ) : (
                                  <Typography
                                    style={{ float: "right" }}
                                    className="bold"
                                  >
                                    S$ 0
                                  </Typography>
                                )}
                              </Grid>
                            </Grid>
                          </ListItem>
                          <Divider />
                          {/* Funded */}
                          {/* Min Investment */}
                          <ListItem>
                            <Grid container>
                              <Grid item xs={6}>
                                <ListItemText primary="Min investment" />
                              </Grid>
                              <Grid item xs={6}>
                                <Typography
                                  className="bold"
                                  style={{ float: "right" }}
                                >
                                  S${" "}
                                  {hp.format_money(
                                    campaign.minimum_invest_amount
                                  )}
                                </Typography>
                              </Grid>
                            </Grid>
                          </ListItem>
                          <Divider />
                          {/* Min Investment */}
                          {/* Returns */}
                          <ListItem>
                            <Grid container>
                              <Grid item xs={6}>
                                <ListItemText primary="Returns" />
                              </Grid>
                              <Grid item xs={6}>
                                <Typography
                                  className="bold"
                                  style={{ float: "right" }}
                                >
                                  {campaign.return}%
                                </Typography>
                              </Grid>
                            </Grid>
                          </ListItem>
                          <Divider />
                          {/* Returns */}
                          {/* Days Left */}
                          <ListItem>
                            <Grid container>
                              <Grid item xs={6}>
                                <ListItemText primary={`${left_unit} left`} />
                              </Grid>
                              <Grid item xs={6}>
                                <Typography
                                  className="bold"
                                  style={{ float: "right" }}
                                >
                                  {day_left}
                                </Typography>
                              </Grid>
                            </Grid>
                          </ListItem>
                          <Divider />
                          {/* Days Left */}
                          {/* Tenor */}
                          <ListItem>
                            <Grid container>
                              <Grid item xs={6}>
                                <ListItemText primary="Tenor" />
                              </Grid>
                              <Grid item xs={6}>
                                <Typography
                                  className="bold"
                                  style={{ float: "right" }}
                                >
                                  {campaign.tenor}
                                </Typography>
                              </Grid>
                            </Grid>
                          </ListItem>
                          {/* Tenor */}
                        </List>
                      </Paper>
                      <div className="center spaceBottom spaceBigX ">
                        {!campaign.is_open ? (
                          <Button
                            variant="contained"
                            size="large"
                            color="default"
                          >
                            Closed
                          </Button>
                        ) : (
                          <Fragment>
                            <div className="spaceBottom">
                              {is_prelaunch ? (
                                <Button
                                  variant="contained"
                                  size="large"
                                  color="primary"
                                >
                                  Campaign launches in <br />
                                  {countdown}
                                </Button>
                              ) : (
                                <Button
                                  variant="contained"
                                  size="large"
                                  className={is_donation && "yellowBg"}
                                  color="primary"
                                  onClick={this.onOpenInvest}
                                >
                                  {is_donation ? "Donate Now" : "Invest Now"}
                                </Button>
                              )}
                            </div>
                            <div className="spaceBottomX">
                              <Button
                                variant="contained"
                                size="large"
                                color="primary"
                                onClick={this.onOpenEnquire}
                              >
                                Enquire
                              </Button>
                            </div>
                            <Dialog
                              open={open_enqurie}
                              onClose={this.onCloseEnquireDialog}
                            >
                              <DialogContent className="dialog-title">
                                <Typography variant="h5">Enquire</Typography>
                                <Button
                                  onClick={this.onCloseEnquireDialog}
                                  variant="outlined"
                                  disableElevation
                                >
                                  <Close />
                                </Button>
                              </DialogContent>
                              <DialogContent dividers>
                                <form onSubmit={this.onEnquire}>
                                  <Typography>
                                    Campaign:{" "}
                                    <Typography
                                      component="span"
                                      className="bold"
                                    >
                                      {campaign.name}
                                    </Typography>
                                  </Typography>
                                  <TextField
                                    multiline
                                    rows="6"
                                    rowsMax="10"
                                    variant="outlined"
                                    margin="normal"
                                    label="Enquiry"
                                    type="text"
                                    helperText="Type your enquiry and click Submit. We will respond within 1 working day."
                                    fullWidth
                                    required
                                    onChange={(e) =>
                                      this.onChange(e, "enquire")
                                    }
                                  />
                                  {enquire_loading ? (
                                    <Button
                                      margin="normal"
                                      variant="contained"
                                      color="primary"
                                      fullWidth
                                      className="space"
                                    >
                                      <CircularProgress
                                        color="default"
                                        size={25}
                                      />
                                    </Button>
                                  ) : (
                                    <Button
                                      margin="normal"
                                      variant="contained"
                                      color="primary"
                                      fullWidth
                                      type="submit"
                                      className="space"
                                    >
                                      Submit
                                    </Button>
                                  )}
                                </form>
                              </DialogContent>
                            </Dialog>
                            <Dialog
                              open={open_enquire_thank}
                              onClose={this.onCloseEnquireThankDialog}
                            >
                              <DialogContent className="dialog-title">
                                <Typography variant="h5" className="bold">
                                  Thank you for your inquiry!
                                </Typography>
                                <Button
                                  onClick={this.onCloseEnquireThankDialog}
                                  variant="outlined"
                                  disableElevation
                                >
                                  <Close />
                                </Button>
                              </DialogContent>
                              <DialogContent dividers>
                                <Typography>
                                  Our team is looking into it and will reply to
                                  you shortly.
                                </Typography>
                                <Typography>
                                  If your enquiry is urgent, you may call us at{" "}
                                  <a href="tel:+6598659648">+65 9865 9648</a>{" "}
                                  from
                                </Typography>
                                <Typography>
                                  Monday to Friday during our office hours.
                                </Typography>
                              </DialogContent>
                            </Dialog>
                          </Fragment>
                        )}
                      </div>

                      {campaign.campaign_images.length > 0 &&
                        (campaign.is_password_open || campaign.is_open) && (
                          <div id="gallery">
                            <Typography
                              variant="h5"
                              component="h2"
                              className="lightblue center bold spaceBig"
                            >
                              Gallery
                            </Typography>

                            <Grid container alignItems="center" spacing={2}>
                              {campaign.campaign_images.map((gallery, i) => (
                                <Grid
                                  key={i}
                                  item
                                  xs={4}
                                  onClick={() => this.onOpenGallery(i)}
                                >
                                  <img src={gallery.link} width="100%" />
                                </Grid>
                              ))}
                            </Grid>

                            {open_gallery && (
                              <Lightbox
                                mainSrc={
                                  campaign.campaign_images[gallery_index].link
                                }
                                nextSrc={
                                  campaign.campaign_images[
                                    (gallery_index + 1) %
                                      campaign.campaign_images.length
                                  ].link
                                }
                                prevSrc={
                                  campaign.campaign_images[
                                    (gallery_index +
                                      campaign.campaign_images.length -
                                      1) %
                                      campaign.campaign_images.length
                                  ].link
                                }
                                onCloseRequest={() =>
                                  this.setState({ open_gallery: false })
                                }
                                onMovePrevRequest={() =>
                                  this.setState({
                                    gallery_index:
                                      (gallery_index +
                                        campaign.campaign_images.length -
                                        1) %
                                      campaign.campaign_images.length,
                                  })
                                }
                                onMoveNextRequest={() =>
                                  this.setState({
                                    gallery_index:
                                      (gallery_index + 1) %
                                      campaign.campaign_images.length,
                                  })
                                }
                                animationOnKeyInput={true}
                                imageCaption={
                                  <Typography>
                                    {
                                      campaign.campaign_images[gallery_index]
                                        .name
                                    }
                                  </Typography>
                                }
                              />
                            )}
                          </div>
                        )}

                      {campaign.campaign_pdfs.length > 0 &&
                        (campaign.is_password_open || campaign.is_open) && (
                          <div id="pdf">
                            <Typography
                              variant="h5"
                              component="h2"
                              className="lightblue center bold spaceBig"
                            >
                              Documents
                            </Typography>

                            <ul>
                              {campaign.campaign_pdfs.map((pdf, i) => (
                                <li key={i}>
                                  <a target="_blank" href={pdf.link}>
                                    <Typography>{pdf.name}</Typography>
                                  </a>
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                    </Grid>
                    {/* end overview */}
                    {/* start description */}
                    <Grid item md={8} xs={12}>
                      {campaign.is_password_open || campaign.is_open ? (
                        <Typography
                          className="spaceBottom"
                          id="description"
                          dangerouslySetInnerHTML={{
                            __html: campaign.description,
                          }}
                          style={{ marginTop: "-55px" }}
                        />
                      ) : (
                        <div>
                          <Typography style={{ marginTop: "-25px" }}>
                            This campaign is closed for investment. If you are
                            an investor and want to access the campaign writeup,
                            please request for the password from <br />
                            <a href="mailto:hello@kapitalboost.com">
                              hello@kapitalboost.com
                            </a>
                          </Typography>
                          <form onSubmit={this.onRequestCloseCampaign}>
                            <TextField
                              margin="normal"
                              label="Campaign Password"
                              type="password"
                              fullWidth
                              required
                              value={campaign_password}
                              onChange={(e) =>
                                this.onChange(e, "campaign_password")
                              }
                            />
                            {campaign_request_loading ? (
                              <Button
                                margin="normal"
                                variant="contained"
                                color="primary"
                                fullWidth
                                className="space"
                              >
                                <CircularProgress color="default" size={25} />
                              </Button>
                            ) : (
                              <Button
                                margin="normal"
                                variant="contained"
                                color="primary"
                                fullWidth
                                type="submit"
                                className="space"
                              >
                                Submit
                              </Button>
                            )}
                          </form>
                        </div>
                      )}
                    </Grid>
                    {/* end description */}
                  </>
                )}
                {/* end small screen: width < 768px */}
              </Grid>
            </Container>

            <div id="other-project">
              <Container maxWidth="lg" className="spaceBottom">
                <Typography
                  variant="h4"
                  component="h2"
                  className="blue bold center spaceBottomX"
                >
                  Other Projects
                </Typography>
                <Grid container alignItems="flex-start" spacing={6}>
                  {/* spacing={4} */}
                  <Grid item xs={12} md={4}>
                    {campaign.related_1 && (
                      <Campaign campaign={campaign.related_1} />
                    )}
                  </Grid>
                  <Grid item xs={12} md={4}>
                    {campaign.related_2 && (
                      <Campaign campaign={campaign.related_2} />
                    )}
                  </Grid>
                  <Grid item xs={12} md={4}>
                    {campaign.related_3 && (
                      <Campaign campaign={campaign.related_3} />
                    )}
                  </Grid>
                </Grid>
              </Container>
            </div>

            {this.renderNotInvestable()}
            {campaign.type == "donation" && this.renderPaypalForm()}
          </div>
        ) : (
          <div className="loading">
            <CircularProgress />
          </div>
        )}
      </div>
    );
  }

  onCloseNotInvestable = () => {
    this.setState({ open_not_investable: false });
  };
  renderNotInvestable() {
    const { open_not_investable } = this.state;
    return (
      <Dialog
        open={open_not_investable}
        onClose={this.onCloseNotInvestable}
        maxWidth="md"
        fullWidth
      >
        <DialogContent className="dialog-title">
          <Typography variant="h5">Investment</Typography>
          <Button
            onClick={this.onCloseNotInvestable}
            variant="outlined"
            disableElevation
          >
            <Close />
          </Button>
        </DialogContent>
        <DialogContent dividers>
          <Typography>
            As part of our Know Your Customer (KYC) procedure, please complete
            your profile and upload identification documents before proceeding
            to invest.
          </Typography>
          <Button variant="contained" color="primary" className="space center">
            <Link to="/dashboard/profile">Complete Profile</Link>
          </Button>
        </DialogContent>
      </Dialog>
    );
  }

  renderPaypalForm() {
    const { invest, campaign, user, invest_amount, investmentId } = this.state;
    return (
      <form
        action="https://www.paypal.com/cgi-bin/webscr"
        // action="https://www.sandbox.paypal.com/cgi-bin/webscr"
        method="POST"
        id="form"
        ref={(e) => (this.paypal_form = e)}
      >
        <input type="hidden" name="business" value="erly@kapitalboost.com" />
        {/* <input type="hidden" name="business" value="reza@kapitalboost.co.id" /> */}
        {/* <input type="hidden" name="business" value="kbbusiness@gmail.com" /> */}
        {/* <input type="hidden" name="business" value="reza@seller.com" /> */}
        <input type="hidden" name="cmd" value="_xclick" />
        <input type="hidden" name="item_name" value={`${campaign.name}`} />
        <input type="hidden" name="item_number" value="1" />
        <input type="hidden" name="credits" value="510" />
        <input type="hidden" name="tx" value="TransactionID" />
        <input
          type="hidden"
          name="at"
          value="AaoP1MVoKJtxIYB_POhalYMFHuw8AUd3CNKp8UKd7-vWV2NX_wYdItzUbHA6zjvHs0wKTUjOE4Towtp5" // production Pak Erly
          // value="Aa_dymI1O41EP9hxajsxeVk_16IxuSj6YIGpVktuidosNTPzh7CnBb9wAKJ3roYrobeLc31ksb4lrVX5" // sandbox reza@kapitalboost.co.id
          // value="ABLhIs9LIHYd1oIB4G1a6tXqquRmAIJfwChjngbDUf3BnCQ8.L-iSkDq" // sandbox kbbusiness@gmail.com

          // Sandbox Paypal Pak Erly
          // value="ODJuzlFwX-WNtbrnJhd_EB33SXbFXYt7xkWb8AGPGQp-eqi5l0wKkTr8it0"
        />
        <input type="hidden" name="userid" value="1" />
        <input
          type="hidden"
          name="cpp_header_image"
          value="https://kapitalboost.com/assets/images/logo.png"
        />
        <input type="hidden" name="no_shipping" value="1" />
        <input type="hidden" name="currency_code" value="SGD" />
        <input type="hidden" name="handling" value="0" />
        <input type="hidden" name="amount" value={invest_amount} />
        <input type="hidden" name="custom" value="" />
        <input
          type="hidden"
          name="cancel_return"
          value="https://kapitalboost.com/cancel.php"
        />
        <input
          type="hidden"
          name="return"
          value={`https://kapitalboost.com/api/invest_is_paid/${investmentId}`} //https://kapitalboost.com/payment
          // value={`http://localhost:8000/api/invest_is_paid/${investmentId}`}
        />

        <input type="hidden" name="order_id" value="123" />
        <input type="hidden" name="item_name_1" value={`${campaign.name}`} />
        <input type="hidden" name="item_item_id_1" value="12345" />
        <input
          type="hidden"
          name="item_description_1"
          value="Kapitalboost campaign"
        />
        <input type="hidden" name="item_quantity_1" value="1" />
        <input type="hidden" name="currency" value="SGD" />
        <input type="hidden" name="refundable" value="false" />
        <input type="hidden" name="username" value={user.firstname} />
        <input type="hidden" name="lastname" value={user.lastname} />
        <input type="hidden" name="xfersphone" value={user.phone_no} />
        <input type="hidden" name="email" value={user.email} />
      </form>
    );
  }
}

export default withGoogleReCaptcha(withRouter(Page));
