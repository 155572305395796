import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";

import {
  Container,
  AppBar,
  Tabs,
  Tab,
  Paper,
  Snackbar,
  InputAdornment,
  CircularProgress,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  FormControlLabel,
  Checkbox,
  Toolbar,
  MenuItem,
  NativeSelect,
  FormControl,
  Typography,
  Button,
  InputLabel,
  Hidden,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Grid,
  Select,
} from "@material-ui/core";
import { AccountCircle, Close } from "@material-ui/icons";
import api from "services/api";
import hp from "services/hp";

class Page extends Component {
  state = {
    accounts: null,
    open_edit: false,
    open_add: false,
    edit_account: {},
    add_account: {},
    update_loading: false,
    add_loading: false,
    openSuccessSnack: false,
    allBanksNew: [],
    bank: "",
    bicCode: "",
    ibanCode: "",
    isOtherBank: false, // * if user choose other bank on add bank account
    isCountryBankEmpty: false, // * if data of country of bank is empty
    isCurrencyEmpty: false, // * if data of currency is empty
    isBicCodeEmpty: false, // * if data of BIC Code is empty
    isIbanCodeEmpty: false, // * if data of IBAN Code is empty
    all_banks: [
      "Australia and New Zealand Banking Group",
      "Bangkok Bank Public Company Limited",
      "Bank of America, National Association",
      "Bank of China Limited",
      "Bank of East Asia, The",
      "Bank of India",
      "Bank of Tokyo-Mitsubishi UFJ, The",
      "BNP Paribas",
      "China Citic Bank International Limited (Singapore)",
      "Chinatrust Commercial Bank Co. Ltd",
      "CIMB Bank Berhad",
      "Citibank NA Singapore Branch",
      "Citibank Singapore Limited",
      "Commerzbank Aktiengesellschaft",
      "Credit Agricole Corporate And Investment Bank",
      "DBS Bank Ltd",
      "Deutsche Bank AG",
      "DnB Bank ASA",
      "Far Eastern Bank (Singapore)",
      "First Commercial Bank",
      "HL Bank",
      "Hongkong and Shanghai Banking Corporation Limited, Singapore Branch, The",
      "HSBC Bank (Singapore) Ltd",
      "ICICI Bank Limited",
      "Indian Bank",
      "Indian Overseas Bank",
      "Industrial &amp; Commercial Bank Of China",
      "Intesa Sanpaolo SpA",
      "JPMorgan Chase Bank, N.A.",
      "Korea Exchange Bank",
      "LANDESBANK BADEN-WUERTTEMBERG (SINGAPORE)",
      "Malayan Banking Berhad",
      "Maybank Singapore Limited",
      "Mizuho Bank Limited",
      "National Australia Bank Ltd",
      "Nordea Bank AB",
      "Oversea-Chinese Banking Corporation Ltd",
      "PT Bank Negara Indonesia (Persero) TBK",
      "RHB Bank Berhad",
      "Skandinaviska Enskilda Banken AB",
      "Societe Generale",
      "Standard Chartered Bank, (Singapore) Limited",
      "State Bank Of India",
      "Sumitomo Mitsui Banking Corporation",
      "Svenska Handelsbanken AB",
      "The Royal Bank Of Scotland N.V. (Singapore)",
      "UBS AG",
      "UCO Bank",
      "United Overseas Bank Ltd",
    ],
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.onStart();
  }
  onStart = async () => {
    const res = await api.get("bank");
    const { data } = res.data;
    // console.log(data);
    const bankRes = await api.get(`all-bank`);
    // console.log(bankRes);
    const allBanksNew = bankRes.data;
    // console.log(allBanksNew);
    this.setState({ accounts: data, allBanksNew });
    // hp.save_bank(data)
  };

  onEditOpen = (e, i) => {
    e.preventDefault();
    const { accounts } = this.state;
    const edit_account = { ...accounts[i] };
    edit_account.bank_name = edit_account.bank.bank_name;
    edit_account.country_bank = edit_account.bank.country;
    edit_account.currency = edit_account.bank.currency;
    edit_account.bic_code = edit_account.bank.bic_code;
    edit_account.iban_code = edit_account.bank.iban_code;
    // console.log(edit_account);
    // console.log(edit_account.bank.country);
    this.setState({ open_edit: true, edit_account });
  };
  onEditClose = () => {
    this.setState({ open_edit: false });
  };
  onAddOpen = (e, i) => {
    e.preventDefault();
    this.setState({ open_add: true });
  };
  onAddClose = () => {
    this.setState({ open_add: false });
  };
  onChange = (e, f, g) => {
    let { state } = this;
    const { add_account } = this.state;
    // console.log(add_account);
    // console.log(state);
    state[f][g] = e.target.value;
    // console.log(state);
    this.setState({ state });
  };
  onChangeBankOld = (e) => {
    let { bank } = this.state;
    const { add_account } = this.state;
    const { detailBankRes } = "";
    // console.log(add_account);
    this.setState({ isOtherBank: false });
    add_account.bank_name = e.target.value;
    bank = e.target.value;
    // * bismillah trying to get bic code and iban code
    // console.log(bank);
    if (bank == "other") {
      this.setState({ isOtherBank: true });
    }
    // console.log(e);
    this.setState({ bank, add_account });
  };
  onChangeBank = async (e) => {
    let { bank } = this.state;
    let detailBankRes = {};
    const { add_account, edit_account } = this.state;
    // console.log(add_account);
    // console.log(edit_account.bank_name);
    this.setState({ isOtherBank: false });
    add_account.bank_name = e.target.value;
    edit_account.bank_name = e.target.value;
    bank = e.target.value;
    // console.log(bank);
    // * If choose other bank
    if (bank == "other") {
      this.setState({ isOtherBank: true });
    }
    // * bismillah trying to get bic code and iban code
    // * if choose bank in the list of banks
    else {
      detailBankRes = await api.get(`detailBank/` + bank);
      // const bicCode = detailBankRes.data.bic_code;
      // const ibanCode = detailBankRes.data.iban_code;
      // * add account
      if (
        !detailBankRes.data.country
          ? ((add_account.country_bank = detailBankRes.data.country),
            this.setState({ isCountryBankEmpty: true }),
            (add_account.country_bank = ""))
          : (add_account.country_bank = detailBankRes.data.country)
      );
      if (
        !detailBankRes.data.currency
          ? ((add_account.currency = detailBankRes.data.currency),
            this.setState({ isCurrencyEmpty: true }),
            (add_account.currency = ""))
          : (add_account.currency = detailBankRes.data.currency)
      );
      if (
        !detailBankRes.data.bic_code
          ? ((add_account.bic_code = detailBankRes.data.bic_code),
            this.setState({ isBicCodeEmpty: true }),
            (add_account.bic_code = ""))
          : (add_account.bic_code = detailBankRes.data.bic_code)
      );
      if (
        !detailBankRes.data.iban_code
          ? ((add_account.iban_code = detailBankRes.data.iban_code),
            this.setState({ isIbanCodeEmpty: true }),
            (add_account.iban_code = ""))
          : (add_account.iban_code = detailBankRes.data.iban_code)
      );
      // add_account.currency = detailBankRes.data.currency;
      // add_account.bic_code = detailBankRes.data.bic_code;
      // add_account.iban_code = detailBankRes.data.iban_code;

      // * edit account
      if (
        !detailBankRes.data.country
          ? ((edit_account.country_bank = detailBankRes.data.country),
            this.setState({ isCountryBankEmpty: true }),
            (edit_account.country_bank = ""))
          : (edit_account.country_bank = detailBankRes.data.country)
      );
      if (
        !detailBankRes.data.currency
          ? ((edit_account.currency = detailBankRes.data.currency),
            this.setState({ isCurrencyEmpty: true }),
            (edit_account.currency = ""))
          : (edit_account.currency = detailBankRes.data.currency)
      );
      if (
        !detailBankRes.data.bic_code
          ? ((edit_account.bic_code = detailBankRes.data.bic_code),
            this.setState({ isBicCodeEmpty: true }),
            (edit_account.bic_code = ""))
          : (edit_account.bic_code = detailBankRes.data.bic_code)
      );
      if (
        !detailBankRes.data.iban_code
          ? ((edit_account.iban_code = detailBankRes.data.iban_code),
            this.setState({ isIbanCodeEmpty: true }),
            (edit_account.iban_code = ""))
          : (edit_account.iban_code = detailBankRes.data.iban_code)
      );
      // edit_account.currency = detailBankRes.data.currency;
      // edit_account.country_bank = detailBankRes.data.country;
      // edit_account.bic_code = detailBankRes.data.bic_code;
      // edit_account.iban_code = detailBankRes.data.iban_code;

      // console.log(detailBankRes);
      // console.log("BIC Code: " + bicCode);
      // console.log("IBAN Code: " + ibanCode);
      // const { detailBank } = detailBankRes.data;
      // console.log(detailBank);
      // this.setState({ bicCode, ibanCode });
    }
    // console.log(e);
    this.setState({ bank, add_account, edit_account });
  };

  onUpdateOld = async (e) => {
    e.preventDefault();
    const { edit_account } = this.state;
    this.setState({ update_loading: true });
    const res = await api.post(`bank/${edit_account.id}`, {
      account_name: edit_account.account_name,
      bank_name: edit_account.bank_name,
      country_bank: edit_account.country_bank,
      account_number: edit_account.account_number,
      currency: edit_account.currency,
      bic_code: edit_account.bic_code,
      iban_code: edit_account.iban_code,
    });
    const { data } = res;
    this.setState({ update_loading: false });
    if (data.error) {
      alert(data.message);
      return;
    }
    this.setState({ openSuccessSnack: true, open_edit: false });
    this.onStart();
  };
  onUpdate = async (e) => {
    e.preventDefault();
    const { edit_account } = this.state;
    this.setState({ update_loading: true });
    if (edit_account.bank_name == "other") {
      const res = await api.post(`bank/${edit_account.id}`, {
        account_name: edit_account.account_name,
        bank_name: edit_account.bankName,
        country_bank: edit_account.country_bank,
        account_number: edit_account.account_number,
        currency: edit_account.currency,
        bic_code: edit_account.bic_code,
        iban_code: edit_account.iban_code,
      });
      // console.log(res);
      const { data } = res;
      this.setState({ update_loading: false });
      if (data.error) {
        alert(data.message);
        return;
      }
      this.setState({ openSuccessSnack: true, open_edit: false });
      this.onStart();
    } else {
      // console.log(edit_account);
      const res = await api.post(`bank/${edit_account.id}`, {
        account_name: edit_account.account_name,
        bank_name: edit_account.bank_name,
        country_bank: edit_account.country_bank,
        account_number: edit_account.account_number,
        currency: edit_account.currency,
        bic_code: edit_account.bic_code,
        iban_code: edit_account.iban_code,
      });
      // console.log(res);
      const { data } = res;
      this.setState({ update_loading: false });
      if (data.error) {
        alert(data.message);
        return;
      }
      this.setState({ openSuccessSnack: true, open_edit: false });
      this.onStart();
    }
  };
  onAdd = async (e) => {
    e.preventDefault();
    const { add_account } = this.state;
    // console.log(add_account);
    this.setState({ add_loading: true });
    if (add_account.bank_name == "other") {
      const res = await api.post(`bank`, {
        account_name: add_account.account_name,
        bank_name: add_account.bankName,
        country_bank: add_account.country_bank,
        account_number: add_account.account_number,
        currency: add_account.currency,
        bic_code: add_account.bic_code,
        iban_code: add_account.iban_code,
      });
      // console.log(res);
      const { data } = res;
      this.setState({ add_loading: false });
      if (data.error) {
        alert(data.message);
        return;
      }
      this.setState({ open_add: false });
      this.onStart();
    } else {
      // console.log(add_account);
      const res = await api.post(`bank`, {
        account_name: add_account.account_name,
        bank_name: add_account.bank_name,
        country_bank: add_account.country_bank,
        account_number: add_account.account_number,
        currency: add_account.currency,
        bic_code: add_account.bic_code,
        iban_code: add_account.iban_code,
      });
      const { data } = res;
      this.setState({ add_loading: false });
      if (data.error) {
        alert(data.message);
        return;
      }
      this.setState({ openSuccessSnack: true, open_add: false });
      this.onStart();
    }
    // const { data } = res;
    // this.setState({ add_loading: false });
    // if (data.error) {
    //   alert(data.message);
    //   return;
    // }
    // this.setState({ open_add: false });
    // this.onStart();
  };

  render() {
    const {
      accounts,
      edit_account,
      add_account,
      bankName,
      update_loading,
      all_banks,
      allBanksNew,
      add_loading,
      isOtherBank,
      isCountryBankEmpty,
      isCurrencyEmpty,
      isBicCodeEmpty,
      isIbanCodeEmpty,
      bicCode,
      ibanCode,
    } = this.state;
    return (
      <div id="bank-account">
        <Container maxWidth="xl">
          <TableContainer
            component={Paper}
            id="dashboard-invest-table"
            className="spaceBottom"
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={8} className="bold">
                    Details of Bank Account
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={this.onAddOpen}
                    >
                      Add Bank Account
                    </Button>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" className="bold" align="left">
                    No.
                  </TableCell>
                  <TableCell component="th" className="bold" align="left">
                    Account Holder Name
                  </TableCell>
                  <TableCell component="th" className="bold" align="left">
                    Bank Name
                  </TableCell>
                  <TableCell component="th" className="bold" align="left">
                    Country of Bank
                  </TableCell>
                  <TableCell component="th" className="bold" align="left">
                    Account Number
                  </TableCell>
                  <TableCell component="th" className="bold" align="left">
                    Currency
                  </TableCell>
                  <TableCell component="th" className="bold" align="left">
                    SWIFT Code
                  </TableCell>
                  <TableCell component="th" className="bold" align="left">
                    IBAN Code
                  </TableCell>
                  {/* <TableCell component="th" className="bold" align="left">
                    Menu
                  </TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {accounts ? (
                  <Fragment>
                    {accounts.length > 0 ? (
                      <Fragment>
                        {accounts.map((account, i) => (
                          <TableRow key={i}>
                            <TableCell align="left">{i + 1}</TableCell>
                            <TableCell align="left">
                              {account.account_name
                                ? account.account_name
                                : "-"}
                            </TableCell>
                            <TableCell align="left">
                              {account.bank_name ? account.bank_name : "-"}
                            </TableCell>
                            <TableCell align="left">
                              {account.bank ? account.bank.country : "-"}
                            </TableCell>
                            <TableCell align="left">
                              {account.account_number}
                            </TableCell>
                            <TableCell align="left">
                              {account.bank ? account.bank.currency : "-"}
                            </TableCell>
                            <TableCell align="left">
                              {account.bank ? account.bank.bic_code : "-"}
                            </TableCell>
                            <TableCell align="left">
                              {account.bank ? account.bank.iban_code : "-"}
                            </TableCell>
                            {/* <TableCell align="left">
                              <Button
                                variant="contained"
                                onClick={(e) => this.onEditOpen(e, i)}
                                color="primary"
                              >
                                Edit
                              </Button>
                            </TableCell> */}
                          </TableRow>
                        ))}
                      </Fragment>
                    ) : (
                      <TableRow className="loading-short">
                        <TableCell colSpan={5} align="center">
                          {" "}
                          <Typography>No bank account added yet</Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </Fragment>
                ) : (
                  <TableRow className="loading-short">
                    <TableCell colSpan={5} align="center">
                      {" "}
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Container>

        <Dialog open={this.state.open_edit} onClose={this.onEditClose}>
          <DialogContent className="dialog-title">
            <Typography variant="h5">Update Bank Account</Typography>
            <Button
              onClick={this.onEditClose}
              variant="outlined"
              disableElevation
            >
              <Close />
            </Button>
          </DialogContent>
          <DialogContent dividers>
            <form onSubmit={this.onUpdate}>
              <TextField
                margin="normal"
                label="Account Holder Name"
                type="text"
                value={edit_account.account_name}
                onChange={(e) =>
                  this.onChange(e, "edit_account", "account_name")
                }
                fullWidth
                required
              />
              {isOtherBank ? (
                <TextField
                  margin="normal"
                  label="Fill the bank here"
                  type="text"
                  value={edit_account.bankName}
                  onChange={(e) =>
                    this.onChange(e, "edit_account", "bank_name")
                  }
                  fullWidth
                  required
                />
              ) : (
                <FormControl margin="normal" required fullWidth>
                  <InputLabel>Bank Name</InputLabel>
                  <NativeSelect
                    value={edit_account.bankName}
                    onChange={(e) => this.onChangeBank(e)}
                    required
                  >
                    <option selected>{edit_account.bank_name}</option>
                    {allBanksNew.map((allBank, i) => (
                      <option value={allBank.id} key={i}>
                        {allBank.bank_name}
                      </option>
                    ))}
                    <option value="other">Other</option>
                  </NativeSelect>
                </FormControl>
              )}
              {isOtherBank ? (
                <TextField
                  margin="normal"
                  label="Country of Bank"
                  type="text"
                  value={edit_account.country_bank}
                  defaultValue="Country Bank"
                  placeholder="Fill the country bank here"
                  onChange={(e) =>
                    this.onChange(e, "edit_account", "country_bank")
                  }
                  fullWidth
                  required
                />
              ) : isCountryBankEmpty ? (
                <TextField
                  margin="normal"
                  placeholder="Please fill the country of bank here"
                  label="Country of Bank"
                  type="text"
                  value={edit_account.country_bank}
                  onChange={(e) =>
                    this.onChange(e, "edit_account", "country_bank")
                  }
                  fullWidth
                  required
                />
              ) : (
                <TextField
                  margin="normal"
                  label="Country of Bank"
                  type="text"
                  value={edit_account.country_bank}
                  defaultValue="Country Bank"
                  onChange={(e) =>
                    this.onChange(e, "edit_account", "country_bank")
                  }
                  fullWidth
                  required
                  InputProps={{ readOnly: true }}
                />
              )}
              <TextField
                margin="normal"
                label="Account Number"
                type="text"
                value={edit_account.account_number}
                onChange={(e) =>
                  this.onChange(e, "edit_account", "account_number")
                }
                fullWidth
                required
              />
              {isOtherBank ? (
                <TextField
                  margin="normal"
                  label="Currency"
                  type="text"
                  value={edit_account.currency}
                  defaultValue="Currency"
                  placeholder="Fill the Currency here"
                  onChange={(e) => this.onChange(e, "edit_account", "currency")}
                  fullWidth
                  required
                />
              ) : isCurrencyEmpty ? (
                <TextField
                  margin="normal"
                  label="Currency"
                  placeholder="Please fill the currency here"
                  type="text"
                  value={edit_account.currency}
                  onChange={(e) => this.onChange(e, "edit_account", "currency")}
                  fullWidth
                  required
                />
              ) : (
                <TextField
                  margin="normal"
                  label="Currency"
                  type="text"
                  value={edit_account.currency}
                  defaultValue="Currency"
                  // value={edit_account.bank ? edit_account.bank.currency : ""}
                  onChange={(e) => this.onChange(e, "edit_account", "currency")}
                  fullWidth
                  required
                  InputProps={{ readOnly: true }}
                />
              )}
              {isOtherBank ? (
                <TextField
                  margin="normal"
                  label="SWIFT / BIC Code"
                  type="text"
                  value={edit_account.bic_code}
                  onChange={(e) => this.onChange(e, "edit_account", "bic_code")}
                  fullWidth
                />
              ) : isBicCodeEmpty ? (
                <TextField
                  margin="normal"
                  label="SWIFT / BIC Code"
                  type="text"
                  defaultValue="SWIFT / BIC Code"
                  value={edit_account.bic_code}
                  fullWidth
                  placeholder="Fill the SWIFT / BIC Code here"
                  onChange={(e) => this.onChange(e, "edit_account", "bic_code")}
                />
              ) : (
                <TextField
                  disabled
                  margin="normal"
                  label="SWIFT / BIC Code"
                  type="text"
                  defaultValue="SWIFT / BIC Code"
                  value={edit_account.bic_code}
                  fullWidth
                  onChange={(e) => this.onChange(e, "edit_account", "bic_code")}
                />
              )}
              {isOtherBank ? (
                <TextField
                  margin="normal"
                  label="IBAN Code"
                  type="text"
                  value={edit_account.iban_code}
                  onChange={(e) =>
                    this.onChange(e, "edit_account", "iban_code")
                  }
                  fullWidth
                />
              ) : isIbanCodeEmpty ? (
                <TextField
                  margin="normal"
                  label="IBAN Code"
                  type="text"
                  defaultValue="IBAN Code"
                  value={edit_account.iban_code}
                  fullWidth
                  placeholder="Fill the IBAN Code here"
                  onChange={(e) =>
                    this.onChange(e, "edit_account", "iban_code")
                  }
                />
              ) : (
                <TextField
                  disabled
                  margin="normal"
                  label="IBAN Code"
                  type="text"
                  defaultValue="IBAN Code"
                  value={edit_account.iban_code}
                  fullWidth
                  onChange={(e) =>
                    this.onChange(e, "edit_account", "iban_code")
                  }
                />
              )}
              {update_loading ? (
                <Button
                  margin="normal"
                  variant="contained"
                  color="primary"
                  fullWidth
                  className="space"
                >
                  <CircularProgress color="default" size={25} />
                </Button>
              ) : (
                <Button
                  margin="normal"
                  variant="contained"
                  color="primary"
                  fullWidth
                  type="submit"
                  className="space"
                >
                  Update
                </Button>
              )}
            </form>
          </DialogContent>
        </Dialog>
        <Dialog open={this.state.open_add} onClose={this.onAddClose}>
          <DialogContent className="dialog-title">
            <Typography variant="h5">Add Bank Account</Typography>
            <Button
              onClick={this.onAddClose}
              variant="outlined"
              disableElevation
            >
              <Close />
            </Button>
          </DialogContent>
          <DialogContent dividers>
            <form onSubmit={this.onAdd}>
              <TextField
                margin="normal"
                label="Account Holder Name"
                type="text"
                value={add_account.account_name}
                onChange={(e) =>
                  this.onChange(e, "add_account", "account_name")
                }
                fullWidth
                required
              />
              {isOtherBank ? (
                <TextField
                  margin="normal"
                  label="Fill the bank here"
                  type="text"
                  value={add_account.bankName}
                  onChange={(e) => this.onChange(e, "add_account", "bank_name")}
                  fullWidth
                  required
                />
              ) : (
                <FormControl margin="normal" required fullWidth>
                  <InputLabel>Bank Name</InputLabel>
                  <NativeSelect
                    value={add_account.bank_name}
                    onChange={(e) => this.onChangeBank(e)}
                    required
                  >
                    <option selected disabled>
                      Choose Bank
                    </option>
                    {allBanksNew &&
                      allBanksNew.map((allBank, i) => (
                        <option value={allBank.id} key={i}>
                          {allBank.bank_name}
                        </option>
                      ))}
                    <option value="other">Other</option>
                  </NativeSelect>
                </FormControl>
              )}
              {isOtherBank ? (
                <TextField
                  margin="normal"
                  label="Country of Bank"
                  type="text"
                  value={add_account.country_bank}
                  defaultValue="Country Bank"
                  placeholder="Fill the country bank here"
                  onChange={(e) =>
                    this.onChange(e, "add_account", "country_bank")
                  }
                  fullWidth
                  required
                  // InputProps={{ readOnly: true }}
                />
              ) : isCountryBankEmpty ? (
                <TextField
                  margin="normal"
                  placeholder="Please fill the country of bank here"
                  label="Country of Bank"
                  type="text"
                  value={add_account.country_bank}
                  onChange={(e) =>
                    this.onChange(e, "add_account", "country_bank")
                  }
                  fullWidth
                  required
                />
              ) : (
                <TextField
                  margin="normal"
                  label="Country of Bank"
                  type="text"
                  value={add_account.country_bank}
                  defaultValue="Country Bank"
                  onChange={(e) =>
                    this.onChange(e, "add_account", "country_bank")
                  }
                  fullWidth
                  required
                  InputProps={{ readOnly: true }}
                />
              )}
              <TextField
                margin="normal"
                label="Account Number"
                type="text"
                value={add_account.account_number}
                onChange={(e) =>
                  this.onChange(e, "add_account", "account_number")
                }
                fullWidth
                required
              />
              {isOtherBank ? (
                <TextField
                  margin="normal"
                  label="Currency"
                  type="text"
                  value={add_account.currency}
                  defaultValue="Currency"
                  onChange={(e) => this.onChange(e, "add_account", "currency")}
                  fullWidth
                  required
                  // InputProps={{ readOnly: true }}
                />
              ) : isCurrencyEmpty ? (
                <TextField
                  margin="normal"
                  label="Currency"
                  placeholder="Please fill the currency here"
                  type="text"
                  value={add_account.currency}
                  onChange={(e) => this.onChange(e, "add_account", "currency")}
                  fullWidth
                  required
                />
              ) : (
                <TextField
                  margin="normal"
                  label="Currency"
                  type="text"
                  value={add_account.currency}
                  defaultValue="Currency"
                  onChange={(e) => this.onChange(e, "add_account", "currency")}
                  fullWidth
                  required
                  InputProps={{ readOnly: true }}
                />
              )}
              {isOtherBank ? (
                <TextField
                  margin="normal"
                  label="SWIFT / BIC Code"
                  type="text"
                  value={add_account.bic_code}
                  onChange={(e) => this.onChange(e, "add_account", "bic_code")}
                  fullWidth
                />
              ) : isBicCodeEmpty ? (
                <TextField
                  margin="normal"
                  label="SWIFT / BIC Code"
                  type="text"
                  defaultValue="SWIFT / BIC Code"
                  value={add_account.bic_code}
                  fullWidth
                  placeholder="Fill the SWIFT / BIC Code here"
                  onChange={(e) => this.onChange(e, "add_account", "bic_code")}
                />
              ) : (
                <TextField
                  disabled
                  margin="normal"
                  label="SWIFT / BIC Code"
                  type="text"
                  defaultValue="SWIFT / BIC Code"
                  value={add_account.bic_code}
                  fullWidth
                  onChange={(e) => this.onChange(e, "add_account", "bic_code")}
                />
              )}
              {isOtherBank ? (
                <TextField
                  margin="normal"
                  label="IBAN Code"
                  type="text"
                  value={add_account.iban_code}
                  onChange={(e) => this.onChange(e, "add_account", "iban_code")}
                  fullWidth
                />
              ) : isIbanCodeEmpty ? (
                <TextField
                  margin="normal"
                  label="IBAN Code"
                  type="text"
                  defaultValue="IBAN Code"
                  value={add_account.iban_code}
                  fullWidth
                  placeholder="Fill the IBAN Code here"
                  onChange={(e) => this.onChange(e, "add_account", "iban_code")}
                />
              ) : (
                <TextField
                  disabled
                  margin="normal"
                  label="IBAN Code"
                  type="text"
                  defaultValue="IBAN Code"
                  value={add_account.iban_code}
                  fullWidth
                  onChange={(e) => this.onChange(e, "add_account", "iban_code")}
                />
              )}
              {add_loading ? (
                <Button
                  margin="normal"
                  variant="contained"
                  color="primary"
                  fullWidth
                  className="space"
                >
                  <CircularProgress color="default" size={25} />
                </Button>
              ) : (
                <Button
                  margin="normal"
                  variant="contained"
                  color="primary"
                  fullWidth
                  type="submit"
                  className="space"
                >
                  Add
                </Button>
              )}
            </form>
          </DialogContent>
        </Dialog>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={this.state.openSuccessSnack}
          autoHideDuration={6000}
          onClose={() => this.setState({ openSuccessSnack: false })}
        >
          <div className="success-alert">
            <Typography>Your bank account is successfully updated</Typography>
          </div>
        </Snackbar>
      </div>
    );
  }
}

export default Page;
