import React, { createContext, useContext, useState } from "react";

const ForgetPasswordDialogContext = createContext(null);

function ForgetPasswordDialogProvider({ children }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <ForgetPasswordDialogContext.Provider
      value={{
        isOpen,
        onOpen: () => setIsOpen(true),
        onClose: () => setIsOpen(false),
      }}
    >
      {children}
    </ForgetPasswordDialogContext.Provider>
  );
}

function useForgetPasswordDialog() {
  return useContext(ForgetPasswordDialogContext);
}

export {
  ForgetPasswordDialogProvider,
  ForgetPasswordDialogContext,
  useForgetPasswordDialog,
};
