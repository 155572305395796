import { Button, Container, Paper } from '@material-ui/core'
import React, {Component} from 'react'

import RateReviewIcon from '@material-ui/icons/RateReview';

class Veriff extends Component {
  render() {
    return (
      <div id="Basis-id">
        {/* <img src={Banner} width="100%" /> */}


        <Container maxWidth="md" >
            <div id="bas-widget-contain\er" style={{minHeight: "200px", padding: '100px 0'}}>
              <br />
              <br />
              <center>
              <RateReviewIcon color="primary" style={{ fontSize: 120 }} />
              <br />
              <h2>Thank you for verification data on Veriff</h2>
              <p>Your data is under review.</p>
              <br />
              <Button variant="contained" color="primary">
              <a href="/dashboard">Back to Dashboard</a>
              </Button>
              </center>
            </div>
        </Container>

      </div>
    )
  }
}

export default Veriff