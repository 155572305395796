import React, {Component, Fragment} from 'react'
import {Link} from 'react-router-dom'

import {Container, AppBar, CircularProgress, Paper, ExpansionPanel, LinearProgress, ExpansionPanelSummary, ExpansionPanelDetails, Tabs, Tab, InputAdornment, FormControlLabel, Checkbox, Toolbar, MenuItem, FormControl, Typography, Button, InputLabel, Hidden, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Grid, Select} from '@material-ui/core'
import {AccountCircle, Close} from '@material-ui/icons'
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3'
import api_public from 'services/api_public'
import hp from 'services/hp'

class Page extends Component {

   state = {
      is_verified: 'null',
      password_error: false,
      password_confirm_error: false,
      password: '',
      password_confirm: '',
      forget_loading: false,
      user: null,
   }

   componentDidMount() {
      window.scrollTo(0,0)
      this.onStart()
   }

   onStart = async() => {
      const {match} = this.props
      if(hp.is_login()) {
         this.props.history.push('/')
      }
      const res = await api_public.get(`forget-request?code=${match.params.code}`)
      const {data} = res
      if(data.error) {
         alert(data.message)
         return
      }
      this.setState({ user: data })
   }

   onChange = (e, f) => {
      let {state} = this
      state[f] = e.target.value
      this.setState({ state })
   }

   onForgetNew = async(e) => {
      e.preventDefault()
      const {match, history} = this.props

      const {password, password_confirm} = this.state
      if(password.length < 8) {
         this.setState({ password_error: true })
         return
      }
      if(password != password_confirm) {
         this.setState({ password_confirm_error: true})
         return
      }
      this.setState({ forget_loading: true })
      const recaptcha_token = await this.props.googleReCaptchaProps.executeRecaptcha('forget_new')
      const res = await api_public.post('forget-request', {recaptcha_token, password, code: match.params.code})
      this.setState({ forget_loading: false })
      let {data} = res
      if(data.success) {
         alert("Password is successfully changed")
         history.push('/')
      }
   }

   render() {
      const {user, is_verified, password, password_confirm, password_error, password_confirm_error, forget_loading} = this.state
      return (
         <Container id="forget">

            <Paper id="paper" className="paddingBig" elevation={3} >
               {user ?
                  <form onSubmit={this.onForgetNew}>
                     <Typography variant="h5" className="space">Hi {user.firstname}, Please enter new password</Typography>
                     <TextField  margin="normal" label="Password - Minimum 8 Characters" type="password" value={password} onChange={(e)=>this.onChange(e, 'password')} fullWidth required error={password_error} helperText={password_error && 'Minimum 8 Characters'} />
                     <TextField  margin="normal" label="Confirm Password" type="password" value={password_confirm} onChange={(e)=>this.onChange(e, 'password_confirm')} fullWidth required error={password_confirm_error} helperText={password_confirm_error && 'Confirm Password must be the same as the Password' } />
                     {forget_loading ?
                        <Button margin="normal" variant="contained" color="primary" fullWidth className="space" ><CircularProgress color="default" size={25} /></Button>
                        :
                        <Button  margin="normal" variant="contained" color="primary" fullWidth type="submit" className="space"  >Submit</Button>
                     }
                  </form>
                  :
                  <div>
                     <LinearProgress className="spaceBigX" style={{ width: 400 }} />
                  </div>
               }
            </Paper>

         </Container>
      )
   }
}

export default withGoogleReCaptcha(Page)
