import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Typography,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import React, { useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import api_public from "services/api_public";
import { useResendEmailDialogContext } from "contexts/ResendEmailDialogContext";

export default function ResendEmailDialog() {
  const { isOpen, onClose, email } = useResendEmailDialogContext();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [isLoading, setIsLoading] = useState(false);

  async function handleSubmit(event) {
    event.preventDefault();
    setIsLoading(true);

    const recaptcha_token = await executeRecaptcha("resend");

    const { data } = await api_public.post("resend", {
      email,
      recaptcha_token,
    });

    setIsLoading(false);

    if (data.error) {
      alert(data.message);
      return;
    }

    alert(
      "A verification email has been sent to you. Please click on the link to activate your account. In case it’s not in your Inbox, please check the Spam or Junk folder"
    );
    onClose();
  }

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogContent className="dialog-title">
        <Typography variant="h5">
          Your account has not been activated.
        </Typography>
        <Button onClick={onClose} variant="outlined" disableElevation>
          <Close />
        </Button>
      </DialogContent>
      <DialogContent dividers>
        <Typography className="space">
          {" "}
          Please check your email inbox or spam folder for the Account
          Activation email, and click on the link to activate.
        </Typography>

        <Button
          margin="normal"
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleSubmit}
          disabled={isLoading}
        >
          {isLoading ? (
            <CircularProgress color="default" size={25} />
          ) : (
            "Resend verification email"
          )}
        </Button>
      </DialogContent>
    </Dialog>
  );
}
