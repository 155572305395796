import React, { createContext, useContext, useState } from "react";

const LoginDialogContext = createContext(null);

function LoginDialogProvider({ children }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <LoginDialogContext.Provider
      value={{
        isOpen,
        onOpen: () => setIsOpen(true),
        onClose: () => setIsOpen(false),
      }}
    >
      {children}
    </LoginDialogContext.Provider>
  );
}

function useLoginDialog() {
  return useContext(LoginDialogContext);
}

export { LoginDialogContext, LoginDialogProvider, useLoginDialog };
