import React, { Component } from "react";

import "./styles/about.scss";

import {
    Paper,
    Container,
    Grid,
    Hidden,
    Button,
  } from "@material-ui/core";

import BannerImageWeb from 'images/about/new/header.png';
import BannerImageMobile from 'images/about/new/header-m.png';
import StoryImage from 'images/about/new/story.png';
import StoryImageMobile from 'images/about/new/story-m.png';
import ImpactImage from 'images/about/new/impact.png';
import ImpactImageMobile from 'images/about/new/impact-m.png';
import Erly from 'images/about/new/erly.png';
import ErlyMobile from 'images/about/new/erly-m.png';
import Norli from 'images/about/new/norliana.png';
import NorliMobile from 'images/about/new/norliana-m.png';
import Fachri from 'images/about/new/fachri.png';
import FachriMobile from 'images/about/new/fachri-m.png';
import Linkedin from 'images/linkedin-logo.png';

import {teams} from 'utils/teams'

class Page extends Component {
    render() {
        return (
            <div data-page="about-us">
                <div className="header">
                    <img className="header-background" alt="header for about us" src={BannerImageMobile} srcSet={`${BannerImageMobile} 768w, ${BannerImageWeb} 1280w`} loading="lazy" />

                    <div className="header-body">
                        <Container>
                            <h1>Helping small <br />businesses grow big</h1>
                        </Container>
                    </div>
                </div>

                <div className="sub-header">
                    <Container>
                        <Grid container className="mui-justify-center">
                            <Grid xs={12} md={9}>
                                <p className="center">
                                    Kapital Boost is all about growing communities. Whether it's helping small businesses grow big or protecting the social welfare of less-privileged communities, our Singapore-based hybrid crowdfunding platform allows our members to invest or donate in a way that is ethical and Shariah-focused.
                                </p>
                            </Grid>
                        </Grid>
                    </Container>
                </div>

                <section className="story">
                    <Container>
                        <Grid container>
                            <Grid xs={12} sm={6} md={5}>
                                <img alt="Our story" src={StoryImageMobile} srcSet={`${StoryImageMobile} 768w, ${StoryImage} 1280w`} loading="lazy" width={`100%`} />
                            </Grid>

                            <Grid xs={12} sm={6} md={6}>
                                <div className="center-content">
                                    <div className="body space-left">
                                        <p className="title">{ `Our story` }</p>
                                        <p>
                                            Growing wealth. Growing SMEs.
                                        </p>
                                        <p>
                                            We started in 2015 with the goal of connecting high-potential SMEs with investors seeking Shariah-compliant investments to grow their wealth.
                                        </p>
                                        <p>        
                                            Through the Kapital Boost platform, we help these SMEs get access to alternative finance so they can grow and operate successfully. 
                                        </p>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </Container>
                </section>

                <section className="story">
                    <Container>
                        <Grid container>
                            <Grid xs={12} sm={6} md={7}>
                                <div className="center-content">
                                    <div className="body">
                                        <p className="title">{ `Our socio-economic impact` }</p>
                                        <p>SMEs form the foundation of Southeast Asia's economy, driving job creation and contributing over 50% of the region's GDP. Their success is pivotal to sustainable economic growth, benefiting the entire community. </p>

                                        <p>At our core, we recognize the challenges faced by SMEs. By bridging the financing gap, we enable these businesses to focus on seizing new opportunities, fostering growth, and thriving in their respective industries. Through supporting SMEs, we invest not only in individual enterprises but also in the broader society, generating a positive socio-economic impact on the community at large.</p>
                                    </div>
                                </div>
                            </Grid>
                            
                            <Grid xs={12} sm={6} md={5}>
                                <img alt="Our Story" src={ImpactImageMobile} srcSet={`${ImpactImageMobile} 768w, ${ImpactImage} 1280w`} loading="lazy" width={`100%`} />
                            </Grid>
                        </Grid>
                    </Container>
                </section>

                <section className="the-team">
                    <Container>
                        <Grid container className="mui-justify-center">
                            <Grid xs={12} md={8}>
                                <p className="title center">The team</p>

                                <p className="center">Experienced, Knowledgeable, and Resourceful – our diverse team brings together expertise in entrepreneurship, Islamic finance, and investment banking. United by a shared mission, we are dedicated to helping SMEs and promoting ethical finance.</p>
                            </Grid>
                        </Grid>

                        <Grid container 
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            className="mui-justify-center"
                            style={{marginTop: '20px'}}>
                            {teams.map(item => (
                                <>
                                    {item.newLine ? <div className="break mb-2" /> : null}
                                    <Grid xs={12} sm={4} md={4} className="team">
                                        <img 
                                            className="team-photo" 
                                            alt={item.name} 
                                            src={item.images.desktop} 
                                            srcSet={`${item.images.mobile} 768w, ${item.images.desktop} 1280w`} 
                                            loading="lazy"
                                        />

                                        <p className="team-name">{item.name}</p>
                                        <p className="team-position">{item.position}</p>

                                        <div className="team-social-media">
                                            {item.linkedin ? (
                                                <a href={item.linkedin} target={`_blank`}>
                                                <img 
                                                    className="team-logo" 
                                                    alt={`Linkedin ${item.name}`} 
                                                    src={Linkedin} 
                                                    loading="lazy"
                                                />
                                            </a>
                                            ) : (<a href={`#`} style={{visibility: 'hidden'}}>
                                            <img 
                                                className="team-logo" 
                                                alt={`Linkedin ${item.name}`} 
                                                src={Linkedin} 
                                                loading="lazy"
                                            />
                                        </a>)}
                                        </div>
                                    </Grid>
                                </>
                            ))}
                        </Grid>
                    </Container>
                </section>
            </div>
        );
    }
}

export default Page;