import React from "react";
import {
  Box,
  Grid,
  Hidden,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";

import RicebowlStartupAwards from "images/home/ricebawl-startup-awards.png";
import MASFinctechFestival from "images/home/MAS-fintech-festival.png";
import AseanFintechChallenge from "images/home/asean-fintech-challenge.png";
import FeaturedOn1 from "images/home/featured/featured-on-1.png";
import FeaturedOn2 from "images/home/featured/featured-on-2.png";
import FeaturedOn3 from "images/home/featured/featured-on-3.png";
import FeaturedOn4 from "images/home/featured/featured-on-4.png";
import FeaturedOn5 from "images/home/featured/featured-on-5.png";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("md")]: {
      padding: "43px 0 85px 0",
    },
    padding: "20px 100px 50px 100px",
    backgroundColor: "rgba(224, 224, 224, .5)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  title: {
    [theme.breakpoints.down("md")]: {
      fontSize: 24,
      width: "178px !important",
      height: "60px !important",
    },
    ...theme.typography.heading2,
    color: theme.palette.darkLightBlue.main,
    width: 322,
    height: 126,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: '33px',
    fontFamily: 'Raleway',
    fontWeight: 'bold',
    lineHeight: '40px',
  },
  award: {
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "column",
    alignItems: "center",
  },
  recognitions: {
    [theme.breakpoints.down("md")]: {
      width: "100%",
      flexDirection: "column",
      marginBottom: 70,
    },
    display: "flex",
    marginBottom: 100,
    width: 1018,
  },
  featuredOnWrapper: {
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
    width: 864,
  },
  featuredOnInner1: {
    [theme.breakpoints.down("md")]: {
      marginBottom: 24,
    },
    display: "flex",
    marginBottom: 30,
  },
  featuredOnInner2: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export default function FeaturedOn() {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div className={classes.root}>      
      <Box className={classes.title} marginBottom={matches ? "40px" : "15px"}>
        Featured on
      </Box>

      <div className={classes.featuredOnWrapper}>
        <div className={classes.featuredOnInner1}>
          <Box
            width={matches ? "100%" : 278}
            height={matches ? "100%" : 102}
            marginRight={matches ? 0 : "15px"}
            display={"flex"}
            justifyContent={"flex-end"}
          >
            <img
              src={FeaturedOn1}
              width={matches ? 112 : 224}
              style={{
                padding: matches ? 0 : "36px 22px 23px 32px",
              }}
            />
          </Box>
          <Box
            width={matches ? "100%" : 278}
            height={matches ? "100%" : 102}
            marginLeft={matches ? "36px" : 0}
            marginRight={matches ? 0 : "15px"}
            display={"flex"}
            justifyContent={"flex-start"}
          >
            <img
              src={FeaturedOn2}
              width={matches ? 150 : 224}
              height={45}
              style={{
                padding: matches ? 0 : "33px 42px 18px 12px",
              }}
            />
          </Box>

          <Hidden mdDown>
            <Box width={matches ? "100%" : 278} height={matches ? "100%" : 102}>
              <img
                src={FeaturedOn3}
                width={matches ? 153 : 235}
                style={{
                  padding: "0px 16px 16px 0",
                }}
              />
            </Box>
          </Hidden>
        </div>

        <Hidden lgUp>
          <Box
            width={"100%"}
            height={"100%"}
            display={"flex"}
            justifyContent={"center"}
            marginBottom={"22px"}
          >
            <img
              src={FeaturedOn3}
              width={matches ? 153 : 278}
              height={65}
              style={{
                padding: matches ? 0 : "13px 16px 16px 0",
              }}
            />
          </Box>
        </Hidden>

        <div className={classes.featuredOnInner2}>
          <Box
            width={matches ? "100%" : 278}
            height={matches ? "100%" : 102}
            display={"flex"}
            justifyContent={"flex-end"}
          >
            <img
              src={FeaturedOn4}
              width={matches ? 132 : 262}
              style={{
                padding: matches ? 0 : "33px 16px 18px 0",
              }}
            />
          </Box>

          <Box
            width={matches ? "100%" : 278}
            height={matches ? "100%" : 102}
            display={"flex"}
            justifyContent={"flex-start"}
            marginLeft={matches ? "13px" : 0}
          >
            <img
              src={FeaturedOn5}
              width={matches ? 118 : 268}
              height={50}
              style={{
                padding: matches ? 0 : "36px 15px 31px 15px",
              }}
            />
          </Box>
        </div>
      </div>

    </div>
  );
}
