import React from 'react'
import api from "services/api";
import { Alert } from "@material-ui/lab";

import { reasons, autocheck_bad_reasons } from "utils/basisid";

import Spinner from "images/spinner.gif";

const loadWidgetMultiJs = () => {
    const script = document.createElement("script");

    script.src = "https://api.basisid.com/assets/js/widget.multi.js";
    script.async = true;

    document.body.appendChild(script);
}



const baseCheckBasisId = async (profile) => {
    const params = ['email'];

    let formData = new FormData();

    params.forEach((param, i) => {
        formData.append(param, profile[param]);
    });

    try {
        const res = await api.post("basisid/base-check", formData);
        console.log(res);

        return res;
    } catch (error) {
        console.log(error);
    }
}

const continueDataBasisId = async (basisid) => {
    const params = ['user_id', 'user_hash'];

    let formData = new FormData();

    params.forEach((param, i) => {
        formData.append(param, basisid[param]);
    });

    try {
        const res = await api.post("basisid/continue-check", formData);
        console.log(res);

        return res;
    } catch (error) {
        console.log(error);
    }
}

const loadWidget = (form_token, toggleLoading) => {
    window.BAS.AS.initFrame({
        key: "prod-XwNflROQlxQLjYWEIdUNPirNtXZVGkiO",
        bas_gw: "https://api.basisid.com/",
        container_id: "bas-widget-container",
        ui: {
            width: "100%",
            height: "705px",
            style: "",
            mobile_height: "auto"
        },
        options: {
            "language": "en",
            "api_form_token": form_token
        },
        events:{
            onLoad: function(){
                console.log("BAS AS loaded");
            },
            onManualCheck: function(result) {
                if (result.status === "ok"){
                    // User data sending success
                    // ... Add Your code here ...
                    saveDataBasisId(result, toggleLoading)
                }
            },
        }
    });
}

const saveDataBasisId = async (response, toggleLoading) => {
    toggleLoading(true);

    const params = ['user_id', 'user_hash'];

    let formData = new FormData();

    params.forEach((param, i) => {
        formData.append(param, response[param]);
    });

    try {
        const res = await api.post("basisid/save", formData);

        toggleLoading(false);
        
        window.location.reload()
    } catch (error) {
        console.log(error);
    }
}

const PageLoading = ({onLoading}) => {
    return (
        <>
            {onLoading === true && 
                <div className='page-loading'>
                    <img src={Spinner} alt="loading" />
                </div>
            }
        </>
    )
}

const createWidgetElement = (profile, toggleLoading) => {
    if(profile.basisid) {
        continueDataBasisId(profile.basisid).then(function(response) {
            const { data } = response;
            
            loadWidget(data.api_form_token, toggleLoading);
        })
    } else {
        baseCheckBasisId(profile).then(function(response) {
            const { data } = response;
            
            loadWidget(data.api_form_token, toggleLoading);
        })
    }
}

const BasisIdElm = () => {
    return (
        <div id="bas-widget-container" style={{minHeight: "350px"}}>
            <h1>
                Loading Basis ID ...
            </h1>
        </div>
    )
}

const BasisIdRequested = ({basisid}) => {
    return (
        <div>
            <Alert severity="warning">
                { basisid.autocheck_bad_reasons }
            </Alert>
            <br />
            <BasisIdElm/>
        </div>
    )
}

const BasisIdRegistered = () => {
    return (
        <Alert severity="info">
            Your KYC documents are successfully submitted and your
            account is currently under review. This may take up to 3
            working days.
        </Alert>
    )
}

const BasisIdDeclined = ({basis_id}) => {
    const data_reasons = basis_id.reason !== null ? basis_id.reason.split(","):[];
    const data_autocheck_bad_reasons = basis_id.autocheck_bad_reasons !== null ? basis_id.autocheck_bad_reasons.split(","):[];
    
    return (
        <>
            <Alert severity="error">
                Ops, your data was <b>declined</b>.
            </Alert>

            <h3>Reasons</h3>
            <ul>
                {data_reasons.map((item, i) => (
                    <li>{reasons[item]}</li>
                ))}
            </ul>

            <h4>Autocheck Bad Reasons</h4>
            <ul>
                {data_autocheck_bad_reasons.map((item, i) => (
                    <li dangerouslySetInnerHTML={{__html: autocheck_bad_reasons[item]}}></li>
                ))}
            </ul>
        </>
    )
}

const BasisIdApproved = () => {
    return (
        <Alert severity="success">
            Your account has been approved.
        </Alert>
    )
}

class ElementBasisID extends React.Component {
    state = {
        profile: null,
        onLoading: false
    }
    
    toggleLoading = (val) => {
        this.setState({onLoading: val})
    }

    componentWillMount() {
        loadWidgetMultiJs();
        const profile = JSON.parse(localStorage.getItem('user'))
    
        this.setState({ profile });
    }

    componentDidMount() {
        if(document.getElementById('bas-widget-container')) {
            createWidgetElement(this.state.profile, () => this.toggleLoading(!this.state.onLoading));
        }
    }


    render() {
        const { profile, onLoading } = this.state;
        const { basisid } = this.state.profile;

        return (
            <div>
                <PageLoading onLoading={onLoading} />

                {profile.status_basis_id === 0 ? (
                    <BasisIdDeclined basis_id={basisid} />
                ) : (
                    <>
                        {profile.status_basis_id === 1 ? (
                            <BasisIdApproved />
                        ) : (
                            <>
                                {profile.status_basis_id === 2 ? (
                                    <BasisIdRequested basisid={basisid} />
                                ) : (
                                    <>
                                        {profile.status_basis_id === 3 ? (
                                            <BasisIdRegistered />
                                        ) : (
                                            <BasisIdElm/>
                                        )}
                                    </>
                                )}
                            </>
                        )} 
                    </>
                )}
            </div>
        );
    }
}

export default ElementBasisID;
