import { Button, Paper } from '@material-ui/core';
import React from 'react'
import VeriffForm from './VeriffForm';
import LibraryAddCheckIcon from '@material-ui/icons/LibraryAddCheck';
import PostAddIcon from '@material-ui/icons/PostAdd';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import PageviewIcon from '@material-ui/icons/Pageview';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';

const loadSDKVeriff = () => {
  const script = document.createElement("script");

  script.src = "https://cdn.veriff.me/incontext/js/v1/veriff.js";
  script.async = true;

  document.body.appendChild(script);
}

class Veriff extends React.Component {
  state = {
    veriff: this.props.profile.veriff,
    profile: this.props.profile
  }

  componentWillMount() {
    loadSDKVeriff()
  }

  render() {
    const {veriff, profile} = this.state;
    
    const resetVeriff = (data) => {
      this.setState({ veriff: data })
    }

    const Content = (veriff) => (
      <div align="center">
        {veriff.code === '9001' && <LibraryAddCheckIcon style={{fontSize: '72px'}} />}
        {veriff.code === '9102' && <HighlightOffIcon style={{fontSize: '72px'}} />}
        {veriff.code === '9104' && <HighlightOffIcon style={{fontSize: '72px'}} />}
        {veriff.code === '9103' && <PostAddIcon style={{fontSize: '72px'}} />}
        {veriff.code === '9121' && <PageviewIcon style={{fontSize: '72px'}} />}
        
        <h2 style={{textTransform: 'capitalize'}}>
          {veriff.status !== null && veriff.status}
        </h2>
        <p>
          {veriff.reason}
        </p>
      </div>
    )

    return (
      <Paper>
        <div className='padding'>
          {veriff === null ? (
              <VeriffForm 
                data={veriff} 
                profile={profile} 
                resetVeriff={resetVeriff}
                startOffer={false}
              /> 
          ) : ( 
            <>
              {veriff.status === null ? (
                <VeriffForm data={veriff} profile={profile} resetVeriff={resetVeriff} startOffer={false} /> 
              ) : (
                <>
                  {veriff.status === "finished" ? (
                    <div align="center" style={{width: "600px", margin: 'auto'}}>
                      <h3>Thank you for your submission.</h3>

                      <p>
                        We have received your verification details. Kindly ensure that you have also provided a valid Proof of Address on your Profile page.
                      </p>
                      <p>
                        Our team is in the process of reviewing your Profile. This process may take up to 2 working days to complete.
                      </p>
                    </div>
                  ) : ( 
                      <div align="center">
                        {/* {veriff.code === '7001' && <AssignmentTurnedInIcon style={{fontSize: '72px'}} />} */}
                        {veriff.code === '7002' && <AssignmentTurnedInIcon style={{fontSize: '72px'}} />}
                        {veriff.code === '9001' && <LibraryAddCheckIcon style={{fontSize: '72px'}} />}
                        {veriff.code === '9102' && <HighlightOffIcon style={{fontSize: '72px'}} />}
                        {veriff.code === '9104' && <HighlightOffIcon style={{fontSize: '72px'}} />}
                        {veriff.code === '9103' && <PostAddIcon style={{fontSize: '72px'}} />}
                        {veriff.code === '9121' && <PageviewIcon style={{fontSize: '72px'}} />}
                        
                        {veriff.code !== '7001' && <h2 style={{textTransform: 'capitalize'}}>
                          {veriff.status !== null && veriff.status.replace('_', ' ')}
                        </h2>}
                        
                        <p>
                          {veriff.reason}
                        </p>

                        {veriff.code === '7001' && (
                          <>
                            {/* <p>You have not been verified, please verify by pressing the button below</p> */}
                            <VeriffForm data={veriff} profile={profile} resetVeriff={resetVeriff} startOffer={false} /> 
                          </>
                        )}

                        {veriff.code === '7002' && (
                          <>
                            <p>Your data is under review, please check again later.</p>
                          </>
                        )}

                        {veriff.code === '9104' && (
                          <>
                            <p>Ops, looks like you not continue the verification within 7 days.</p>
                            <p>You need to start over, click button bellow to start over</p>

                            <VeriffForm data={veriff} profile={profile} resetVeriff={resetVeriff} startOffer={true} /> 
                          </>
                        )}

                        {veriff.code === '9103' && (
                          <>
                            <VeriffForm data={veriff} profile={profile} resetVeriff={resetVeriff} startOffer={true} buttonText="Resubmission Now" />
                          </>
                        )}

                        {veriff.code === '9102' && (
                          <>
                            <VeriffForm data={veriff} profile={profile} resetVeriff={resetVeriff} startOffer={true} buttonText="START OVER" />
                          </>
                        )}
                      </div>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </Paper>
    );
  }
}

export default Veriff;