import React, { useState } from "react";
import FBLogin from "react-facebook-login";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

import api from "services/api";
import hp from "services/hp";

export const useFacebookLogin = ({
  onOpenResendEmail,
  onClose,
  onRegisterSuccess,
}) => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [isLoading, setIsLoading] = useState(false);

  async function handleCallback(response) {
    setIsLoading(true);
    const { id, first_name, last_name, email, accessToken } = response;
    const recaptcha_token = await executeRecaptcha("registerfb");

    const res = await api.post("register-fb", {
      id,
      first_name,
      last_name,
      email,
      accessToken,
      recaptcha_token,
    });

    if (!res) {
      return;
    }

    const {
      data: { register },
    } = res;

    isLoading(false);

    if (register.error) {
      if (register.code == "003") {
        onClose();
        onOpenResendEmail(register.email);
        return;
      }

      alert(register.message);
      return;
    }

    if (register.first_time) {
      onRegisterSuccess();
      return;
    }

    if (register.login) {
      hp.save_user(register.user);
      hp.save_bank(register.banks);
      localStorage.setItem("id", register.user.id);
      localStorage.setItem("token", register.login.token);
      window.location.reload();
    } else {
      alert("Error");
      return;
    }
  }

  const facebookProps = {
    callback: handleCallback,
  };

  return {
    facebookProps,
    FacebookLogin,
  };
};

export const FacebookLogin = ({ callback }) => {
  return (
    <FBLogin
      appId="261151691029080"
      autoLoad={false}
      fields="first_name,last_name,email"
      onClick={() => console.log("onclick")}
      cssClass="fb-button"
      callback={callback}
    />
  );
};
