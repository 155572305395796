import React, {Component} from 'react'
import {Link} from 'react-router-dom'

import {Container, AppBar, InputAdornment, FormControlLabel, Checkbox, Toolbar, MenuItem, FormControl, Typography, Button, InputLabel, Hidden, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Grid, Select} from '@material-ui/core'
import {AccountCircle, Close} from '@material-ui/icons'
import api from 'services/api'

import Banner from 'images/career.jpg'
import useScript from 'hooks/useScript'


class Page extends Component {
   componentWillMount() {
      const script = document.createElement("script");
   
      script.src = "https://api.basisid.com/assets/js/widget.multi.js";
      script.async = true;
   
      document.body.appendChild(script);
   }
   componentDidMount() {
      window.scrollTo(0,0)

      setTimeout(function(){
         
         window.BAS.AS.initFrame({
            key: "prod-XwNflROQlxQLjYWEIdUNPirNtXZVGkiO",
            bas_gw: "https://api.basisid.com/",
            container_id: "bas-widget-container",
            ui: {
                width: "100%",
                height: "705px",
                style: "",
                mobile_height: "auto"
            },
            options: {
               "language": "en"
            },
            events:{
                onLoad: function(){
                    console.log("BAS AS loaded");
                },
                onManualCheck: function(result) {
                    if (result.status === "ok"){
                      // User data sending success
                      // ... Add Your code here ...
                      console.log(result)
                    }
                },
            }
          });
      }, 1000)
       
   }

   render() {
      return (
         <div id="Basis-id">
            {/* <img src={Banner} width="100%" /> */}


            <Container maxWidth="md" >
               <div id="bas-widget-container" style={{minHeight: "350px"}}> BASIS ID loading...</div>
            </Container>

         </div>
      )
   }
}

export default Page
