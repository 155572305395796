import { Box, Button, makeStyles, Typography } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  img: {
    [theme.breakpoints.down("xs")]: {
      height: 307,
    },
    height: 660,
    objectFit: "cover",
    objectPosition: "top",
  },
  headlineWrapper: {
    [theme.breakpoints.down("xs")]: {
      width: 174,
      left: 29,
      top: "50%",
      padding: "21px 15px 12px 13px",
      transform: "translateY(-50%)",
    },
    width: 450,
    position: "absolute",
    top: 113,
    left: 109,
    paddingTop: 20,
    paddingLeft: 33,
    paddingRight: 22,
    paddingBottom: 37,
    backgroundColor: "rgba(51, 51, 51, 0.4)",
    borderRadius: 15,
  },
  headline: {
    [theme.breakpoints.down("xs")]: {
      "font-family": "Raleway",
      "font-style": "normal",
      "font-weight": "800",
      "font-size": "21px",
      marginBottom: 10,
    },
    ...theme.typography.heading1,
    color: "#ffffff",
    textShadow: "6px 4px 4px rgba(0, 0, 0, 0.25)",
    textAlign: "left",
    marginBottom: 21,
  },
  subHeadline: {
    [theme.breakpoints.down("xs")]: {
      "font-family": "Roboto",
      "font-style": "normal",
      "font-weight": "normal",
      "font-size": "14px",
      "line-height": 1,
      marginBottom: 20,
    },
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 18,
    lineHeight: "26px",
    color: "#ffffff",
    textAlign: "left",
    marginBottom: 38,
  },
  action: {
    [theme.breakpoints.down("xs")]: {
      minWidth: "100%",
      height: 38,
      fontSize: 14,
    },
    fontSize: 16,
    fontWeight: 400,
    height: 48,
    minWidth: 240,
    backgroundColor: theme.palette.ceremainBlue.main,
    borderRadius: 50,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#ffffff",
    textTransform: "none",
    // ...theme.typography.heading4,
  },
}));

export default function BigCarouselItem({
  imageSrc,
  headline,
  subHeadline,
  action,
  actionLabel,
  headlineWidth
}) {
  const classes = useStyles();

  return (
    <Box position="relative">
      <img src={imageSrc} className={classes.img} alt={actionLabel} />

      <div className={classes.headlineWrapper} style={{width: headlineWidth}}>
        <div className={classes.headline}>{headline}</div>
        <div className={classes.subHeadline}>{subHeadline}</div>
        <Button className={classes.action} onClick={action}>
          {actionLabel}
        </Button>
      </div>
    </Box>
  );
}
