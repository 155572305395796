/* eslint-disable no-multi-str */

export const reasons = {
    '0': "Wrong data",
    '1': "Wrong photos",
    '2': "Fake documents",
    '3': "Other"
}

export const autocheck_bad_reasons = {
    'pep': "politically exposed person\
            <br>law enforcement\
            <br>regulatory enforcement\
            <br>other bodies",
    'sanctions': "governmental and other sanctions",
    'black_list': "A person with the same name is mentioned in blacklist.",
    'video': "No match in facial recognition or failed liveness check.",
    'mrz': "customer data does not match with ID's machine readable zone (MRZ) or the document is not valid."
}