import React, { Component, useEffect, useState, useCallback } from "react";

import {
  Container,
  CircularProgress,
  Typography,
  Button,
  Grid,
  Box,
  Tab,
  Tabs,
} from "@material-ui/core";
import api from "services/api";
import Campaign from "components/Campaign";
import { Fade } from "react-reveal";
import "./styles/campaigns.css";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function CampaignList(props) {
  const [value, setValue] = useState(0);
  const [donation, setDonation] = useState([]);
  const [sme, setSme] = useState([]);
  const [pageSme, setPageSme] = useState(1);
  const [pageDonation, setPageDonation] = useState(1);
  const [perpageSme, setPerpageSme] = useState(6);
  const [perpageDonation, setPerpageDonation] = useState(6);

  const fetchCampaign = useCallback(async (page, perpage, type) => {
    try {
      let campaigns_res;
      let url = `campaigns/${type}?page=${page}&perpage=${perpage}`;

      campaigns_res = await api.get(url);
      const campaignFetched = campaigns_res?.data?.data;

      if (type === "sme") setSme([...sme, ...campaignFetched]);
      if (type === "donation") setDonation([...donation, ...campaignFetched]);
    } catch (err) {
      // if (err && err.response && err.response.status == 403) {
      //   this.props.history.push(`/campaign/?page=1`);
      // }
    }
  });

  // useEffect(() => {
  //   // fetchCampaign(page, perpage)
  //   fetchCampaign(pageSme, perpageSme, "sme")
  // },[fetchCampaign, pageSme, perpageSme])

  // useEffect(() => {
  //   fetchCampaign(pageDonation, perpageDonation, "donation")
  // },[fetchCampaign, pageDonation, perpageDonation])

  useEffect(() => {
    fetchCampaign(pageSme, perpageSme, "sme");
    fetchCampaign(pageDonation, perpageDonation, "donation");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSme, perpageSme, pageDonation, perpageDonation]);

  useEffect(() => {
    const params = new URLSearchParams(props.location.search);

    if (params.get("i") === "donation") {
      setValue(1);
    }
  }, [props.location.search]);

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  function handleLoadMore(type) {
    if (type === "sme") {
      if (perpageSme !== 3) setPerpageSme(3);
      if (pageSme === 1) {
        setPageSme(pageSme + 2);
      } else {
        setPageSme(pageSme + 1);
      }
    }
    if (type === "donation") {
      if (perpageDonation !== 3) setPerpageDonation(3);
      if (pageDonation === 1) {
        setPageDonation(pageDonation + 2);
      } else {
        setPageDonation(pageDonation + 1);
      }
    }
  }

  function openLoginDialog(e) {
    props.onLoginDialog(e);
  }
  return (
    <div id="campaigns-list">
      <h4
        className="header-campaign"
      >
        Our Campaigns
      </h4>
      <Box sx={{ width: "100%", typography: "body1" }}>
        <div className="tab-header">
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            centered
          >
            <Tab label="SME" className="darklightblue bold" />
            <Tab
              label="Donation"
              className="darklightblue bold text-transform-none"
            />
          </Tabs>
        </div>

        <TabPanel value={value} index={0}>
          <Container id="campaigns-content" maxWidth="xl" className="spaceBigX">
            <Grid container alignItems="flex-start" spacing={6}>
              {/* spacing={4} */}
              {sme ? (
                <>
                  {sme.map((campaign, i) => (
                    <>
                      {campaign.type === "sme" && (
                        <Grid key={i} item xs={12} md={6} lg={4}>
                          <Fade key={i} delay={i * 50} bottom>
                            <Campaign
                              onOpenLoginDialog={(e) => openLoginDialog(e)}
                              campaign={campaign}
                            />
                          </Fade>
                        </Grid>
                      )}
                    </>
                  ))}
                </>
              ) : (
                <div className="loading">
                  <CircularProgress />
                </div>
              )}
            </Grid>
          </Container>

          <div className="flex justify-content-center">
            <Button
              variant="outlined"
              className="load-more"
              onClick={() => handleLoadMore("sme")}
              disabled={sme.length >= 15}
            >
              More
            </Button>
          </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Container id="campaigns-content" maxWidth="xl" className="spaceBigX">
            <Grid container alignItems="flex-start" spacing={6}>
              {/* spacing={4} */}
              {donation ? (
                <>
                  {donation.map((campaign, i) => (
                    <>
                      {campaign.type === "donation" && (
                        <Grid key={i} item xs={12} md={6} lg={4}>
                          <Fade key={i} delay={i * 50} bottom>
                            <Campaign
                              onOpenLoginDialog={(e) => openLoginDialog(e)}
                              campaign={campaign}
                            />
                          </Fade>
                        </Grid>
                      )}
                    </>
                  ))}
                </>
              ) : (
                <div className="loading">
                  <CircularProgress />
                </div>
              )}
            </Grid>
          </Container>
          <div className="flex justify-content-center">
            <Button
              variant="outlined"
              className="load-more"
              onClick={() => handleLoadMore("donation")}
              disabled={donation.length >= 15}
            >
              More
            </Button>
          </div>
        </TabPanel>
      </Box>
    </div>
  );
}

export default CampaignList;
