import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import Slider1 from "images/home/banner-header-1.jpg";
import Slider2 from "images/home/banner-header-2.jpg";
import Slider4 from "images/home/banner-header-4.jpg";
import BigCarouselItem from "./BigCarouselItem";
import { useHistory } from "react-router-dom";

export default function BigCarousel() {
  const history = useHistory();

  return (
    <Carousel
      autoPlay={true}
      showThumbs={false}
      showStatus={false}
      showArrows={false}
      infiniteLoop={true}
      interval={4000}
    >
      <BigCarouselItem
        imageSrc={Slider2}
        headline={<div>Growing wealth, growing SMEs</div>}
        subHeadline={<div>Support the growth of promising SMEs through ethical P2P investments</div>}
        action={() => history.push('/campaign?i=sme')}
        actionLabel="Invest now"
        headlineWidth="450px"
      />
      <BigCarouselItem
        imageSrc={Slider1}
        headline={<div>Funding solutions for SMEs</div>}
        subHeadline={<div>Supercharge your business with short-term asset-purchase and invoice financing options</div>}
        action={() => history.push('/get-funded')}
        actionLabel="Get funded"
        headlineWidth="475px"
      />
      <BigCarouselItem
        imageSrc={Slider4}
        headline={<div>Invest for the hereafter</div>}
        subHeadline={<div>Make a charitable gift that brings hope and support to those in need</div>}
        action={() => history.push('/campaign?i=donation')}
        actionLabel="Donate now"
        headlineWidth="375px"
      />
    </Carousel>
  );
}