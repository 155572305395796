import React from "react";
import {
  Box,
  Grid,
  Hidden,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";

import RicebowlStartupAwards from "images/home/ricebawl-startup-awards.png";
import MASFinctechFestival from "images/home/MAS-fintech-festival.png";
import AseanFintechChallenge from "images/home/asean-fintech-challenge.png";
import FeaturedOn1 from "images/home/featured-on-1.png";
import FeaturedOn2 from "images/home/featured-on-2.png";
import FeaturedOn3 from "images/home/featured-on-3.png";
import FeaturedOn4 from "images/home/featured-on-4.png";
import FeaturedOn5 from "images/home/featured-on-5.png";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("md")]: {
      padding: "43px 0 85px 0",
    },
    padding: "25px 100px 0px 100px",
    backgroundColor: "#FFFFFF",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  title: {
    [theme.breakpoints.down("md")]: {
      fontSize: 24,
      width: "178px !important",
      height: "60px !important",
    },
    // ...theme.typography.heading2,
    color: theme.palette.darkLightBlue.main,
    width: 322,
    height: 126,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: '33px',
    fontFamily: 'Raleway',
    fontWeight: 'bold',
    lineHeight: '40px',
  },
  award: {
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "column",
    alignItems: "center",
  },
  recognitions: {
    [theme.breakpoints.down("md")]: {
      width: "100%",
      flexDirection: "column",
      marginBottom: 70,
    },
    display: "flex",
    marginBottom: 100,
    width: 1018,
  },
  featuredOnWrapper: {
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
    width: 864,
  },
  featuredOnInner1: {
    [theme.breakpoints.down("md")]: {
      marginBottom: 24,
    },
    display: "flex",
    marginBottom: 30,
  },
  featuredOnInner2: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export default function Recognitions() {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div className={classes.root}>
      <Box className={classes.title} marginBottom={matches ? "21px" : "15px"}>
        Recognitions
      </Box>
      <div className={classes.recognitions}>
        <Box display={"flex"} marginBottom={matches ? "40px" : 0}>
          <Box
            className={classes.award}
            marginRight={matches ? 0 : "68px"}
            width={"100%"}
          >
            <Box mb={matches ? 2 : 4}>
              <img src={RicebowlStartupAwards} width={matches ? 62 : 90} />
            </Box>
            <Box width={matches ? 122 : 280}>
              <Box
                fontWeight="bold"
                fontSize={matches ? 12 : 16}
                textAlign={"center"}
                color="#4F4F4F"
                lineHeight={1.5}
              >
                Winner
              </Box>
              <Box
                fontSize={matches ? 12 : 16}
                color="#4F4F4F"
                lineHeight={1.5}
                textAlign="center"
              >
                Rice Bowl Startup Awards
                <br />
                2015
              </Box>
            </Box>
          </Box>
          <Box
            className={classes.award}
            marginRight={matches ? 0 : "80px"}
            width={"100%"}
          >
            <Box mb={matches ? 2 : 5}>
              <img src={MASFinctechFestival} width={matches ? 90 : 206} />
            </Box>
            <Box width={matches ? 122 : 290}>
              <Box
                fontWeight="bold"
                fontSize={matches ? 12 : 16}
                textAlign={"center"}
                color="#4F4F4F"
                lineHeight={1.5}
              >
                Finalist
              </Box>
              <Box
                fontSize={matches ? 12 : 16}
                color="#4F4F4F"
                lineHeight={1.5}
                textAlign="center"
              >
                MAS Fintech Festival <br />
                2016
              </Box>
            </Box>
          </Box>

          <Hidden xsDown>
            <Box className={classes.award}>
              <Box mb={matches ? 2 : 5}>
                <img src={AseanFintechChallenge} width={matches ? 82 : 120} />
              </Box>
              <Box width={300}>
                <Box
                  fontWeight="bold"
                  fontSize={matches ? 12 : 16}
                  textAlign={"center"}
                  color="#4F4F4F"
                  lineHeight={1.5}
                >
                  Runner-up
                </Box>
                <Box
                  fontSize={matches ? 12 : 16}
                  color="#4F4F4F"
                  lineHeight={1.5}
                  textAlign="center"
                >
                  ASEAN Fintech Challenge <br />
                  2016
                </Box>
              </Box>
            </Box>
          </Hidden>
        </Box>

        <Hidden smUp>
          <Box className={classes.award}>
            <Box mb={matches ? 2 : 5}>
              <img src={AseanFintechChallenge} width={matches ? 82 : 120} />
            </Box>
            <Box width={300}>
              <Box
                fontWeight="bold"
                fontSize={matches ? 12 : 16}
                textAlign={"center"}
                color="#4F4F4F"
                lineHeight={1.5}
              >
                Runner-up
              </Box>
              <Box
                fontSize={matches ? 12 : 16}
                color="#4F4F4F"
                lineHeight={1.5}
                textAlign="center"
              >
                ASEAN Fintech Challenge <br />
                2016
              </Box>
            </Box>
          </Box>
        </Hidden>
      </div>

    </div>
  );
}
