import React, { createContext, useContext, useState } from "react";

const RegisterSuccessDialogContext = createContext(null);

function RegisterSuccessDialogProvider({ children }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <RegisterSuccessDialogContext.Provider
      value={{
        isOpen,
        onOpen: () => setIsOpen(true),
        onClose: () => setIsOpen(false),
      }}
    >
      {children}
    </RegisterSuccessDialogContext.Provider>
  );
}

function useRegisterSuccessDialog() {
  return useContext(RegisterSuccessDialogContext);
}

export {
  RegisterSuccessDialogContext,
  RegisterSuccessDialogProvider,
  useRegisterSuccessDialog,
};
