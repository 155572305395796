import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useFacebookLogin } from "hooks/useFacebookLogin";
import React, { Fragment, useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import api_public from "services/api_public";
import hp from "services/hp";

import { useForgetPasswordDialog } from "contexts/ForgetPasswordDialogContext";
import { useLoginDialog } from "contexts/LoginDialogContext";
import { useResendEmailDialogContext } from "contexts/ResendEmailDialogContext";

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

const LoginDialog = () => {
  const { isOpen, onClose } = useLoginDialog();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { onOpen: onOpenForgetPasswordDialog } = useForgetPasswordDialog();
  const { onOpen: onOpenResendEmailDialog } = useResendEmailDialogContext();
  const { facebookProps, FacebookLogin } = useFacebookLogin({
    onClose,
    onOpenResendEmail: onOpenResendEmailDialog,
  });
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState({
    email: "",
    password: "",
  });

  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    const recaptcha_token = await executeRecaptcha("login");
    const { data } = await api_public.post("login", {
      ...form,
      recaptcha_token,
    });

    setIsLoading(false);

    if (data.error) {
      if (data.code === "003") {
        onOpenResendEmailDialog(form.email);
        return;
      }

      alert(data.message);
      return;
    }

    if (data.login.id) {
      hp.save_user(data.user);
      hp.save_bank(data.banks);
      localStorage.setItem("id", data.login.id);
      localStorage.setItem("token", data.login.token);
      window.location.reload();
      if (data.referral_code) {
        //* jika referral code is true
        window.location.reload();
      }
    } else {
      alert("Error");
      return;
    }
  }

  function handleChange(event) {
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  }

  function handleOpenForgetPassword() {
    onClose();
    onOpenForgetPasswordDialog();
  }

  return (
    <Fragment>
      <Dialog open={isOpen} onClose={onClose}>
        <DialogContent
          classes={{
            root: classes.dialogContent,
          }}
        >
          <Typography variant="h5">Investor Login</Typography>
          <Button onClick={onClose} variant="outlined" disableElevation>
            <Close />
          </Button>
        </DialogContent>
        <DialogContent dividers>
          <form onSubmit={handleSubmit}>
            <TextField
              autoFocus
              name="email"
              margin="normal"
              label="Email address"
              type="text"
              fullWidth
              required
              onChange={handleChange}
            />
            <TextField
              name="password"
              margin="normal"
              label="Password"
              type="password"
              fullWidth
              required
              onChange={handleChange}
            />
            <Button
              margin="normal"
              variant="contained"
              color="primary"
              fullWidth
              type="submit"
              className="space"
              disabled={isLoading}
            >
              {isLoading ? (
                <CircularProgress color="default" size={25} />
              ) : (
                "Login"
              )}
            </Button>
          </form>
          <Typography>
            <a href="javascript:;" onClick={() => handleOpenForgetPassword()}>
              Forgot your password?
            </a>
          </Typography>
          <Typography>
            Not registered? <Button>Sign up now</Button>
          </Typography>
        </DialogContent>
        <DialogContent>
          <FacebookLogin {...facebookProps} />
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default LoginDialog;
