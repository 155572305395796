import axios from "axios";

let url = process.env.REACT_APP_API_URL;
// if (process.env.REACT_APP_IS_STAGING) {
//   url = "https://stagingapi.kapitalboost.com/api";
// } else {
//   url =
//     process.env.NODE_ENV !== "production"
//       ? "http://localhost:8000/api"
//       : "https://api.kapitalboost.com/api";
// }

const api_public = axios.create({
  baseURL: url,
});

api_public.interceptors.response.use(
  (response) => response,
  (error) => {
    return error.response;
    // if (error.response.status === '') {
    //   console.log('do nothing.');
    // }
  }
)

export default api_public;
