import React from "react";
import { makeStyles, Typography } from "@material-ui/core";

import ExcellencePhoto from "images/home/excellence.png";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("xs")]: {
      padding: "27px 32px 40px 32px !important",
    },
    [theme.breakpoints.down("md")]: {
      paddingLeft: 40,
      paddingRight: 40,
    },
    backgroundColor: theme.palette.gray5.main,
    paddingTop: 40,
    paddingBottom: 75,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    [theme.breakpoints.down("xs")]: {
      maxWidth: 200,
      fontSize: 21,
      textAlign: "center",
      lineHeight: 1,
    },
    ...theme.typography.heading2,
    color: theme.palette.darkLightBlue.main,
    lineHeight: 2,
    marginBottom: 15,
  },
  subTitle: {
    [theme.breakpoints.down("xs")]: {
      maxWidth: 262,
      fontSize: 11,
      marginBottom: 43,
      lineHeight: 2,
    },
    maxWidth: 658,
    color: theme.palette.gray2.main,
    ...theme.typography.body3,
    textAlign: "center",
    marginBottom: 63,
  },
  rect: {
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      width: "100%",
      height: "auto",
    },
    width: 1200,
    height: 432,
    backgroundColor: "#ffffff",
    display: "flex",
  },
  image: {
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
    width: "45%",
    objectFit: "cover",
    objectPosition: "top",
  },
  listWrapper: {
    [theme.breakpoints.down("xs")]: {
      padding: "16px 32px 32px 16px !important",
    },
    [theme.breakpoints.down("md")]: {
      padding: "32px 50px",
    },
    position: "relative",
    width: "100%",
    paddingTop: 89,
    paddingLeft: 66,
    paddingRight: 58,
  },
  listItem: {
    [theme.breakpoints.down("xs")]: {
      fontSize: 16,
      marginBottom: 5,
    },
    position: "relative",
    ...theme.typography.body2,
    marginBottom: 20,
    "&::before": {
      [theme.breakpoints.down("xs")]: {
        width: 8,
        height: 8,
      },
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      content: "''",
      width: 16,
      height: 16,
      background: theme.palette.gray2.main,
      borderRadius: "50%",
    },
  },
  link: {
    [theme.breakpoints.down("xs")]: {
      height: "40px !important",
      marginLeft: "100px !important",
      "font-size": "16px !important",
      position: "relative",
      right: "0",
      bottom: "0",
      width: "fit-content",
    },
    [theme.breakpoints.down("md")]: {
      height: "45px",
      marginLeft: 400,
      "font-size": "20px",
      position: "relative",
      right: "0",
      bottom: "0",
      width: "fit-content",
    },
    position: "absolute",
    bottom: 33,
    right: 58,
    fontFamily: "Raleway",
    fontSize: 16,
    fontWeight: 400,
    color: theme.palette.ceremainBlue.main,
    border: `2px solid ${theme.palette.ceremainBlue.main}`,
    borderRadius: 50,
    height: 48,
    paddingLeft: 41,
    paddingRight: 41,
    display: "flex",
    alignItems: "center",
  },
  listUl: {
    [theme.breakpoints.down("xs")]: {
      margin: 0,
      marginBottom: 20,
      width: 250,
    },
    padding: 0,
    listStyle: "none",
  },
  listItemText: {
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 20,
    },
    paddingLeft: 35,
    fontSize: '18px'
  },
}));

export default function Excellence() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography
        classes={{
          root: classes.title,
        }}
      >
        Growing wealth, Growing SMEs
      </Typography>
      <Typography
        classes={{
          root: classes.subTitle,
        }}
      >
        {/* We provide an opportunity for you earn potentially attractive returns,
        while giving well-deserved SMEs access to funding for business growth. */}

        We offer you the opportunity to earn potentially attractive returns while providing deserving SMEs with access to funding for business growth
      </Typography>
      <div className={classes.rect}>
        <img src={ExcellencePhoto} className={classes.image} alt={'excelence'} />

        <div className={classes.listWrapper}>
          <ul className={classes.listUl}>
            <li className={classes.listItem}>
              <div className={classes.listItemText}>Zero investment fees</div>
            </li>
            <li className={classes.listItem}>
              <div className={classes.listItemText}>
                Shariah-compliant financing structures
              </div>
            </li>
            <li className={classes.listItem}>
              <div className={classes.listItemText}>
                {`Short-term tenor – 1 to 6 months`}
              </div>
            </li>
            <li className={classes.listItem}>
              <div className={classes.listItemText}>
                Annualised returns of up to 22%
              </div>
            </li>
          </ul>
          <Link to="/how-it-works" className={classes.link}>
            Learn more
          </Link>
        </div>
      </div>
    </div>
  );
}
