import {
  Box,
  Button,
  Grid,
  Input,
  makeStyles,
  Typography,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import { Call, Mail } from "@material-ui/icons";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("md")]: {
      padding: "36px 30px 83px 30px !important",
      flexDirection: "column",
      alignItems: "center",
    },
    backgroundColor: theme.palette.darkLightBlue2.main,
    padding: "80px 106px 130px 115px",
    display: "flex",
    color: "#ffffff",
  },
  input: {
    [theme.breakpoints.down("md")]: {
      width: "auto",
      height: 48,
      fontSize: 13,
    },
    width: 481,
    height: 45,
    backgroundColor: "#FFFFFF",
    borderRadius: 50,
    paddingLeft: 31,
    paddingRight: 31,
    ...theme.typography.body3,
    color: theme.palette.gray4.main,
    border: "none",
    outline: "none",
    marginBottom: 18,
  },
  subscribe: {
    [theme.breakpoints.down("md")]: {
      height: 38,
      fontSize: 13,
    },
    width: 240,
    height: 45,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#FFFFFF",
    fontSize: 16,
    fontWeight: 400,
    borderRadius: 50,
    backgroundColor: theme.palette.sunshine.main,
    textTransform: "none",
  },
  heading: {
    [theme.breakpoints.down("md")]: {
      fontSize: 24,
      textAlign: "center",
    },
    // ...theme.typography.heading3,
    fontSize: '33px',
    fontFamily: 'Raleway',
    fontWeight: 'bold',
    lineHeight: '40px',
  },
  paragraph: {
    [theme.breakpoints.down("md")]: {
      fontSize: 12,
      textAlign: "center",
    },
    ...theme.typography.body3,
  },
}));

export default function Subscription() {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Grid container className={classes.root}>
      <Grid item sm={6} xs={12} container direction="column">
        <Box marginBottom="20px" width={matches ? "100%" : 343} height={104}>
          <div className={classes.heading}>We’re here to help</div>
          <p className={classes.paragraph}>
            Contact our team if you have any questions.
          </p>
        </Box>

        <Box
          display="flex"
          alignItems="center"
          marginBottom={matches ? "10px" : "20px"}
          justifyContent={matches ? "center" : "start"}
        >
          <Call fontSize="large" />
          <Box width="21px" />
          <Box fontSize={matches ? "16px" : "18px"} fontWeight={500}>
            8433 1760
          </Box>
        </Box>

        <Box
          display="flex"
          alignItems="center"
          justifyContent={matches ? "center" : "start"}
        >
          <Mail fontSize="large" />
          <Box width="21px" />
          <Box fontSize={matches ? "16px" : "18px"} fontWeight={500}>
            hello@kapitalboost.com
          </Box>
        </Box>
      </Grid>

      <Box height={matches ? 69 : 0} />

      <Grid item sm={6} xs={12} container direction="column">
        <Box marginBottom="30px" width="100%">
          <div className={classes.heading}>Stay informed</div>
          <p className={classes.paragraph}>Join our mailing list </p>
        </Box>

        <input className={classes.input} placeholder="Enter your email" />

        <Grid container justify={matches ? "center" : "flex-start"}>
          <Button
            classes={{
              root: classes.subscribe,
            }}
          >
            Subscribe
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
