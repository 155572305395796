import React, {Component, Fragment} from 'react'
import {Link} from 'react-router-dom'

import {Container, AppBar, Tabs, Tab, Paper, InputAdornment, CircularProgress, Table, TableContainer, TableHead, TableBody, TableRow, TableCell, FormControlLabel, Checkbox, Toolbar, MenuItem, FormControl, Typography, Button, InputLabel, Hidden, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Grid, Select} from '@material-ui/core'
import {AccountCircle, Close} from '@material-ui/icons'
import api from 'services/api'
import hp from 'services/hp'
import {format } from 'date-fns'

import Wallet from 'images/dashboard/wallet.png'
import Money from 'images/dashboard/money.png'
import MoneyBack from 'images/dashboard/money-back.png'

class Page extends Component {

   state = {
      portfolios: null,
      dashboard: null,
   }

   componentDidMount() {
      window.scrollTo(0,0)
      this.onStart()
   }

   onStart = async() => {
      const portfolios_res = await api.get('portfolio')
      const portfolios = portfolios_res.data

      const dashboard_res = await api.get('dashboard')
      const dashboard = dashboard_res.data
      this.setState({ portfolios, dashboard })

   }

   render() {
      const {portfolios, dashboard} = this.state
      return (
         <div id="dashboard">
            <Container maxWidth="xl"  >
               <Grid container spacing={2} className="spaceBottom" >
                  <Grid item  xs={12} md={4}  >
                     <div id="kb-wallet-sec" className="grids" >
                        <Grid container >
                           <Grid item xs={4}>
                              <img src={Wallet} width="48" />
                           </Grid>
                           <Grid item xs={8} style={{ textAlign: 'right' }} >
                              {dashboard ?
                                 <Typography variant="h4" className="spaceBottom">${hp.format_money(dashboard.wallet)}</Typography>
                                 :
                                 <CircularProgress  size={40} />
                              }
                              <Typography className="">KB-Wallet balance</Typography>
                              <Typography className="small">
                                 <Button href="/dashboard/kb-wallet" color="primary">More details</Button>
                              </Typography>
                           </Grid>
                        </Grid>
                     </div>
                  </Grid>
                  <Grid item xs={12} md={4} >
                     <div id="total" className="grids" >
                        <Grid container >
                           <Grid item xs>
                              <img src={Money} width="48" />
                           </Grid>
                           <Grid item xs style={{ textAlign: 'right' }} >
                              {dashboard ?
                                 <Typography variant="h4" className="spaceBottom">${hp.format_money(dashboard.amount_invested)}</Typography>
                                 :
                                 <CircularProgress  size={40} />
                              }
                              <Typography className="">Total amount invested</Typography>
                              <Typography className="small">
                                 &nbsp;
                                 <br />
                                 <br />
                              </Typography>
                           </Grid>
                        </Grid>
                     </div>
                  </Grid>
                  <Grid item xs={12} md={4} >
                     <div id="average" className="grids" >
                        <Grid container >
                           <Grid item xs={4}>
                              <img src={MoneyBack} width="48" />
                           </Grid>
                           <Grid item xs={8} style={{ textAlign: 'right' }} >
                              {dashboard ?
                                 <Typography variant="h4" className="spaceBottom">{dashboard.annualised_return}%</Typography>
                                 :
                                 <CircularProgress  size={40} />
                              }
                              <Typography className="">Average annualised return</Typography>
                              <Typography className="small">
                                 &nbsp;
                                 <br />
                                 <br />
                              </Typography>
                           </Grid>
                        </Grid>
                     </div>
                  </Grid>
               </Grid>

               <TableContainer component={Paper} id="dashboard-invest-table" className="spaceBottom" >
                  <Table >
                     <TableHead>
                        <TableRow>
                           <TableCell colSpan={3} className="bold" >Latest investments</TableCell>
                           <TableCell align="right" ><Link to="/dashboard/portfolio#sme" ><Button variant="contained" color="primary" >View Portfolio</Button></Link></TableCell>
                        </TableRow>
                        <TableRow>
                           <TableCell component="th" className="bold" align="left">Campaigns</TableCell>
                           <TableCell component="th" className="bold" align="left">Funding Amount (SGD)</TableCell>
                           <TableCell component="th" className="bold" align="left">Investment Date</TableCell>
                           <TableCell component="th" className="bold" align="left">Principal Payment</TableCell>
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {portfolios ?
                           <Fragment>
                              {portfolios.invests.filter( invests => invests.campaign.type == 'sme' ).length > 0 ?
                              <Fragment>
                                 {portfolios.invests.filter( invests => invests.campaign.type == 'sme' ).map( (invest, i) => (
                                    <Fragment key={i}>
                                       {i < 5 &&
                                          <TableRow key={i}>
                                             <TableCell align="left" >{invest.campaign.acronim}</TableCell>
                                             <TableCell align="left" >{hp.format_money(invest.amount)}</TableCell>
                                             <TableCell align="left" >{format(new Date(invest.created_at.replace(/\s+/g, 'T')), 'dd MMM yyyy')}</TableCell>
                                             <TableCell align="left" >{invest.is_paid ? 'Paid' : 'Unpaid'}</TableCell>
                                          </TableRow>
                                       }
                                    </Fragment>
                                 ))}
                              </Fragment>
                              :
                              <TableRow className="loading-short">
                                 <TableCell colSpan={4} align="center" > <Typography>No investment yet</Typography></TableCell>
                              </TableRow>
                           }
                        </Fragment>
                        :
                        <TableRow className="loading-short">
                           <TableCell colSpan={4} align="center"  > <CircularProgress /></TableCell>
                        </TableRow>
                     }
                  </TableBody>
               </Table>
            </TableContainer>

            <TableContainer component={Paper} id="dashboard-donation-table" className="spaceBottom" >
               <Table >
                  <TableHead>
                     <TableRow>
                        <TableCell colSpan={3} className="bold" >Latest donations</TableCell>
                        <TableCell align="right" ><Link to="/dashboard/portfolio#donation" ><Button variant="contained" color="primary" >View Donation</Button></Link></TableCell>
                     </TableRow>
                     <TableRow>
                        <TableCell component="th" className="bold" align="left">Campaigns</TableCell>
                        <TableCell component="th" className="bold" align="left">Donation Amount (SGD)</TableCell>
                        <TableCell component="th" className="bold" align="left">Donation Date</TableCell>
                        <TableCell component="th" className="bold" align="left">Principal Payment</TableCell>
                     </TableRow>
                  </TableHead>
                  <TableBody>
                     {portfolios ?
                        <Fragment>
                           {portfolios.invests.filter( invests => invests.campaign.type == 'donation' ).length > 0 ?
                           <Fragment>
                              {portfolios.invests.filter( invests => invests.campaign.type == 'donation' ).map( (invest, i) => (
                                 <Fragment key={i}>
                                    {i < 5 &&
                                       <TableRow key={i}>
                                          <TableCell align="left" >{invest.campaign.acronim}</TableCell>
                                          <TableCell align="left" >{hp.format_money(invest.amount)}</TableCell>
                                          <TableCell align="left" >{format(new Date(invest.created_at.replace(/\s+/g, 'T')), 'dd MMM yyyy')}</TableCell>
                                          <TableCell align="left" >{invest.is_paid ? 'Paid' : 'Unpaid'}</TableCell>
                                       </TableRow>
                                    }
                                 </Fragment>
                              ))}
                           </Fragment>
                           :
                           <TableRow className="loading-short">
                              <TableCell colSpan={4} align="center" > <Typography>No donation yet</Typography></TableCell>
                           </TableRow>
                        }
                     </Fragment>
                     :
                     <TableRow className="loading-short">
                        <TableCell colSpan={4} align="center" > <CircularProgress /></TableCell>
                     </TableRow>
                  }
               </TableBody>
            </Table>
         </TableContainer>



      </Container>
   </div>
)
}
}

export default Page
