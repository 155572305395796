import { Button, Dialog, DialogActions, DialogTitle } from "@material-ui/core";
import { useLogoutDialog } from "contexts/LogoutDialogContext";
import React from "react";

const LogoutDialog = () => {
  const { isOpen, onClose, onLogout } = useLogoutDialog();

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle id="alert-dialog-title">
        {"Are you sure want to logout?"}
      </DialogTitle>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          No
        </Button>
        <Button onClick={onLogout} color="primary" autoFocus>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LogoutDialog;
