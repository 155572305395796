import {
  AppBar,
  Box,
  Button,
  Collapse,
  Hidden,
  IconButton,
  makeStyles,
  MenuItem,
  Toolbar,
  Typography,
  useMediaQuery,
  useScrollTrigger,
  useTheme,
  Menu,
  Grid,
} from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { withGoogleReCaptcha } from "react-google-recaptcha-v3";

import Logo from "images/kapitalboost-logo.png";
import {
  AccountBalance,
  AccountBalanceWallet,
  AccountBox,
  BusinessCenter,
  Close,
  Dashboard,
  ExitToApp,
  ExpandMore,
  LocalAtm,
  Update,
  Menu as MenuIcon,
} from "@material-ui/icons";
import { useLoginDialog } from "contexts/LoginDialogContext";
import { useLogoutDialog } from "contexts/LogoutDialogContext";

import hp from "services/hp";

const useStyles = makeStyles((theme) => ({
  appBarRoot: {
    [theme.breakpoints.down("md")]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 20,
      paddingRight: 20,
    },
    paddingLeft: 77,
    paddingRight: 54,
    backgroundColor: "#ffffff",
    display: "flex",
    justifyContent: "center",
    transition: "min-height 0.15s ease-out",
    boxShadow: "0px 4px 50px rgba(0, 0, 0, 0.25)",
  },
  toolbarRoot: {
    display: "flex",
    justifyContent: "space-between",
  },
  logo: {
    [theme.breakpoints.down("sm")]: {
      width: 176,
    },
    width: 205,
  },
  navRight: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
  menu: {
    display: "flex",
    alignItems: "center",
    columnGap: 30,
    marginRight: 40,
  },
  menuItem: {
    [theme.breakpoints.down("sm")]: {
      lineHeight: 2.5,
      color: theme.palette.gray2.main,
    },
    fontFamily: "Raleway",
    fontSize: 16,
    fontWeight: 500,
    margin: 0,
    color: theme.palette.gray2.main,
  },
  button: {
    display: "flex",
    alignItems: "center",
    columnGap: 10,
  },
  buttonLogin: {
    minWidth: 100,
    height: 40,
    borderRadius: 50,
    border: `0px solid ${theme.palette.ceremainBlue.main}`,
    color: theme.palette.gray2.main,
    fontFamily: "Raleway",
    fontWeight: 700,
    fontSize: 16,
    textTransform: "capitalize"
  },
  buttonRegister: {
    minWidth: 146,
    height: 40,
    borderRadius: 50,
    borderColor: theme.palette.ceremainBlue.main,
    // backgroundColor: theme.palette.ceremainBlue.main,
    color: theme.palette.ceremainBlue.main,
    fontFamily: "Raleway",
    fontWeight: 700,
    fontSize: 16,
    textTransform: "capitalize",
  },
  toggleDrawer: {
    color: theme.palette.ceremainBlue.main,
  },
  collapse: {
    position: "fixed",
    zIndex: 100,
    background: "#FFFFFF",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    top: 72,
    left: 0,

    [theme.breakpoints.down("md")]: {
      height: '94vh !important',
      maxHeight: '100%',
      overflowY: "scroll"
    },
  },
  collapseWrapper: {
    height: "100vh",
  },
  collapseCloseWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "12px 24px",
    borderBottom: `1px solid ${theme.palette.gray5.main}`,
  },
  collapseMenuWrapper: {
    padding: "24px 33px",
    display: "flex",
    flexDirection: "column",
    borderBottom: `1px solid ${theme.palette.gray5.main}`,
  },
  collapseButtonWrapper: {
    padding: 30,
  },
}));

const menus = [
  {
    label: "About Us",
    path: "/about",
  },
  {
    label: "Invest",
    path: "/campaign?page=1",
    guard: true,
  },
  {
    label: "Donate",
    path: "/campaign?page=1&i=donation",
    guard: true,
  },
  {
    label: "Get Funded",
    path: "/get-funded",
  },
  {
    label: "Refer",
    path: "/referral",
  },
];

function Header() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const trigger = useScrollTrigger();
  const { onOpen: onOpenLogin } = useLoginDialog();
  const { onOpen: onOpenLogout } = useLogoutDialog();
  const [isCollapse, setIsCollapse] = useState(false);
  const [user, setUser] = useState(null);
  const [openDashboard, setOpenDashboard] = useState(false);
  const [anchor, setAnchor] = useState(null);

  async function setUserLoggedIn() {
    let userLoggedIn = await hp.get_user();

    setUser(userLoggedIn);
  }

  function drawerToggle() {}

  useEffect(() => {
    setIsCollapse(false);
  }, [location]);

  useEffect(() => {
    setUserLoggedIn();
  }, []);

  return (
    <Fragment>
      <AppBar
        elevation={0}
        position="fixed"
        classes={{
          root: classes.appBarRoot,
        }}
        style={{
          minHeight: matches ? 60 : trigger ? 63 : 110,
        }}
      >
        <Toolbar
          disableGutters={matches}
          classes={{
            root: classes.toolbarRoot,
          }}
        >
          <Link to="/">
            <img src={Logo} className={classes.logo} alt="Kapitalboost" />
          </Link>
          <Box className={classes.navRight}>
            <Box className={classes.menu}>
              {menus.map((menu) => (
                <Link
                  key={menu.path}
                  to={menu.path}
                  className={classes.menuItem}
                >
                  {menu.label}
                </Link>
              ))}
            </Box>
            {hp.is_login() ? (
              <Typography>
                {user && (
                  <Button
                    onClick={(e) => {
                      setOpenDashboard(true);
                      setAnchor(e.currentTarget);
                    }}
                    className={classes.menuItem}
                    style={{
                      color: "#000",
                    }}
                  >
                    {user.firstname} <ExpandMore />
                  </Button>
                )}
                <Menu
                  anchorEl={anchor}
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  id="dashboard-menu"
                  transformOrigin={{ vertical: "top", horizontal: "right" }}
                  open={openDashboard}
                  onClose={() => setOpenDashboard(false)}
                >
                  <MenuItem className="blue menu-link">
                    <Link to="/dashboard">
                      <Dashboard /> Dashboard
                    </Link>
                  </MenuItem>
                  <MenuItem className="blue menu-link">
                    <Link to="/dashboard/portfolio">
                      <LocalAtm /> Portfolio
                    </Link>
                  </MenuItem>
                  <MenuItem className="blue menu-link">
                    <Link to="/dashboard/campaign-update">
                      <Update /> Campaign Updates
                    </Link>
                  </MenuItem>
                  <MenuItem className="blue menu-link">
                    <Link to="/dashboard/profile">
                      <AccountBox /> Profile
                    </Link>
                  </MenuItem>
                  <MenuItem className="blue menu-link">
                    <Link to="/dashboard/kb-wallet">
                      <AccountBalanceWallet /> KB-Wallet
                    </Link>
                  </MenuItem>
                  <MenuItem className="blue menu-link">
                    <Link to="/dashboard/bank-account">
                      <AccountBalance /> Bank Account
                    </Link>
                  </MenuItem>
                  <MenuItem className="blue menu-link">
                    <Link to="/dashboard/project">
                      <BusinessCenter /> My Projects
                    </Link>
                  </MenuItem>
                  <MenuItem
                    className="blue"
                    onClick={onOpenLogout}
                    color="secondary"
                  >
                    <ExitToApp /> Logout
                  </MenuItem>
                </Menu>
              </Typography>
            ) : (
              <Box className={classes.button}>
                <Button
                  classes={{
                    root: classes.buttonLogin,
                  }}
                  color="primary"
                  onClick={() => history.push("/login")}
                >
                  Log in
                </Button>
                <Button
                  classes={{
                    root: classes.buttonRegister,
                  }}
                  variant="outlined"
                  color="primary"
                  onClick={() => history.push("/registration")}
                >
                  Register
                </Button>
              </Box>
            )}
          </Box>

          <Hidden mdUp>
            <IconButton
              aria-label="Menu"
              className={classes.toggleDrawer}
              onClick={() => setIsCollapse(!isCollapse)}
            >
              <MenuIcon
                style={{
                  fontSize: 32,
                }}
              />
            </IconButton>
          </Hidden>
        </Toolbar>
      </AppBar>

      <Collapse in={isCollapse} className={classes.collapse}>
        <div className={classes.collapseWrapper}>
          <div className={classes.collapseCloseWrapper}>
            <IconButton onClick={() => setIsCollapse(false)}>
              <Close
                style={{
                  color: "#666666",
                  fontSize: 32,
                }}
              />
            </IconButton>
          </div>

          <div className={classes.collapseMenuWrapper}>
            {menus.map((menu) => (
              <Link key={menu.path} to={menu.path} className={classes.menuItem}>
                {menu.label}
              </Link>
            ))}
          </div>

          <div className={classes.collapseButtonWrapper}>
            {hp.is_login() ? (
              <>
                {user && (
                  <div
                    className={classes.menuItem}
                    style={{
                      color: "#000",
                      textTransform: "uppercase",
                    }}
                  >
                    {user.firstname}
                  </div>
                )}
                <Grid container>
                  <Grid item xs={12}>
                    <Link to="/dashboard" className={classes.menuItem}>
                      <Dashboard /> Dashboard
                    </Link>
                  </Grid>
                  <Grid item xs={12}>
                    <Link
                      to="/dashboard/portfolio"
                      className={classes.menuItem}
                    >
                      <LocalAtm /> Portfolio
                    </Link>
                  </Grid>
                  <Grid item xs={12}>
                    <Link
                      to="/dashboard/campaign-update"
                      className={classes.menuItem}
                    >
                      <Update /> Campaign Updates
                    </Link>
                  </Grid>
                  <Grid item xs={12}>
                    <Link to="/dashboard/profile" className={classes.menuItem}>
                      <AccountBox /> Profile
                    </Link>
                  </Grid>
                  <Grid item xs={12}>
                    <Link
                      to="/dashboard/kb-wallet"
                      className={classes.menuItem}
                    >
                      <AccountBalanceWallet /> KB-Wallet
                    </Link>
                  </Grid>
                  <Grid item xs={12}>
                    <Link
                      to="/dashboard/bank-account"
                      className={classes.menuItem}
                    >
                      <AccountBalance /> Bank Account
                    </Link>
                  </Grid>
                  <Grid item xs={12}>
                    <Link to="/dashboard/project" className={classes.menuItem}>
                      <BusinessCenter /> My Projects
                    </Link>
                  </Grid>
                  <Grid item xs={12}>
                    <div className={classes.menuItem} onClick={onOpenLogout} data-action="logout">
                      <ExitToApp /> Logout
                    </div>
                  </Grid>
                </Grid>
              </>
            ) : (
              <>
                <div>
                  <Button
                    classes={{
                      root: classes.buttonLogin,
                    }}
                    color="primary"
                    onClick={onOpenLogin}
                  >
                    Login
                  </Button>
                </div>
                <Box height="26px" />
                <div>
                  <Button
                    classes={{
                      root: classes.buttonRegister,
                    }}
                    color="primary"
                    onClick={() => history.push("/registration")}
                  >
                    Register
                  </Button>
                </div>
              </>
            )}
          </div>
        </div>
      </Collapse>
    </Fragment>
  );
}

export default withGoogleReCaptcha(Header);
