import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  TextField,
  Typography,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useForgetPasswordDialog } from "contexts/ForgetPasswordDialogContext";
import React, { useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import api_public from "services/api_public";

export default function ForgetPasswordDialog() {
  const { isOpen, onClose } = useForgetPasswordDialog();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [form, setForm] = useState({
    forget_email: "",
  });

  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    const { forget_email } = form;
    const recaptcha_token = await executeRecaptcha('forget');

    const { data } = await api_public.post("forget", {
      forget_email,
      recaptcha_token,
    });

    if (data.error) {
      alert(data.message);
      setIsLoading(false);
      return;
    }

    setForm({ forget_email: "" });
    setIsSuccess(true);
    setIsLoading(false);
  }

  function handleChange(event) {
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  }

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogContent className="dialog-title">
        <Typography variant="h5">Retrieve Password</Typography>
        <Button onClick={onClose} variant="outlined" disableElevation>
          <Close />
        </Button>
      </DialogContent>

      <DialogContent dividers>
        <form onSubmit={handleSubmit}>
          <TextField
            autoFocus
            name="forget_email"
            margin="normal"
            label="Please enter your email address"
            type="text"
            fullWidth
            required
            onChange={handleChange}
          />
          <Button
            margin="normal"
            variant="contained"
            color="primary"
            fullWidth
            type="submit"
            className="space"
            disabled={isLoading || isSuccess}
          >
            {isLoading ? (
              <CircularProgress color="default" size={25} />
            ) : (
              "Submit"
            )}
          </Button>

          <Typography className="space" style={{ opacity: isSuccess ? 1 : 0 }}>
            Please check your email for instructions to change your password
          </Typography>
        </form>
      </DialogContent>
    </Dialog>
  );
}
